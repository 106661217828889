import { NameValue } from "./nameValue";
import { Mandatory } from "./interfaces/mandatory";
import { InputField } from "./interfaces/inputField";
import { Field } from "./interfaces/field";

export class CheckBox implements Mandatory, InputField, Field {
  label!: string;
  mandatory!: boolean;

  //input interface
  id!: string;
  value = false;
  helpText?: string;
  helpLinkText?: string;
  helpLinkUrl?: string;

  //field
  isHidden!: boolean;
}
