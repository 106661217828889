import { NameValue } from "./nameValue";
import { Mandatory } from "./interfaces/mandatory";
import { InputField } from "./interfaces/inputField";
import { Field } from "./interfaces/field";

export class RadioButton implements Mandatory, InputField, Field {
  label?: string;
  mandatory!: boolean;
  options: NameValue[] = [];

  //input interface
  value?: string;
  helpText?: string;
  helpLinkText?: string;
  helpLinkUrl?: string;

  //field
  id!: string;
  isHidden = false;
}
