<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden" class="pt-4">
      <p
        v-html="data.text"
        :class="{ bold: data.bold, cursive: data.cursive }"
        :style="
          data.fontSize != null
            ? { 'font-size': data.fontSize + 'px' }
            : ''
        "
      />
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { TextParagraph } from '@/models/fields/textParagraph'

@Component({})
export default class TextParagraphComponent extends Vue {
  @Prop({default: new TextParagraph()}) data!: TextParagraph;
}
</script>
