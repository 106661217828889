<template>
    <v-dialog v-model="isOpen" persistent max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('discountDialogHeader') }}
        </v-card-title>
        <v-card-text>
          <p class="mt-4" v-if="discountResults != ''">
            {{ $t('discountDialogResultText') }}
          </p>
          <p class="mt-4" v-else>{{ $t('discountDialogNoResults') }}</p>
          <div v-html="discountResults" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="discountResults == ''"
            color="green darken-1"
            text
            @click="emitUpdate()"
          >
            {{ $t('discountDialogRetryBtn') }}
          </v-btn>
          <v-btn color="primary" text @click="emitUpdate()">
            {{ $t('discountDialogBtn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DiscountResultsComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean;
  @Prop() discountResults!: string;

  emitUpdate() {
    this.$emit('discountResultsDialogIsUpdated');
  }
}
</script>

<i18n>
{
  "en": {
    "discountDialogHeader": "Discount codes",
    "discountDialogBtn": "continue to register",
    "discountDialogResultText": "Your code is eligible for following discounts: ",
    "discountDialogNoResults": "Unfortunately, your code is not eligible for any discounts",
    "discountDialogRetryBtn": "try again"
  },
  "fi": {
    "discountDialogHeader": "Alennuskoodit",
    "discountDialogBtn": "jatka rekisteröitymiseen",
    "discountDialogResultText": "Koodisi on oikeutettu seuraaviin alennuksiin: ",
    "discountDialogNoResults": "Valitettavasti koodisi ei ole oikeutettu alennuksiin",
    "discountDialogRetryBtn": "syötä uudelleen"
  },
  "sv": {
    "discountDialogHeader": "Rabattkoder",
    "discountDialogBtn": "fortsätta registrera",
    "discountDialogResultText": "Din kod är berättigad till följande rabatter: ",
    "discountDialogNoResults": "Tyvärr är din kod inte berättigad till några rabatter",
    "discountDialogRetryBtn": "försök igen"
  }
}
</i18n>
