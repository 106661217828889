<template>
  <v-dialog v-model="isOpen" persistent max-width="850px" scrollable>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ $t('registrationForm') }}
      </v-card-title>
      <v-card-text>
        <v-stepper
          v-model="stepper"
          vertical
          class="elevation-0"
          v-if="value.length > 1"
          @change="stepperChanged"
        >
          <template v-for="(f, i) in value">
            <template v-if="!f.isHidden">
              <v-stepper-step
                :complete="isCompleted(i + 1)"
                :step="i + 1"
                :editable="editable"
                :key="'s' + i"
                :rules="[formRules(f, isCompleted(i + 1))]"
              >
                {{ f.name }}

                <small v-if="f.subHeader != null">{{ f.subHeader }}</small>
              </v-stepper-step>
              <v-stepper-content :step="i + 1" class="pt-2" :key="'c' + i">
                <registration-form-page
                  :value="f"
                  @skipLogic="evaluateSkipLogic"
                  :eventId="eventId"
                  :areInternalStandsAllowed="areInternalStandsAllowed"
                  :registrationId="registrationId"
                  :eventPasses="eventPasses"
                />
              </v-stepper-content>
            </template>
          </template>
        </v-stepper>
        <div v-else>
          <!-- skiplogic hiding is not valid, if there is only one section -->
          <registration-form-page
            :value="this.value[0]"
            @skipLogic="evaluateSkipLogic"
            :eventId="eventId"
            :areInternalStandsAllowed="areInternalStandsAllowed"
            :registrationId="registrationId"
            :eventPasses="eventPasses"
          />
        </div>
      </v-card-text>
      <v-card-actions class="grey lighten-3">
        <span v-if="registerPeriodClosed && isRegistered" style="color: red">{{
          $t('periodClosed')
        }}</span>
        <template v-else>
          <small class="ml-2" v-if="registerDisabled"
            ><b>* {{ $t('mandatoryField') }} </b></small
          >
          <!--  <small class="ml-2" v-if="!allVisited"><b>{{$t("mandatorySections")}}</b></small> -->
        </template>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="closeClick">
          <v-icon left>close</v-icon>
          {{ $t('close') }}</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          @click="saveClick"
          :disabled="registerDisabled"
        >
          <v-icon left>save_alt</v-icon>
          {{
            isRegistered
              ? $t('editRegister')
              : eventPaymentType == 1
              ? $t('registerPayable')
              : $t('register')
          }}
          <!-- TODO MITEN Käsitellään muutokset? Maksullisia kohtia ei voi muuttaa?! -->
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator'
import RegistrationFormPage from '@/components/RegistrationForm/RegistrationFormPage.vue'

// MODELS
import { FormDict } from '@/models/formDict'
import { FormField } from '@/models/formField'
import { Pass } from '@/models/pass'
import { RegistrationForm } from '@/models/registrationForm'
import { SkipLogic } from '@/models/skipLogic'
import { SkipLogicAction } from '@/models/skipLogicAction'

// ENUMS
import {
  SkipLogicActionType,
  PaymentType,
  FieldType
} from '@/enums/enums'

//HELPERS
import {
  evaluateLogic,
  findRelatedSkipLogics,
  visibleFormCount,
  getFieldOrForm,
  objIsMandatory,
} from '@/helpers/registrationForm'

@Component({
  components: {
    RegistrationFormPage,
  },
})
export default class RegistrationFormComponent extends Vue {
  @Prop() isOpen!: boolean
  @Prop() value!: RegistrationForm[]
  @Prop({ default: false }) isRegistered!: boolean
  @Prop({ default: false }) registerPeriodClosed!: boolean
  @Prop() skipLogicList!: SkipLogic[]
  @Prop() eventId!: string
  @Prop() registrationId!: string
  @Prop() emailField!: string
  @Prop() hasKeyMappingFields!: boolean
  @Prop({ default: false }) eventPaymentType!: PaymentType
  @Prop({
    default: function () {
      return []
    },
  })
  eventPasses!: Pass[]
  stepper = 1
  visitedSteps: number[] = [1]
  editable = true
  hiddenSections: FormDict[] = []
  disableRegistrationButton = false

  get allVisited() {
    return this.visitedSteps.length == this.value.length
  }

  mounted() {
    for (let form of this.value) {
      if (form.isHidden) this.hideSection(form) //this will ensure that mandatory fields in hidden sections are set to non-mandatory
      //TODO what should be done to name and email -fields? They should never be mandatory?!?
    }
  }

  evaluateSkipLogic(sourceId: string) {
    const relatedLogics: SkipLogic[] = findRelatedSkipLogics(sourceId, this.skipLogicList)
    for (const logic of relatedLogics) {
      if (evaluateLogic(logic, this.value)) {
        this.processActions(logic.actions)
      }
    }
  }

  get areInternalStandsAllowed(): boolean {
    //internal stands are available if email field ends with @aalto.fi or aaltoee.fi
    let emailValue = ''
    for (const r of this.value) {
      for (const f of r.fields) {
        if (f.data?.id == this.emailField) {
          emailValue = f.data.value
          break
        }
      }
      if (emailValue !== '') break
    }

    if (
      emailValue?.toLowerCase().endsWith('@aalto.fi') ||
      emailValue?.toLowerCase().endsWith('@aaltoee.fi')
    ) {
      return true
    }

    return false
  }

  processActions(actions: SkipLogicAction[]) {
    for (const a of actions) {
      let isFieldRule = a.targetFieldId !== undefined && a.targetFieldId !== '' && a.targetFieldId !== null
      let targetId = isFieldRule ? a.targetFieldId : a.targetSectionId
      if (targetId == undefined) continue
      const target = getFieldOrForm(targetId, isFieldRule, this.value)

      if (target == null) {
        continue
      }

      switch (a.action) {
        case SkipLogicActionType.hide:
          if (isFieldRule) {
            (target as FormField).data.isHidden = true
            objIsMandatory((target as FormField).data)
              ? ((target as FormField).data.mandatory = false)
              : null
          } else {
            this.hideSection(target as RegistrationForm)
          }
          break
        case SkipLogicActionType.showAndSetAsOptional:
          (target as FormField).data.isHidden = false
          objIsMandatory((target as FormField).data)
            ? ((target as FormField).data.mandatory = false)
            : null
          break
        case SkipLogicActionType.showAndSetAsRequired:
          (target as FormField).data.isHidden = false
          objIsMandatory((target as FormField).data)
            ? ((target as FormField).data.mandatory = true)
            : null
          break
        case SkipLogicActionType.show: //this only applies to sections
          this.showSection(target as RegistrationForm)
          break
      }
    }
  }

  showSection(form: RegistrationForm): void {
    let idx = this.hiddenSections.findIndex((x) => x.formId == form.id)

    if (idx == -1) return

    let dict = this.hiddenSections[idx]
    for (let f of form.fields) {
      if (dict.trackedFields.includes(f.data.id)) {
        f.data.mandatory = true
      }
    }
    this.hiddenSections.splice(idx, 1)
    form.isHidden = false
  }

  hideSection(form: RegistrationForm): void {
    let dict = new FormDict(form.id)
    this.hiddenSections.push(dict)

    for (let f of form.fields) {
      if (f.data.mandatory == true) {
        f.data.mandatory = false
        dict.trackedFields.push(f.data.id)
      }
    }
    form.isHidden = true
  }

  get registerDisabled() {
    if (this.disableRegistrationButton && this.eventPaymentType != 1) {
      return true
    }

    if (this.registerPeriodClosed && this.isRegistered) {
      return true
    }

    if (!this.isRegistered) {
      if (this.visitedSteps.length < visibleFormCount(this.value)) {
        return true
      }
    }

    for (const form of this.value) {
      if (!this.validateForm(form)) {
        return true
      }
    }

    if (!this.hasKeyMappingFields) {
      return true
    }

    return false
  }

  closeClick() {
    this.$emit('closeClicked')
  }

  saveClick() {
    this.disableRegistrationButton = true
    this.$emit('saveClicked')
  }

  isCompleted(step: number): boolean {
    if (this.isRegistered) {
      return true
    }
    return this.visitedSteps.includes(step)
  }

  stepperChanged(value: number) {
    if (!this.visitedSteps.includes(value)) {
      this.visitedSteps.push(value)
    }
  }

  formRules(form: RegistrationForm, isComplete: boolean): any {
    if (!isComplete) return () => true

    if (this.validateForm(form)) {
      return () => true
    }
    return () => false
  }

  validateForm(form: RegistrationForm): boolean {
    for (const f of form.fields) {
      if (
        f.type == FieldType.textInput ||
        f.type == FieldType.radioButton ||
        f.type == FieldType.checkBox ||
        f.type == FieldType.textBox ||
        f.type == FieldType.checkBoxGroup ||
        f.type == FieldType.equipment ||
        f.type == FieldType.stand ||
        f.type == FieldType.ticket
      ) {
        if (!this.validateField(f.data)) {
          return false
        }
      }
    }
    return true
  }

  validateField(fieldData: any): boolean {
    if (
      fieldData.mandatory != null &&
      fieldData.mandatory &&
      (fieldData.isHidden == null || !fieldData.isHidden)
    ) {
      if (fieldData.value == null || fieldData.value == '') {
        return false
      }
    }

    if (fieldData.maxLength != null && fieldData.value != null) {
      if (fieldData.value.length > fieldData.maxLength) {
        return false
      }
    }

    if (
      fieldData.orderedQuantity != null &&
      fieldData.maxOrderAmount != null &&
      (isNaN(fieldData.orderedQuantity) ||
        fieldData.orderedQuantity > fieldData.maxOrderAmount)
    ) {
      return false
    }

    if (
      fieldData.validation != null &&
      fieldData.value != null &&
      fieldData.value != ''
    ) {
      if (!RegExp(fieldData.validation).test(fieldData.value)) {
        return false
      }
    }
    return true
  }
}
</script>

<i18n>
{
  "en": {
    "register": "register",
    "registerPayable": "payment",
    "editRegister": "save",
    "periodClosed": "The time window for modifying registrations has been closed.",
    "close": "close",
    "registrationForm": "Registration form",
    "mandatoryField": "indicates required field",
    "mandatoryFieldNotification": "Missing mandatory fields: ",
    "mandatorySections": "Please review all sections"
  },
  "fi": {
    "register": "ilmoittaudu",
    "registerPayable": "maksu",
    "editRegister": "tallenna",
    "periodClosed": "Rekisteröintitietojen päivitysten aikaikkuna on sulkeutunut.",
    "close": "sulje",
    "registrationForm": "Rekisteröintilomake",
    "mandatoryField": "pakollinen kenttä",
    "mandatoryFieldNotification": "Pakollisia kenttiä puuttuu: ",
    "mandatorySections": " ja lisäksi pakollisia osioita läpikäymättä"
  },
  "sv": {
    "register": "registrera",
    "registerPayable": "betalning",
    "editRegister": "spara",
    "periodClosed": "Tidsfönstret för att ändra registreringar har stängts.",
    "close": "stänga",
    "registrationForm": "Anmälningsblankett",
    "mandatoryField": "indikerar obligatoriska fält",
    "mandatoryFieldNotification": "Pakollisia kenttiä puuttuu: ",
    "mandatorySections": "Please review all sections"
  }
}
</i18n>

<style>
.theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(
    .v-stepper__step--complete
  ):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background: red !important;
}
</style>
