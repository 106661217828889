<template>
    <v-dialog v-model="isOpen" persistent max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('discountDialogHeader') }}
        </v-card-title>
        <v-card-text>
          <p class="mt-4">{{ $t('discountDialogText') }}</p>
          <v-text-field :label="$t('discountCode')" v-model="discountCode" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="emitUpdateediscountDialogClicked()">
            {{ $t('discountDialogBtn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DiscountComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean

  discountCode = ''

  emitUpdate() {
    this.$emit('discountDialogIsUpdated', this.discountCode)
  }
}
</script>

<i18n>
{
  "en": {
    "discountDialogText": "If you have a discount code, you can enter it in the field below and continue to register. If you do not have a discount code, leave the field blank and continue to register.",
    "discountCode": "Discount code",
    "discountDialogHeader": "Discount codes",
    "discountDialogBtn": "continue to register"
  },
  "fi": {
    "discountDialogText": "Mikäli käytössäsi on alennuskoodi, voit syöttää sen oheiseen kenttään ja jatkaa rekisteröitymiseen. Mikäli käytössäsi ei ole alennuskoodia, jätä kenttä tyhjäksi ja jatka rekisteröitymiseen.",
    "discountCode": "Alennuskoodi",
    "discountDialogHeader": "Alennuskoodit",
    "discountDialogBtn": "jatka rekisteröitymiseen"
  },
  "sv": {
    "discountDialogText": "Om du har en rabattkod kan du ange den i fältet nedan och fortsätta registrera dig. Om du inte har en rabattkod, lämna fältet tomt och fortsätt registrera dig.",
    "discountCode": "Rabattkod",
    "discountDialogHeader": "Rabattkoder",
    "discountDialogBtn": "fortsätta registrera"
  }
}
</i18n>
