<template>
  <v-app>
    <v-main v-if="isEditor">
      <DragDropEditor
        v-model="editorEvent"
        @undoEditorEvent="undoEditorEvent"
        @keyFieldChanged="keyFieldChanged"
        @addSection="addSection"
        @deleteSection="deleteSection"
        @addField="addField"
        @deleteField="deleteField"
        @sectionMoved="moveEditorSection"
        @fieldMoved="moveField"
        @saved="loadEditorEvent"
        :inProgress="loadingEditor"
      />
    </v-main>
    <v-main v-if="isCheckin">
      <CheckinComponent :id="registerEventId" />
    </v-main>
    <template v-else>
      <v-app-bar app color="#2d2d2d" dark>
        <div class="align-center">
          <v-img
            alt="Aalto Logo"
            :src="logo"
            contain
            width="13rem"
            min-height="23px"
          />
        </div>

        <v-toolbar-title>{{ $t('eventRegister') }}</v-toolbar-title>
        <VSpacer />
        {{ selectedLanguage }}

        <!-- Menu -->
        <TopMenu
          :supportedLangs="event.supportedLangs"
          @menuIsUpdated="changeLang"
        />

        <!-- Progress bar -->
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="deep-purple accent-4"
          height="6"
        />
      </v-app-bar>
      <v-main>
        <EventComponent
          v-model="event"
          :isLoading="loading"
          :readableId="readableEventId"
          @eventCancelled="eventCancelled"
        />
        <div class="text-center mt-9" v-if="loading">
          <span class="title">
            {{ $t('loadingEvent') }}
          </span>
        </div>
        <div class="text-center mt-9" v-if="paytrailProcessed">
          <span class="title">
            {{ paytrailResultText1 }}
          </span>
          <br />
          <span>
            {{ paytrailResultText2 }}
          </span>
          <br />
          <span> {{ paytrailResultText3 }} </span><br />
          <v-btn
            v-if="paytrailProcessingStatus == 'CANCELLED'"
            color="primary"
            text
            @click="loadPreRegisteredEvent"
          >
            {{ $t('continuePreRegistration') }}
          </v-btn>
        </div>
      </v-main>
      <v-footer
        fixed
        color="#2d2d2d"
        dark
        :class="$vuetify.breakpoint.smAndDown ? 'footerSmall' : 'footerNormal'"
      >
        <div class="grey--text text--darken-1">{{ environment }}</div>
        <v-spacer />
        <a class="footer" :href="$t('privacyLink')" target="_blank">{{
          $t('privacyLinkText')
        }}</a>
        <v-spacer />
        <div>Aalto-yliopisto &copy; {{ new Date().getFullYear() }}</div>
      </v-footer>
    </template>
    <!-- error snackbar -->
    <v-snackbar
      v-model="errorSnackbar"
      color="error"
      :multi-line="true"
      :right="true"
      :timeout="8000"
      :top="true"
    >
      {{ errorMessage }}
      <v-btn icon dark @click="errorSnackbar = false">
        <v-icon> close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-dialog
      scrollable
      persistent
      :value="confirmKeyMapping.id != ''"
      width="350"
    >
      <v-card height="200">
        <v-card-title class="headline yellow lighten-2"
          >Change key field mapping?</v-card-title
        >
        <v-card-text class="pa-5 text-center">
          Field is already mapped. Are you sure you want to re-map field?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" outlined tile small @click="cancelKeyMapping">
            <v-icon left small>cancel</v-icon>cancel
          </v-btn>
          <v-btn
            color="warning"
            outlined
            tile
            small
            @click="doKeyFieldMapping()"
          >
            <v-icon left small>check</v-icon>ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script lang="ts">
// VUE
import { Component, Vue } from 'vue-property-decorator'
import CheckinComponent from '@/components/Checkin.vue'
import DragDropEditor from '@/components/DragDropEditor.vue'
import EventComponent from '@/components/EventLayout.vue'
import TopMenu from '@/components/App/Menu.vue'

// AXIOS
import axios from 'axios'

// SERVICES
import { eventRegistrationService } from '@/services/eventRegistrationService'
import { eventEditorService } from '@/services/eventEditorService'

//UTILS
import arrayUtils from '@/utils/arrayUtils'

// MODELS
import { EditorEvent } from '@/models/editor/editorEvent'
import { EditorField } from '@/models/editor/EditorField'
import { Event } from '@/models/event'
import { Localisation } from '@/models/editor/localisation'

//ENUMS
import { Lang } from '@/enums/enums'

// HELPERS
import {
  currentKeyFieldMapping,
  doKeyFieldMapping,
  fixTextFieldMappings,
} from '@/helpers/app'

@Component({
  components: {
    CheckinComponent,
    DragDropEditor,
    EventComponent,
    TopMenu
  },
})
export default class App extends Vue {
  selectedLanguage = 'Suomi'
  event = new Event()
  editorEvent = new EditorEvent()
  environment = ''
  readableEventId = ''
  registrationId = ''
  errorMessage = ''
  errorSnackbar = false
  loading = false
  loadingEditor = false
  axiosInterceptorReq: any
  axiosInterceptorRes: any
  paytrailProcessed = false
  paytrailProcessingStatus = ''
  isEditor = false
  isCheckin = false
  registerEventId: string = ''
  confirmKeyMapping: { id: string, type: number } = { id: '', type: 0 }

  paytrailResultText1 = ''
  paytrailResultText2 = ''
  paytrailResultText3 = ''

  loadPreRegisteredEvent() {
    const preRegistrationId = this.$route.query?.ORDER_NUMBER as string | null

    if (preRegistrationId == null) {
      alert('missing order number!')
      return
    }

    this.paytrailProcessed = false
    this.loading = true

    eventRegistrationService
      .getEventWithPreRegistration(preRegistrationId)
      .subscribe((result: Event) => {
        this.event = result
        this.loading = false
      })
  }

  doKeyFieldMapping() {
    this.editorEvent = doKeyFieldMapping(
      this.editorEvent,
      this.confirmKeyMapping
    )
    this.confirmKeyMapping = { id: '', type: 0 }
    this.editorEvent = fixTextFieldMappings(this.editorEvent)
  }

  cancelKeyMapping() {
    for (let s of this.editorEvent.sections) {
      for (let f of s.fields) {
        if (f.id == this.confirmKeyMapping.id) {
          f.keyFieldMapping = currentKeyFieldMapping(this.editorEvent, f.id)
          break
        }
      }
    }

    this.confirmKeyMapping = { id: '', type: 0 }
  }

  keyFieldChanged(fieldType: number, fieldId: string) {
    let mappingChanged = false

    switch (fieldType) {
      case 0:
        if (this.editorEvent.mappingEmailId == fieldId)
          this.editorEvent.mappingEmailId = ''

        if (this.editorEvent.mappingFirstNameId == fieldId)
          this.editorEvent.mappingFirstNameId = ''

        if (this.editorEvent.mappingLastNameId == fieldId)
          this.editorEvent.mappingLastNameId = ''

        if (this.editorEvent.mappingPhoneId == fieldId)
          this.editorEvent.mappingPhoneId = ''

        if (this.editorEvent.mappingCompanyId == fieldId)
          this.editorEvent.mappingCompanyId = ''

        if (this.editorEvent.mappingPaytrailEmailId == fieldId)
          this.editorEvent.mappingPaytrailEmailId = ''

        mappingChanged = false
        break
      case 1: //email
        if (
          this.editorEvent.mappingEmailId == null ||
          this.editorEvent.mappingEmailId == ''
        ) {
          this.editorEvent.mappingEmailId = fieldId
        } else {
          mappingChanged = this.editorEvent.mappingEmailId != fieldId
        }
        break
      case 2: //first name
        if (
          this.editorEvent.mappingFirstNameId == null ||
          this.editorEvent.mappingFirstNameId == ''
        ) {
          this.editorEvent.mappingFirstNameId = fieldId
        } else {
          mappingChanged = this.editorEvent.mappingFirstNameId != fieldId
        }
        break
      case 3: //last name
        if (
          this.editorEvent.mappingLastNameId == null ||
          this.editorEvent.mappingLastNameId == ''
        ) {
          this.editorEvent.mappingLastNameId = fieldId
        } else {
          mappingChanged = this.editorEvent.mappingLastNameId != fieldId
        }
        break
      case 4: //phone
        if (
          this.editorEvent.mappingPhoneId == null ||
          this.editorEvent.mappingPhoneId == ''
        ) {
          this.editorEvent.mappingPhoneId = fieldId
        } else {
          mappingChanged = this.editorEvent.mappingPhoneId != fieldId
        }
        break
      case 5: //company
        if (
          this.editorEvent.mappingCompanyId == null ||
          this.editorEvent.mappingCompanyId == ''
        ) {
          this.editorEvent.mappingCompanyId = fieldId
        } else {
          mappingChanged = this.editorEvent.mappingCompanyId != fieldId
        }
        break
      case 6: //paytrail email
        if (
          this.editorEvent.mappingPaytrailEmailId == null ||
          this.editorEvent.mappingPaytrailEmailId == ''
        ) {
          this.editorEvent.mappingPaytrailEmailId = fieldId
        } else {
          mappingChanged = this.editorEvent.mappingPaytrailEmailId != fieldId
        }
        break
    }

    if (mappingChanged) {
      //if mapping is about to change, ask for confirmation
      this.confirmKeyMapping = { id: fieldId, type: fieldType }
    }
  }

  changeLang(langShort: string, loadEvent = true) {
    //this.$root.$i18n.locale = 'fi' // DEBUG ENABLE DEFAULT FINNISH
    //this.selectedLanguage = 'Suomi' // DEBUG ENABLE DEFAULT FINNISH

    /** Comment out start */
    this.$root.$i18n.locale =
      langShort === 'fi' || langShort === 'sv' || langShort === 'en'
        ? langShort
        : 'fi'

    if (langShort !== 'en' && langShort !== 'sv')
      this.selectedLanguage = 'Suomi'
    if (langShort === 'en') this.selectedLanguage = 'English'
    if (langShort === 'sv') this.selectedLanguage = 'Svenska'
    /** Comment out end */

    if (loadEvent) {
      this.changeEventLang(langShort)
      this.loadEvent(this.$root.$i18n.locale)
    }
  }

  changeEventLang(langShort: string) {
    this.event.registrationLang =
      langShort === 'fi' || langShort === 'sv' || langShort === 'en'
        ? Lang[langShort]
        : Lang.fi
  }

  get logo() {
    const logoSrc = 'Aalto_' + this.$root.$i18n.locale + '.png'
    return require('@/assets/' + logoSrc)
  }

  setAxiosInterceptors() {
    this.axiosInterceptorReq = axios.interceptors.request.use(
      (config) => {

        this.loading = true
        return config
      },
      (error) => {
        this.loading = false
        this.errorSnackbar = true
        return Promise.reject(error)
      }
    )

    this.axiosInterceptorRes = axios.interceptors.response.use(
      (response) => {
        this.loading = false
        return response
      },
      (error) => {
        if (error.response == null) {
          this.errorMessage = this.$t('networkError').toString()
        } else if (error.response.status == 404) {
          this.errorMessage =
            this.$t('notFoundError').toString() + error.response.data
        } else {
          this.errorMessage = this.$t('serverError').toString()
        }

        this.loading = false
        this.errorSnackbar = true
        return Promise.reject(error)
      }
    )
  }

  eventCancelled() {
    this.registrationId = ''
    this.$router.push({
      path: '/' + this.readableEventId,
      query: { lang: this.$root.$i18n.locale },
    })
    this.loadEvent()
  }

  addSection() {
    this.editorEvent.sections.push({
      id: '',
      visibleByDefault: true,
      header: 'new section',
      fields: [],
    })
  }

  deleteSection(index: number) {
    this.editorEvent.sections.splice(index, 1)
  }

  addField(sectionIdx: number) {
    let newField = new EditorField()

    for (let l of this.editorEvent.supportedLangs) {
      let newLoc = new Localisation()
      newLoc.lang = l
      newField.localisations.push(newLoc)
    }

    this.editorEvent.sections[sectionIdx].fields.push(newField)
  }

  deleteField(sectionIdx: number, fieldIdx: number) {
    let f = this.editorEvent.sections[sectionIdx].fields[fieldIdx]
    if (f.keyFieldMapping != 0) {
      //also remove existing mapping
      this.confirmKeyMapping = { id: '', type: f.keyFieldMapping }
      this.doKeyFieldMapping()
    }
    this.editorEvent.sections[sectionIdx].fields.splice(fieldIdx, 1)
  }

  moveEditorSection(fromIdx: number, toIdx: number) {
    arrayUtils.move(this.editorEvent.sections, fromIdx, toIdx)
  }

  moveField(
    fromSectionIdx: number,
    fromFieldIdx: number,
    toSectionIdx: number,
    toFieldIdx: number
  ) {
    if (fromSectionIdx == toSectionIdx) {
      //move with in the section
      arrayUtils.move(
        this.editorEvent.sections[fromSectionIdx].fields,
        fromFieldIdx,
        toFieldIdx
      )
    } else {
      //delete from original section and insert into new section to specified index
      //console.log("move form: " + fromSectionIdx + fromFieldIdx +" to: " +toSectionIdx + toFieldIdx)
      const field =
        this.editorEvent.sections[fromSectionIdx].fields[fromFieldIdx]
      //console.dir(field)
      this.editorEvent.sections[toSectionIdx].fields.splice(
        toFieldIdx,
        0,
        field
      )
      this.deleteField(fromSectionIdx, fromFieldIdx)
    }
  }

  undoEditorEvent() {
    this.loadEditorEvent(
      this.$route.query.id as string,
      this.$route.params.registrationId
    )
  }

  loadEditorEvent(eventId: string, key: string) {
    //"e1c45e24-fdd1-42e1-8c3c-ad955a9fe6ac"
    this.loadingEditor = true
    eventEditorService.getEditorEvent(eventId, key).subscribe(
      (result: EditorEvent) => {
        this.editorEvent = result
        this.loadingEditor = false
      },
      (err) => {
        this.loadingEditor = false
      }
    )
  }

  created() {
    this.environment = process.env.VUE_APP_ENVIRONMENT
    this.setAxiosInterceptors()
    switch (this.$route.params.eventId) {
      //DEBUG
      /*
      case 'editor':
        this.isEditor = true
        this.loadEditorEvent(
          "1786d98d-42cd-ed11-b597-000d3ad98951",
          "e1c45e24-fdd1-42e1-8c3c-ad955a9fe6ac",
          this.$route.params.registrationId as string
        )
      break
      */
      case "editor":
        this.isEditor = true;
        this.loadEditorEvent(
          this.$route.query.id as string,
          this.$route.params.registrationId
        )
        break
      case 'paytrail':
        const paytrailLang = this.$route.params.registrationId
        if (paytrailLang != null && paytrailLang != '') {
          this.changeLang(paytrailLang, false)
        }
        this.handlePaytrailResponse()
        break
      case 'checkin':
        this.registerEventId = this.$route.params.registrationId
        this.isCheckin = true
        break
      default:
        this.readableEventId = this.$route.params.eventId
        this.registrationId = this.$route.params.registrationId
        if (this.$route.query?.lang != null) {
          this.changeLang(this.$route.query.lang as string)
        } else {
          this.changeLang('en')
        }
        break;
    }
  }

  handlePaytrailResponse() {
    let queryStr = this.$route.fullPath.split('?')[1]

    eventRegistrationService
      .paytrailCheckRedirect(queryStr)
      .subscribe((result: string) => {
        result = result.toLowerCase()

        if (result == 'ok') {
          this.paytrailResultText1 = this.$t(
            'paytrailProcessedSuccesfully1'
          ).toString()
          this.paytrailResultText2 = this.$t(
            'paytrailProcessedSuccesfully2'
          ).toString()
          this.paytrailResultText3 = this.$t(
            'paytrailProcessedSuccesfully3'
          ).toString()
        } else {
          if (result === 'fail' || result === 'cancelled') {
            //TODO check if cancelled is still used in new API
            this.paytrailResultText1 = this.$t(
              'paytrailProcessedCancelled1'
            ).toString()
            this.paytrailResultText2 = this.$t(
              'paytrailProcessedCancelled2'
            ).toString()
            this.paytrailResultText3 = this.$t(
              'paytrailProcessedCancelled3'
            ).toString()
          } else if (result == 'processed') {
            this.paytrailResultText1 = this.$t(
              'paytrailProcessedProcessed1'
            ).toString()
            this.paytrailResultText2 = this.$t(
              'paytrailProcessedProcessed2'
            ).toString()
            this.paytrailResultText3 = this.$t(
              'paytrailProcessedProcessed3'
            ).toString()
          } else if (result == 'nopasses') {
            this.paytrailResultText1 = this.$t('paytrailNoPass1').toString()
            this.paytrailResultText2 = this.$t('paytrailNoPass2').toString()
            this.paytrailResultText3 = ''
          } else {
            this.paytrailResultText1 = result
            this.paytrailResultText2 = ''
            this.paytrailResultText3 = ''
          }
        }
        this.paytrailProcessed = true
      })
  }

  loadEvent(lang = '') {
    if (this.registrationId != null && this.registrationId != '') {
      eventRegistrationService
        .getEventWithRegistration(
          this.readableEventId,
          this.registrationId,
          lang
        )
        .subscribe((result: Event) => {
          this.event = result
          this.changeEventLang(lang)
        })
    } else {
      eventRegistrationService
        .getEvent(this.readableEventId, lang)
        .subscribe((result: Event) => {
          const langIndex = Lang[lang as any];
          if (Object.hasOwnProperty.call(result, 'supportedLangs')) {
            if (!result.supportedLangs.includes(langIndex as unknown as number)) {
              const resultLang = Lang[result.supportedLangs[0]];
              this.changeLang(resultLang)
            } else {
              this.event = result
              this.changeEventLang(lang)
            }
          }
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "eventRegister": "Registration for the event",
    "networkError": "An error occured while trying to contact network. Please try again later.",
    "notFoundError": "Requested event cound not be found: ",
    "serverError": "An error occured in a server. Please contact support: crm-support@aalto.fi",
    "loadingEvent": "Please wait until data transfer is complete...",
    "paytrailProcessedSuccesfully1" : "Thank you!",
    "paytrailProcessedSuccesfully2" : "Your payment and registration has been processed. You will receive a receipt into the email address you have provided.",
    "paytrailProcessedSuccesfully3" : "You can now close this window.",
    "paytrailProcessedCancelled1" : "The payment was cancelled",
    "paytrailProcessedCancelled2" : "As your payment was cancelled, the registration process was not completed.",
    "paytrailProcessedCancelled3" : "You can now close this window or continue registering.",
    "paytrailProcessedProcessed1" : "Payment event has been processed.",
    "paytrailProcessedProcessed2" : "You will receive an event confirmation into your email.",
    "paytrailProcessedProcessed3" : "You can now close this window.",
    "continuePreRegistration" : "continue registering",
    "privacyLink" : "https://www.aalto.fi/en/services/privacy-notices",
    "privacyLinkText":"Privacy notice for Aalto University communication",
    "paytrailNoPass1": "Unable to create event registration – passes sold out.",
    "paytrailNoPass2": "Event registration failed since the payment was not received on time and passes were sold out. Notification on failed registration has been sent to event organizer and payment will be refunded."
  },
  "fi": {
    "eventRegister": "Tapahtumaan ilmoittautuminen",
    "networkError": "Järjestelmässä tapahtui verkkovirhe. Kokeile myöhemmin uudelleen.",
    "notFoundError": "Pyydettyä tapahtumaa ei löydy järjestelmästä: ",
    "serverError": "Palvelimella tapahtui virhe. Ota tukeen yhteyttä: crm-support@aalto.fi",
    "loadingEvent": "Ole hyvä ja odota, kunnes tiedonsiirto on valmis...",
    "paytrailProcessedSuccesfully1" : "Kiitos!",
    "paytrailProcessedSuccesfully2" : "Maksusi ja rekisteröintisi ovat onnnistuneesti käsitelty. Saat kuitin antamaasi sähköpostiosoitteeseen.",
    "paytrailProcessedSuccesfully3" : "Voit nyt sulkea tämän ikkunan.",
    "paytrailProcessedCancelled1" : "Maksutapahtuma peruttu",
    "paytrailProcessedCancelled2" : "Koska maksutapahtuma on peruttu, rekisteröitymistäsi ei ole voitu suorittaa loppuun.",
    "paytrailProcessedCancelled3" : "Voit nyt sulkea tämän ikkunan tai jatkaa rekisteröitymistä.",
    "paytrailProcessedProcessed1" : "Maksutapahtuma on käsitelty.",
    "paytrailProcessedProcessed2" : "Saat tapahtumavahvistuksen sähköpostiisi.",
    "paytrailProcessedProcessed3" : "Voit nyt sulkea tämän ikkunan.",
    "continuePreRegistration" : "jatka rekisteröitymistä",
    "privacyLink" : "https://www.aalto.fi/fi/palvelut/tietosuojailmoitukset",
    "privacyLinkText" :"Aalto-yliopiston viestinnän tietosuojailmoitus",
    "paytrailNoPass1": "Rekisteröinti epäonnistui – passit loppuneet.",
    "paytrailNoPass2": "Rekisteröinti tapahtumaan epäonnistui, maksusuoritusta ei tehty ajoissa ja passi on loppuunmyyty. Epäonnistuneesta rekisteröinnistä on toimitettu tieto tapahtumanjärjestäjälle ja maksu palautetaan."
  },
  "sv": {
    "eventRegister": "Registrering för evenemanget",
    "networkError": "Ett fel inträffade vid försök att kontakta nätverket. Vänligen försök igen senare.",
    "notFoundError": "Den begärda händelsen kunde inte hittas: ",
    "serverError": "Ett fel inträffade i en server. Kontakta supporten: crm-support@aalto.fi",
    "loadingEvent": "Vänta tills dataöverföringen har slutförts...",
    "paytrailProcessedSuccesfully1" : "Tack!",
    "paytrailProcessedSuccesfully2" : "Din betalning och registrering har behandlats. Du kommer att få ett kvitto på den e-postadress du har angett.",
    "paytrailProcessedSuccesfully3" : "Du kan nu stänga det här fönstret.",
    "paytrailProcessedCancelled1" : "Betalningen avbröts",
    "paytrailProcessedCancelled2" : "Eftersom din betalning avbröts slutfördes inte registreringsprocessen.",
    "paytrailProcessedCancelled3" : "Du kan nu stänga det här fönstret eller fortsätta registrera dig.",
    "paytrailProcessedProcessed1" : "Betalningshändelsen har behandlats.",
    "paytrailProcessedProcessed2" : "Du kommer att få en händelsebekräftelse i ditt e-postmeddelande.",
    "paytrailProcessedProcessed3" : "Du kan nu stänga det här fönstret.",
    "continuePreRegistration" : "fortsätt registreringen",
    "privacyLink" : "https://www.aalto.fi/en/services/privacy-notices",
    "privacyLinkText" :"Sekretessmeddelande för Aalto University kommunikation",
    "paytrailNoPass1": "Det går inte att skapa händelseregistrering - utsålda pass.",
    "paytrailNoPass2": "Händelseregistrering misslyckades eftersom betalningen inte mottogs i tid och pass slutsåld. Meddelande om misslyckad registrering har skickats till arrangören och betalningen återbetalas."
  }
}
</i18n>

<style>
html {
  overflow-y: auto;
}
.footer:visited {
  color: #757575;
}
.footer:hover {
  color: white;
}
.footer:link {
  color: #757575;
}

.footerSmall {
  font-size: 0.6em;
}

.footerNormal {
  font-size: 1em;
}
</style>
