<template>
  <div>
    <div class="topBanner">
      <v-btn class="ma-4" tile outlined color="success" @click="addNewSection">
        <v-icon left>add</v-icon> section
      </v-btn>
      <v-btn
        :disabled="!hasChanges"
        class="ma-4"
        tile
        outlined
        color="success"
        @click="save()"
      >
        <v-icon left>save</v-icon>save
      </v-btn>
      <v-btn
        :disabled="hasChanges"
        class="ma-4"
        tile
        outlined
        color="success"
        @click="showPreview()"
      >
        <v-icon left>web</v-icon>preview
      </v-btn>
      <v-btn class="ma-4" tile outlined color="success" @click="undo()">
        <v-icon left>undo</v-icon>undo
      </v-btn>

      <v-btn
        class="ma-4"
        tile
        outlined
        color="success"
        @click="fullscreen()"
        v-if="isInFrame()"
      >
        <v-icon left>fullscreen</v-icon>fullscreen
      </v-btn>
    </div>
    <v-row class="content" justify="center">
      <v-expansion-panels
        v-model="selectedPanel"
        class="mb-6"
        multiple
        focusable
        tile
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="title pa-0 pl-6">
            <template v-slot:default>
              <v-list-item three-line class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    >Event page: {{ value.eventName }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    {{ value.state
                    }}<span
                      class="ml-3 red--text"
                      v-if="missingKeyFields.length > 0"
                    >
                      Following mandatory field mappings are missing:
                      {{ missingKeyFields.join(', ') }}
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="overline">{{
                    supportedLangStr
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-tabs>
              <v-tab> finnish </v-tab>
              <v-tab> english </v-tab>
              <v-tab> swedish </v-tab>
              <v-tab-item>
                <v-row justify="center">
                  <v-col cols="6">
                    <v-text-field
                      label="Event Name"
                      hide-details="auto"
                      class="ma-2 pb-3"
                      v-model="value.eventNameFi"
                    />
                    <v-select
                      class="ma-2 pb-3"
                      v-model="value.marketingPageIdFi"
                      label="Marketing page"
                      :items="marketingPageOptions"
                      item-text="name"
                      item-value="value"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          v-if="
                            value.marketingPageIdFi != undefined &&
                            value.marketingPageIdFi != ''
                          "
                          class="mr-1"
                          tile
                          small
                          color="success"
                          @click="openMarketingPage(value.marketingPageIdFi)"
                        >
                          <v-icon small>forward</v-icon>
                        </v-btn>
                        <v-btn
                          tile
                          small
                          color="success"
                          @click="newMarketingPage()"
                        >
                          <v-icon small>add</v-icon>
                        </v-btn>
                      </template>
                    </v-select>
                    <v-img
                      v-if="value.imgUrl != '' && value.marketingPageIdFi == ''"
                      class="ma-2"
                      max-height="260"
                      contain
                      position="left"
                      :src="value.imgUrl"
                    ></v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-if="value.marketingPageIdFi == ''"
                      filled
                      name="input-7-4"
                      label="Description"
                      rows="3"
                      v-model="value.descriptionFi"
                      class="ma-2"
                    ></v-textarea>
                    <v-textarea
                      v-if="value.marketingPageIdFi == ''"
                      filled
                      name="input-7-4"
                      label="Long Description"
                      rows="4"
                      v-model="value.longDescriptionFi"
                      class="ma-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row justify="center">
                  <v-col cols="6">
                    <v-text-field
                      label="Event Name"
                      hide-details="auto"
                      class="ma-2 pb-3"
                      v-model="value.eventNameEn"
                    />
                    <v-select
                      class="ma-2 pb-3"
                      v-model="value.marketingPageIdEn"
                      label="Marketing page"
                      :items="marketingPageOptions"
                      item-text="name"
                      item-value="value"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          v-if="
                            value.marketingPageIdEn != undefined &&
                            value.marketingPageIdEn != ''
                          "
                          tile
                          small
                          color="success"
                          @click="openMarketingPage(value.marketingPageIdEn)"
                        >
                          <v-icon small>forward</v-icon>
                        </v-btn>
                        <v-btn
                          tile
                          small
                          color="success"
                          @click="newMarketingPage()"
                        >
                          <v-icon small>add</v-icon>
                        </v-btn>
                      </template>
                    </v-select>
                    <v-img
                      v-if="value.imgUrl != '' && value.marketingPageIdEn == ''"
                      class="ma-2"
                      max-height="260"
                      contain
                      position="left"
                      :src="value.imgUrl"
                    ></v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-if="value.marketingPageIdEn == ''"
                      filled
                      name="input-7-4"
                      label="Description"
                      rows="3"
                      v-model="value.descriptionEn"
                      class="ma-2"
                    ></v-textarea>
                    <v-textarea
                      v-if="value.marketingPageIdEn == ''"
                      filled
                      name="input-7-4"
                      label="Long Description"
                      rows="4"
                      v-model="value.longDescriptionEn"
                      class="ma-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row justify="center">
                  <v-col cols="6">
                    <v-text-field
                      label="Event Name"
                      hide-details="auto"
                      class="ma-2 pb-3"
                      v-model="value.eventNameSv"
                    />
                    <v-select
                      class="ma-2 pb-3"
                      v-model="value.marketingPageIdSv"
                      label="Marketing page"
                      :items="marketingPageOptions"
                      item-text="name"
                      item-value="value"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          v-if="
                            value.marketingPageIdSv != undefined &&
                            value.marketingPageIdSv != ''
                          "
                          tile
                          small
                          color="success"
                          @click="openMarketingPage(value.marketingPageIdSv)"
                        >
                          <v-icon small>forward</v-icon>
                        </v-btn>
                        <v-btn
                          tile
                          small
                          color="success"
                          @click="newMarketingPage()"
                        >
                          <v-icon small>add</v-icon>
                        </v-btn>
                      </template>
                    </v-select>
                    <v-img
                      v-if="value.imgUrl != '' && value.marketingPageIdSv == ''"
                      class="ma-2"
                      max-height="260"
                      contain
                      position="left"
                      :src="value.imgUrl"
                    ></v-img>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-if="value.marketingPageIdSv == ''"
                      filled
                      name="input-7-4"
                      label="Description"
                      rows="3"
                      v-model="value.descriptionSv"
                      class="ma-2"
                    ></v-textarea>
                    <v-textarea
                      v-if="value.marketingPageIdSv == ''"
                      filled
                      name="input-7-4"
                      label="Long Description"
                      rows="4"
                      v-model="value.longDescriptionSv"
                      class="ma-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-for="(item, i) in value.sections" :key="i">
          <v-expansion-panel-header
            draggable
            @dragstart="dragStart($event, i, 'section')"
            class="subtitle-1"
            @dragover="allowDrop"
            @drop="dropTo($event, i)"
            >{{ item.header }}
            <template v-slot:actions>
              <v-icon>arrow_drop_up</v-icon>
              <v-btn
                class="mr-4 ml-4"
                tile
                small
                outlined
                color="error"
                @click="stageSectionForDelete(i)"
                @click.native.stop
              >
                delete
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <form-section
              :validationOptions="validationOptions"
              :value="item"
              :langs="value.supportedLangs"
              @keyFieldChanged="keyFieldChanged"
              @fieldMoved="fieldMoved"
              @deleteField="deleteField"
              @addField="addField"
              :index="i"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- confirmation message -->
        <v-expansion-panel>
          <v-expansion-panel-header class="title pa-0 pl-6">
            <template v-slot:default>
              <v-list-item three-line class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    >Registration confirmation message</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-tabs>
              <v-tab> finnish </v-tab>
              <v-tab> english </v-tab>
              <v-tab> swedish </v-tab>
              <v-tab-item>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-textarea
                      filled
                      label="Message"
                      rows="4"
                      v-model="value.confirmationMessageFi"
                      class="ma-2"
                    />
                    <v-checkbox
                      class="ml-2"
                      v-model="value.confirmationMessageFiVisible"
                      label="Registration link visible"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-textarea
                      filled
                      label="Message"
                      rows="4"
                      v-model="value.confirmationMessageEn"
                      class="ma-2"
                    />
                    <v-checkbox
                      class="ml-2"
                      v-model="value.confirmationMessageEnVisible"
                      label="Registration link visible"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-textarea
                      filled
                      label="Message"
                      rows="4"
                      v-model="value.confirmationMessageSv"
                      class="ma-2"
                    />
                    <v-checkbox
                      class="ml-2"
                      v-model="value.confirmationMessageSvVisible"
                      label="Registration link visible"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <!-- preview -->
    <v-dialog
      v-model="previewDialog"
      max-width="850px"
      persistent
      scrollable
      v-if="!loading"
    >
      <registration-form
        @closeClicked="previewDialog = false"
        @saveClicked="previewDialog = false"
        v-model="previewEvent.forms"
        :isRegistered="false"
        :registerPeriodClosed="false"
        :skipLogicList="previewEvent.skipLogic"
        :eventId="previewEvent.id"
        :emailField="previewEvent.emailFieldId"
        :eventPaymentType="previewEvent.eventPayment"
        :eventPasses="previewEvent.passes"
        :hasKeyMappingFields="missingKeyFields.length == 0"
        :isOpen="previewDialog"
      />
    </v-dialog>

    <v-dialog :value="isLoading" max-width="450px" persistent scrollable>
      <v-card color="primary" dark>
        <v-card-text class="pa-3 mb-2">
          Processing, please wait
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- preview lang selector  -->

    <v-dialog v-model="dialogLangSelector" max-width="290">
      <v-card>
        <v-card-title class="headline center-text">
          Preview language?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="value.supportedLangs.includes(0)"
            color="green darken-1"
            text
            @click="selectPreviewLang('fi')"
          >
            finnish
          </v-btn>
          <v-btn
            v-if="value.supportedLangs.includes(1)"
            color="green darken-1"
            text
            @click="selectPreviewLang('en')"
          >
            english
          </v-btn>
          <v-btn
            v-if="value.supportedLangs.includes(2)"
            color="green darken-1"
            text
            @click="selectPreviewLang('sv')"
          >
            swedish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable persistent :value="deleteSection != -1" width="350">
      <v-card height="200">
        <v-card-title class="headline yellow lighten-2"
          >Delete Section?</v-card-title
        >
        <v-card-text class="pa-5 text-center">
          You are about to delete section. Are you sure?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            outlined
            tile
            small
            @click="deleteSection = -1"
          >
            <v-icon left small>delete</v-icon>cancel
          </v-btn>
          <v-btn color="warning" outlined tile small @click="removeSection()">
            <v-icon left small>warning</v-icon>delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EditorEvent } from '@/models/editor/editorEvent';
import FormSection from '@/components/FormEditor/FormSectionEditor.vue';
import RegistrationForm from './RegistrationForm/RegistrationForm.vue';

// RXJS
import { Observable } from 'rxjs';

// SERVICES
import { eventEditorService } from '@/services/eventEditorService';

// MODELS
import { Event } from '@/models/event';
import { EventImg } from '@/models/eventImg';
import { Form } from '@/models/form';
import { Localisation } from '@/models/editor/localisation';
import { NameValue } from '@/models/editor/nameValue';
import { Section } from '@/models/editor/section';

// ENUMS
import { Lang } from '@/enums/enums';

@Component({
  components: {
    FormSection,
    RegistrationForm,
  },
})
export default class DragDropEditor extends Vue {
  selectedPanel: number[] = [];
  previewDialog = false;
  deleteSection: number = -1;
  supportedLangStr: string = '';
  dialogLangSelector: boolean = false;
  previewEvent: Event = new Event();
  loading: boolean = false;
  missingKeyFields: string[] = [];
  marketingPageOptions: NameValue[] = [];

  hasEventChanged: boolean = false;
  undoClicked: boolean = false;
  saveClicked: boolean = false;

  get hasChanges() {
    return this.hasEventChanged;
  }

  @Prop() value!: EditorEvent;
  @Prop({ default: false }) inProgress!: boolean;

  @Watch('value', { immediate: true, deep: true })
  editorEventChanged(to: any, orig: any) {
    this.checkKeyfieldMappings();
    if (this.value.sections.length == 0 && !this.selectedPanel.includes(0)) {
      this.selectedPanel.push(0);
    }

    if (
      orig != null &&
      orig.id != '' &&
      !this.undoClicked &&
      !this.saveClicked
    ) {
      this.hasEventChanged = true;
    } else {
      this.saveClicked = false;
      this.undoClicked = false;
      this.hasEventChanged = false;
    }
  }

  isInFrame(): boolean {
    return window.location !== window.parent.location;
  }

  mounted() {
    this.marketingPageOptions.push({
      name: '-no marketing page-',
      value: '',
      active: true,
    });
    eventEditorService
      .getPages(this.$route.params.registrationId)
      .subscribe((result: NameValue[]) => {
        for (let o of result) {
          if (
            o.active ||
            this.value.marketingPageIdFi == o.value ||
            this.value.marketingPageIdEn == o.value ||
            this.value.marketingPageIdSv == o.value
          )
            this.marketingPageOptions.push(o);
        }
        //this.marketingPageOptions = this.marketingPageOptions.concat(result);
      });
  }

  newMarketingPage() {
    this.openMarketingPage('');
  }

  openMarketingPage(id: string) {
    let url = process.env.VUE_APP_MARKETINGLINK + id;
    window.open(url, '_blank')?.focus();
  }

  fullscreen() {
    window.open(document.location.href, '_blank');
  }

  get validationOptions(): { name: string; value: string }[] {
    let options: { name: string; value: string }[] = [
      { name: 'No validation', value: '' },
    ];

    options = options.concat(this.value.validationOptions);
    return options;
  }

  showPreview() {
    this.dialogLangSelector = true;
  }

  get isLoading() {
    return this.inProgress || this.loading;
  }

  keyFieldChanged(fieldType: number, fieldId: string) {
    this.$emit('keyFieldChanged', fieldType, fieldId);
  }

  fieldMoved(
    fromSectionIdx: number,
    fromFieldIdx: number,
    toSectionIdx: number,
    toFieldIdx: number
  ) {
    this.$emit(
      'fieldMoved',
      fromSectionIdx,
      fromFieldIdx,
      toSectionIdx,
      toFieldIdx
    );
  }

  addField(sectionIdx: number) {
    this.$emit('addField', sectionIdx);
  }

  deleteField(sectionIdx: number, fieldIdx: number) {
    this.$emit('deleteField', sectionIdx, fieldIdx);
  }

  undo() {
    this.$emit('undoEditorEvent');
    this.undoClicked = true;
  }

  save() {
    this.loading = true;

    this.saveEditorEvent().subscribe(() => {
      this.loading = false;
      this.saveClicked = true;
    });
  }

  checkKeyfieldMappings() {
    this.missingKeyFields = [];
    if (this.value.state.toLowerCase() != 'live') {
      return;
    }

    if (this.value.mappingEmailId == null || this.value.mappingEmailId == '') {
      this.missingKeyFields.push('Email');
    }

    if (
      this.value.mappingFirstNameId == null ||
      this.value.mappingFirstNameId == ''
    ) {
      this.missingKeyFields.push('Firstname');
    }

    if (
      this.value.mappingLastNameId == null ||
      this.value.mappingLastNameId == ''
    ) {
      this.missingKeyFields.push('Lastname');
    }
  }

  saveEditorEvent(): Observable<string> {
    return new Observable<string>((subscriber: any) => {
      eventEditorService
        .saveEditorEvent(this.value, this.$route.params.registrationId)
        .subscribe(
          (result: string) => {
            this.$emit(
              'saved',
              this.value.id,
              this.$route.params.registrationId
            );
            subscriber.next(result);
            subscriber.complete();
          },
          (err) => {
            this.loading = false;
          }
        );
    });
  }

  selectPreviewLang(langstr: string) {
    this.dialogLangSelector = false;
    this.loadPreview(langstr);
  }

  loadPreview(langstr: string) {
    this.loading = true;
    eventEditorService
      .getEventPreview(
        this.value.id,
        langstr,
        this.$route.params.registrationId
      )
      .subscribe(
        (result: Event) => {
          this.previewEvent = result;
          this.loading = false;
          this.previewDialog = true;
        },
        (error) => {
          console.log('error in save: ' + error);
          this.loading = false;
        }
      );
  }

  updateSupportedLangStr() {
    this.updateLocalisationsToEditorFields();
    this.supportedLangStr = '';
    for (let i of this.value.supportedLangs) {
      switch (i) {
        case Lang.fi:
          this.supportedLangStr = this.supportedLangStr + ' FI';
          break;
        case Lang.en:
          this.supportedLangStr = this.supportedLangStr + ' EN';
          break;
        case Lang.sv:
          this.supportedLangStr = this.supportedLangStr + ' SV';
          break;
      }
    }
  }

  updateLocalisationsToEditorFields() {
    let langs = this.value.supportedLangs;
    for (let s of this.value.sections) {
      for (let f of s.fields) {
        for (let l of langs) {
          if (f.localisations.find((loc) => loc.lang == l) == null) {
            //localisation not found -> new empty
            let newLoc = new Localisation();
            newLoc.lang = l;
            f.localisations.push(newLoc);
          }
        }
      }
    }
  }

  @Watch('value.eventNameFi')
  eventNameChanged_fi() {
    this.updateSupportedLangs(this.value.eventNameFi, Lang.fi);
    this.updateSupportedLangStr();
  }

  @Watch('value.eventNameEn')
  eventNameChanged_en() {
    this.updateSupportedLangs(this.value.eventNameEn, Lang.en);
    this.updateSupportedLangStr();
  }

  @Watch('value.eventNameSv')
  eventNameChanged_sv() {
    this.updateSupportedLangs(this.value.eventNameSv, Lang.sv);
    this.updateSupportedLangStr();
  }

  updateSupportedLangs(val: any, lang: Lang) {
    if (this.isNotNullOrEmpty(val)) {
      if (this.value.supportedLangs.indexOf(lang) == -1) {
        this.value.supportedLangs.push(lang);
      }
    } else {
      if (this.value.supportedLangs.indexOf(lang) != -1) {
        this.value.supportedLangs.splice(
          this.value.supportedLangs.indexOf(lang),
          1
        );
      }
    }
  }

  isNotNullOrEmpty(value: string | null | undefined): boolean {
    if (value == null || value == undefined || value == '') {
      return false;
    } else {
      return true;
    }
  }

  stageSectionForDelete(index: number) {
    this.deleteSection = index;
  }

  removeSection() {
    //alert("delete" + this.deleteSection);
    this.$emit('deleteSection', this.deleteSection);
    this.deleteSection = -1;
  }

  addNewSection() {
    this.$emit('addSection');
  }

  dragStart(ev: any, index: number, type: string) {
    //console.log("drag start: " + index);
    ev.dataTransfer.setData('index', index);
    ev.dataTransfer.setData('type', type);
  }

  allowDrop(ev: any) {
    //console.log("allowdDrop");
    ev.preventDefault();
  }

  dropTo(ev: any, index: number) {
    ev.preventDefault();
    const oldIdx = ev.dataTransfer.getData('index');
    const type = ev.dataTransfer.getData('type');
    if (type != 'section') {
      //only handle section drops
      return;
    }
    this.$emit('sectionMoved', oldIdx, index);
    //arrayUtils.move(this.items, oldIdx, index);
    /*
    console.log("dropped to:" + index);
    let data = ev.dataTransfer.getData("index");
    console.log(data);*/
  }
}
</script>

<style>
.v-dialog {
  position: absolute !important;
  top: 0 !important;
}

.topBanner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 55px;
  background-color: rgba(255, 255, 255, 1);
}

.content {
  margin: 2px;
  margin-top: 55px !important;
}
</style>
