// VUE
import EventComponent from '@/components/EventLayout.vue';

// MODELS
import { Equipment } from '@/models/fields/equipment';
import { Event } from '@/models/event';
import { FormField } from '@/models/formField';
import { Order } from '@/models/order';
import { Pass } from '@/models/pass';
import { PaytrailProvider } from '@/models/paytrailProvider';
import { Ticket } from '@/models/fields/ticket';
import { TicketAvailability } from '@/models/ticketAvailability';

// ENUMS
import { FieldType } from '@/enums/enums';


// GETS EVENT ORDER
export function getEventOrder(eventValue: Event): Order {
  const order = new Order();

  for (const field of getTicketFields(eventValue)) {
    const ticket = field.data as Ticket;
    const orderedPass = getPassById(eventValue, ticket.value);

    if (orderedPass != null) {
      let price = orderedPass.price;
      if (orderedPass.discount != undefined) {
        price = orderedPass.discount.discountedPrice;
      }

      order.items.push({
        title: orderedPass.translatedName,
        subtitle: price + '€',
      });
      order.totalPrice += price != null ? price : 0;
    }
  }

  return order;
}

// GENERATES AND CHECKS TICKET FIELDS
export function getTicketFields(eventValue: Event): FormField[] {
  const ticketFields: FormField[] = [];
  for (const form of eventValue.forms) {
    for (const field of form.fields) {
      if (field.type == FieldType.ticket) {
        ticketFields.push(field);
      }
    }
  }
  return ticketFields;
}

// UPDATES PASSES AVAILABILITYSTATUSES
export function updatePassesAvailabilities(
  eventValue: Event,
  ticketAvailabilities: TicketAvailability[]
) : Event {
  for (const pass of eventValue.passes) {
    const passAvailability = ticketAvailabilities.find(
      (x) => x.ticketId == pass.id
    );

    if (passAvailability?.amountAvailable != undefined) {
      pass.noOfPassesLeft = passAvailability.amountAvailable;
    }
  }

  return eventValue
}

// ADDS USED DISCOUND IDS TO EVENT
export function addUsedDiscountIdsToEvent(eventValue: Event) {
  eventValue.usedDiscountCodeIds = [];
  for (let ticket of getTicketFields(eventValue)) {
    if ((ticket.data as Ticket).value != undefined) {
      let pass = eventValue.passes.find(
        (x) => x.id == (ticket.data as Ticket).value
      );
      if (pass?.discount != undefined) {
        eventValue.usedDiscountCodeIds.push(pass?.discount.id);
      }
    }
  }

  return eventValue
}

// GETS PASS BY ID
function getPassById(eventValue: Event,id: string | undefined): Pass | null {
  if (id != undefined) {
    for (const pass of eventValue.passes) {
      if (pass.id == id) {

        return pass;
      }
    }
  }

  return null;
}

// CHECKS IF SELECTED TICKETS ARE AVAILABLE
export function resolveIsSelectedTicketsAvailable(
  eventValue : Event,
  ticketAvailabilities: TicketAvailability[]
) : boolean {
  let currentTicketsAvailable = true;

  for (const ticketField of getTicketFields(eventValue)) {
    const currentTicket = ticketField.data as Ticket;
    const availability = ticketAvailabilities.find(
      (x) => x.ticketId == currentTicket.value
    );

    if (availability == null || availability.amountAvailable == null) {
      continue;
    }

    if (availability.amountAvailable < 1) {
      currentTicketsAvailable = false;
    }
  }

  return currentTicketsAvailable;
}

// PAYTRAIL LOGIC
export function createPaytrailProviders(
  providers: PaytrailProvider[]
): HTMLFormElement[] {
  let forms: HTMLFormElement[] = [];
  for (let provider of providers) {
    let form = document.createElement('form');
    form.method = 'POST';
    form.action = provider.url;
    for (let param of provider.parameters)
      createInputHtmlElement(form, param.name, param.value);

    createButtonElement(form, provider.svg);
    forms.push(form);
  }
  return forms;
}

// PAYTRAIL LOGIC
function createInputHtmlElement(
  form: HTMLFormElement,
  name: string,
  value?: string
): void {
  const elem = document.createElement('input') as HTMLInputElement;
  if (value != null && value != undefined && value != '') {
    elem.value = value;
    elem.name = name;
    elem.type = 'hidden';
    form.appendChild(elem);
  }
}

// PAYTRAIL LOGIC
function createButtonElement(form: HTMLFormElement, imgSrc: string): void {
  const btn = document.createElement('button') as HTMLButtonElement;
  btn.className = 'paymentMethodButton';
  const img = document.createElement('img') as HTMLImageElement;
  img.src = imgSrc;
  btn.appendChild(img);
  form.appendChild(btn);
}

// GETS ORDERED AMOUNT
export function getOrderedAmount(equipment: Equipment): number {
  const ordered = equipment.ordered != null && equipment.ordered ? 1 : 0;
  return equipment.orderedQuantity != null && equipment.orderedQuantity > 1
    ? equipment.orderedQuantity
    : ordered;
}
