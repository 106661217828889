<template>
    <v-dialog scrollable v-model="isOpen" persistent width="500">
      <v-card min-height="500">
        <v-card-title class="headline grey lighten-2">
          {{ $t('paymentMethods') }}
        </v-card-title>
        <v-card-text v-if="isMethodsLoading">
          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="7"
              ><v-progress-circular
                :size="200"
                color="deep-purple accent-4"
                indeterminate
              ></v-progress-circular
            ></v-col>
            <v-col cols="2"></v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <div ref="paymentMethods" />
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PaymentMethodsComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ default: false }) isMethodsLoading!: boolean;

  emitIsUpdated() {
    this.$emit('ticketsNotAvailableDialogUpdated');
  }
}
</script>


<i18n>
{
  "en": {
    "paymentMethods": "Payment methods"
  },
  "fi": {
    "paymentMethods": "Maksutavat"
    },
  "sv": {
    "paymentMethods": "Betalningsmetod"
  }
}
</i18n>
