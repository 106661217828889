<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden" class="mt-2">
      <h1 :style="headerStyle">{{ data.label }}</h1>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { Header } from '@/models/fields/header';

@Component({})
export default class HeaderComponent extends Vue {
  @Prop({ default: new Header() }) data!: Header;

  get headerStyle() {
    let styleString = '';
    const h = this.data as Header;
    if (h.color != null) {
      styleString = 'color: ' + h.color + ';';
    }

    if (h.fontSize != null) {
      styleString = styleString + 'font-size: ' + h.fontSize + 'px;';
    }

    return styleString;
  }
}
</script>
