import { EventImg } from "./eventImg";
import { EventParagraph } from "./eventParagraph";
import { RegistrationForm } from "./registrationForm";
import { Lang, PaymentType } from "@/enums/enums";
import { SkipLogic } from "./skipLogic";
import { Pass } from "./pass";
import { EmbeddedMarketingPage } from "./embeddedMarketingPage";

export class Event {
  constructor() {
    this.paragraphs = [];
    this.forms = [];
    this.supportedLangs = [];
    this.skipLogic = [];
  }

  id = "";
  registrationLang?: Lang;
  registrationId = "";
  supportedLangs: Lang[];
  openForRegistrations = false;
  registrationModificationsAllowed = false;
  header!: string;
  subHeader?: string;
  image?: EventImg;
  marketingPageId: string = '';
  paragraphs: EventParagraph[];
  additionalInformation?: string;
  forms: RegistrationForm[];
  skipLogic: SkipLogic[];
  emailFieldId = "";
  firstnameFieldId = "";
  lastnameFieldId = "";
  //payableEvent = false;
  passes: Pass[] = [];
  eventPayment: PaymentType = PaymentType.none;
  preRegistrationId = "";
  hasDiscountCodes: boolean = false;
  usedDiscountCodeIds: string[] = [];
  confirmationMessage: string = "";
  confirmationMessageLinkVisible: boolean = false;
}
