import { Field } from "./interfaces/field";

export class TextParagraph implements Field {
  text!: string;
  bold = false;
  cursive = false;
  fontSize?: number;

  //field
  id!: string;
  isHidden!: boolean;
}
