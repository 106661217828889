import { FieldType } from '@/enums/enums';
import { Localisation } from './localisation';
import { Option } from './option';

export class EditorField {
  invoicingTypeCode?: number;
  isRelatedToInvoicing?: boolean = false;
  fieldType: FieldType = FieldType.undefined;
  id: string = "";
  name: string = "new field";
  questionResponseMaxLength?: number;
  numberOfRows: number = 4;
  order: number = 1;
  textFontSize?: number = 16;
  fileMaxSize: number = 10000;
  imageHeight?: number;
  imageWidth?: number;
  allowedFileTypes: string = "";
  icon: string = "";
  textColor: string = "#000000";
  isLine: boolean = false;
  required: boolean = true;
  isBold: boolean = false;
  isCursive: boolean = false;
  visibleByDefault: boolean = true;
  partOfTextboxGroup: boolean = false;
  lineMarginInPixels: number = 10;
  localisations: Localisation[] = [];
  keyFieldMapping: number = 0;
  validationRegExpId?: string;
  options: Option[] = [];
  equipmentId?: string;
}
