<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden">
      <hr :style="dividerStyle" />
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { Divider } from '@/models/fields/divider';

@Component({})
export default class DividerComponent extends Vue {
  @Prop({ default: new Divider() }) data!: Divider;

  get dividerStyle() {
    let styleString = '';
    const h = this.data as Divider;
    if (!h.isLine) {
      styleString = 'visibility: hidden;';
    }

    if (h.margin != null) {
      styleString = styleString + 'margin-top: ' + h.margin + 'px;';
    }

    return styleString;
  }
}
</script>
