import { Field } from "./interfaces/field";
import { InputField } from "./interfaces/inputField";

export class Equipment implements Field, InputField {
  label!: string;
  additionalInfoLabel?: string;
  price?: number;
  vat?: number;

  availableForMiniStand = false;
  availableForAaltoStand = false;

  maxOrderAmount?: number;
  orderedQuantity?: number;
  ordered?: boolean;

  prefix?: string;
  suffix?: string;

  //input field
  value?: string;
  helpText?: string;
  helpLinkText?: string;
  helpLinkUrl?: string;

  equipmentId!: string;
  //field
  id!: string;
  isHidden!: boolean;
}
