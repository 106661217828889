<template>
    <v-dialog :value="data" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{
          $t('standReserveFailed')
        }}</v-card-title>
        <v-card-text>{{ $t('standReserveFailedText') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="emitCloseDialog"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({})
export default class PreBookFailedComponent extends Vue {
  @Prop({ default: false }) data!: boolean;

  emitCloseDialog() {
    this.$emit('closeDialog');
  }
}
</script>
<i18n>
{
  "en": {
    "standReserveFailed": "Stand reservation failed",
    "standReserveFailedText": "The stand you tried to reserve is no longer available. Please, select a different one."
  },
  "fi": {
    "standReserveFailed": "Ständin varaus epäonnistui",
    "standReserveFailedText": "Ständi jota yritit varata, ei ole enää saatavilla. Pyydämme valitsemaan uuden vaihtoehdon."
  },
  "sv": {
    "standReserveFailed": "Stativ reservation misslyckades",
    "standReserveFailedText": "Det stativ du försökte reservera är inte längre tillgängligt. Välj en annan stativ."
  }
}
</i18n>
