<template>
  <div v-if="ready">
    <v-app-bar dense app color="#2d2d2d" dark>
      <v-app-bar-nav-icon>
        <v-img
          position="left"
          alt="Aalto Logo"
          :src="logo"
          contain
          height="50px"
          width="10px"
      /></v-app-bar-nav-icon>

      <v-toolbar-title>{{ eventHeader.name }}</v-toolbar-title>
    </v-app-bar>
    <v-container>
      <v-row>
        <v-col cols="12" v-if="currentRegistration == null">
          <div class="maxsize">
            <v-overlay :value="loading">
              <v-progress-circular :size="50" color="primary" indeterminate />
            </v-overlay>
            <qrcode-stream
              class="box center"
              @decode="onDecode"
              @init="onInit"
            />
            <p v-if="readyToScan" class="overline text-center">ready to scan</p>
            <p v-if="error != ''" class="overline text-center">{{ error }}</p>
            <p class="text-center">
              <v-btn color="primary" x-small @click="openManualInput">
                input manually
              </v-btn>
            </p>
          </div>
        </v-col>

        <v-col cols="12" v-else>
          <v-card v-if="currentRegistration != null" outlined class="maxsize">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="title mb-1">
                  {{ currentRegistration.firstName }}
                  {{ currentRegistration.lastName }}
                </v-list-item-title>
                <p
                  v-if="
                    currentRegistration.email && currentRegistration.email != ''
                  "
                >
                  <b>Email: </b>{{ currentRegistration.email }}
                </p>
                <p
                  v-if="
                    currentRegistration.company &&
                    currentRegistration.emcompanyail != ''
                  "
                >
                  <b>Company: </b> {{ currentRegistration.company }}
                </p>
                <p
                  v-if="
                    currentRegistration.standBooked != null &&
                    currentRegistration.standBooked != ''
                  "
                >
                  <b>Stand: </b> {{ currentRegistration.standBooked }}
                </p>
                <p
                  v-if="
                    currentRegistration.ticket != null &&
                    currentRegistration.ticket != ''
                  "
                >
                  <b>Ticket: </b> {{ currentRegistration.ticket }}
                </p>
              </v-list-item-content>
            </v-list-item>
            <v-alert
              v-if="
                currentRegistration != null &&
                currentRegistration.hasEventCheckin
              "
              dense
              outlined
              type="error"
              class="mt-2 mb-2"
            >
              Already registered!
            </v-alert>
            <v-card-actions class="mt-8">
              <v-spacer />
              <v-btn
                v-if="
                  currentRegistration != null &&
                  !currentRegistration.hasEventCheckin
                "
                text
                outlined
                color="primary"
                class="register-btn"
                @click="doCheckin"
                >check-in</v-btn
              >
              <v-btn
                v-else
                text
                outlined
                color="primary"
                class="register-btn"
                @click="currentRegistration = null"
                >read new</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="checkinResult" :timeout="2000" :color="resultColor">
      {{ checkinResultMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="checkinResult = false">
          close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog persistent :value="manualInput" width="350">
      <v-card height="200">
        <v-card-title class="headline primary lighten-2"
          >Manual input</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="manualInputValue"
            class="mt-2"
            label="Registration Code"
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            outlined
            tile
            small
            @click="manualInput = false"
          >
            <v-icon left small>cancel</v-icon>cancel
          </v-btn>
          <v-btn
            color="success"
            outlined
            tile
            small
            @click="validateManualInput"
          >
            <v-icon left small>send</v-icon>send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';

// SERVICES
import { eventCheckinService } from '@/services/eventCheckinService';

// MODELS
import { EventCheckin } from '@/models/eventRegister';
import { EventHeader } from '@/models/eventHeader';
import { EventRegistration } from '@/models/eventRegistration';

@Component({
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
})
export default class CheckinComponent extends Vue {
  @Prop() id!: string;

  eventHeader: EventHeader = new EventHeader();

  checkinResult: boolean = false;
  checkinResultMessage: string = '';
  resultColor: string = 'black';

  ready: boolean = false;
  manualInput: boolean = false;
  manualInputValue: string = '';
  result: string = '';
  error: string = '';
  loading: boolean = false;
  currentRegistration: EventRegistration | null = null;
  initialized: boolean = false;

  getQrStyle() {
    return ' width:80vw; height:80vw;';
    //return "height: 80%; width:80%";
  }

  fontSize() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return '3em';
      default:
        return '5em';
    }
  }

  get readyToScan(): boolean {
    return this.initialized && !this.loading;
  }

  get logo() {
    return require('@/assets/Aalto.png');
  }

  get qrStyle() {
    return (
      'height: ' + this.size + 'px; width: ' + this.size + 'px; margin: 0 auto'
    );
  }

  get regBoxHeight() {
    return 'height: ' + this.size + 'px';
  }

  get size() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 250;
      case 'sm':
        return 400;
      case 'md':
        return 450;
      case 'lg':
        return 600;
      case 'xl':
        return 600;
    }
  }

  openManualInput() {
    this.manualInputValue = '';
    this.manualInput = true;
  }

  validateManualInput() {
    this.onDecode(this.manualInputValue);
    this.manualInput = false;
  }

  mounted() {
    eventCheckinService
      .getEventHeader(this.id)
      .subscribe((result: EventHeader) => {
        this.eventHeader = result;
        this.ready = true;
      });
  }

  doCheckin() {
    if (this.currentRegistration != null) {
      const checkin: EventCheckin = {
        registrationId: this.currentRegistration.registrationId,
        eventId: this.currentRegistration.eventId,
      };

      this.loading = true;
      eventCheckinService
        .registerToEvent(checkin)
        .subscribe((result: boolean) => {
          if (result) {
            this.currentRegistration = null;
            this.resultColor = 'blue';
            this.checkinResultMessage = 'Check-in OK!';
            this.checkinResult = true;
          } else {
            this.resultColor = 'red';
            this.checkinResultMessage = 'Error occured in check-in!';
            this.checkinResult = true;
          }
          this.loading = false;
        });
    }
  }

  onDecode(result: string) {
    this.loading = true;
    eventCheckinService.getRegistration(this.id, result).subscribe(
      (reg: EventRegistration) => {
        this.currentRegistration = reg;
        this.loading = false;

        if (
          this.currentRegistration != null &&
          this.currentRegistration.hasEventCheckin
        ) {
          this.checkinResultMessage = 'Already registered!';
          this.checkinResult = true;
          this.resultColor = 'red';
        }
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  async onInit(promise: any) {
    try {
      await promise;
      this.initialized = true;
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        this.error = 'ERROR: you need to grant camera access permisson';
      } else if (error.name === 'NotFoundError') {
        this.error = 'ERROR: no camera on this device';
      } else if (error.name === 'NotSupportedError') {
        this.error = 'ERROR: secure context required (HTTPS, localhost)';
      } else if (error.name === 'NotReadableError') {
        this.error = 'ERROR: is the camera already in use?';
      } else if (error.name === 'OverconstrainedError') {
        this.error = 'ERROR: installed cameras are not suitable';
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.error = 'ERROR: Stream API is not supported in this browser';
      }
    }
  }
}
</script>

<style>
.register-btn {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.maxsize {
  max-width: 600px;
  max-height: 600px;
}

.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}

.some_element {
  position: relative;
  width: 70%;
  height: 0;
  padding-bottom: 20%;
}

.box {
  --b: 5px; /* thickness of the border */
  --c: blue; /* color of the border */
  --w: 50px; /* width of border */

  border: var(--b) solid transparent; /* space for the border */
  background: linear-gradient(var(--c), var(--c)) top left,
    linear-gradient(var(--c), var(--c)) top left,
    linear-gradient(var(--c), var(--c)) bottom left,
    linear-gradient(var(--c), var(--c)) bottom left,
    linear-gradient(var(--c), var(--c)) top right,
    linear-gradient(var(--c), var(--c)) top right,
    linear-gradient(var(--c), var(--c)) bottom right,
    linear-gradient(var(--c), var(--c)) bottom right;
  background-size: var(--b) var(--w), var(--w) var(--b);
  background-repeat: no-repeat;
}
</style>

<i18n>
{
  "en": {
    "register": "register"
  },
  "fi": {
    "register": "ilmoittaudu"
  },
  "sv": {
    "register": "registrera"
  }
}
</i18n>
