import { BaseService } from "./baseService";
import { Observable } from "rxjs";
import { Event } from "@/models/event";
import { StandArea } from "@/models/standArea";

class EventRegistrationService extends BaseService {
  // constructor () { super() }

  static instance: EventRegistrationService;

  static get Instance() {
    return this.instance || (this.instance = new this());
  }

  getEvent(eventId = "", lang = ""): Observable<Event> {
    if (lang != "") {
      return this.get<Event>("/event/" + eventId + "/" + lang);
    } else {
      return this.get<Event>("/event/" + eventId);
    }
  }

  getMarketingPage(id: string): Observable<EmbeddedMarketingPage> {
    return this.get<EmbeddedMarketingPage>("/page/" + id);
  }

  createOrUpdateRegistration(
    event: Event,
    registrationId = ""
  ): Observable<string> {
    return this.post<string, Event>(
      "/event/registration/" + registrationId,
      event
    );
  }

  processPaytrailReponse(
    paytrailResponseQueryStr: string
  ): Observable<string> {
    return this.get<string>("/event/paytrail", paytrailResponseQueryStr);
  }

  preRegisterEvent(event: Event): Observable<PaytrailFormData> {
    return this.post<PaytrailFormData, Event>("/event/preregistration", event);
  }

  getEventWithPreRegistration(
    preRegistrationId: string
  ): Observable<Event> {
    return this.getInPage<Event>("/event/preregistration/" + preRegistrationId);
  }

  sendFormFile(fieldId: string, formData: FormData): Observable<string> {
    return this.postFile("/event/field/" + fieldId + "/file", formData);
  }

  cancelRegistration(registrationId: string): Observable<void> {
    return this.get<void>("/event/registration/" + registrationId + "/cancel");
  }

  getAvailableStands(
    eventId: string,
    registrationId: string
  ): Observable<StandArea[]> {
    let params = "";
    if (registrationId != null && registrationId != "") {
      params = "rid=" + registrationId;
    }

    return this.getInPage<StandArea[]>("/event/" + eventId + "/stands", params);
  }

  preBookStand(standId: string): Observable<boolean> {
    return this.getInPage<boolean>("/event/stand/" + standId + "/prebook");
  }

  getDiscounts(eventId: string, code: string): Observable<Discount[]> {
    return this.getInPage<Discount[]>("/event/" + eventId + "/discount/" + code);
  }

  getEquipmentAvailability(
    eventId: string,
    registrationId = ""
  ): Observable<EquipmentAvailability[]> {
    let url = "/event/" + eventId + "/equipment/availability";
    if (registrationId != "") {
      url += "/?rid=" + registrationId;
    }

    return this.getInPage<EquipmentAvailability[]>(url);
  }

  getTicketAvailability(
    eventId: string,
    registrationId = ""
  ): Observable<TicketAvailability[]> {
    let url = "/event/" + eventId + "/ticket/availability";
    if (registrationId != "") {
      url += "/?rid=" + registrationId;
    }

    return this.getInPage<TicketAvailability[]>(url);
  }

  cancelStandPreBook(standId: string): Observable<void> {
    return this.getInPage<void>("/event/stand/" + standId + "/prebook/cancel");
  }

  getEventWithRegistration(
    readableEventId: string,
    registrationId: string,
    lang = ""
  ): Observable<Event> {
    return this.get<Event>(
      "/event/" +
      readableEventId +
      "/registration/" +
      registrationId +
      "/" +
      lang
    );
  }

  //new Paytrail API
  getPaymentProviders(amount: number): Observable<PaytrailPaymentResponse> {
    return this.getInPage<PaytrailPaymentResponse>("/paytrail/providers/" + amount);
  }

  createPayment(event: Event): Observable<PaytrailPaymentResponse> {
    return this.postInPage<PaytrailPaymentResponse, Event>("/paytrail", event);
  }

  paytrailCheckRedirect(
    paytrailResponseQueryStr: string
  ): Observable<string> {
    return this.get<string>("/paytrail/process/redirect", paytrailResponseQueryStr);
  }
  //new Paytrail API
}
import { Form } from "@/models/form";
import { EquipmentAvailability } from "@/models/equipmentAvailability";
import { TicketAvailability } from "@/models/ticketAvailability";
import { PaytrailFormData } from "@/models/paytrailFormData";
import { Discount } from "@/models/discount";
import { EmbeddedMarketingPage } from "@/models/embeddedMarketingPage";
import { PaytrailProvider } from "@/models/paytrailProvider";
import { PaytrailPaymentResponse } from "@/models/paytrailPaymentResponse";

export const eventRegistrationService = EventRegistrationService.Instance;
