<template>
  <div>
    <div @change="changed" ref="htmleditor" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Jodit } from 'jodit/build/jodit';

@Component({
  components: {},
})
export default class HtmlEditor extends Vue {
  editor: any;

  @Prop() value!: string;

  mounted() {
    const editorElement: any = this.$refs.htmleditor;
    this.editor = new Jodit(editorElement, {
      toolbarButtonSize: 'middle', //small | middle | large
      removeButtons: ['image', 'file', 'video', '|', 'link'],
    });
    this.editor.value = this.value;
    this.editor.change = this.changed;
  }

  changed(evt: any) {
    this.$emit('input', this.editor.value);
  }
}
</script>
