<template>
  <v-expand-transition>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ $t('standSelectionTitle') }}
      </v-card-title>
      <v-card-text class="mt-2" v-if="!loading">
        <v-alert
          class="mt-3"
          v-if="data.helpText != null && data.helpText != ''"
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          {{ data.helpText }}
          <template v-if="data.helpLinkText != '' && data.helpLinkUrl != ''">
            <br />
            <a :href="data.helpLinkUrl" target="_blank">{{
              data.helpLinkText
            }}</a>
          </template>
        </v-alert>
        <v-row justify="start">
          <v-checkbox
            dense
            class="mx-2"
            v-model="showMiniStands"
            :label="$t('miniStands')"
            @change="filterAvailableStands('miniStands')"
          ></v-checkbox>
          <v-checkbox
            dense
            class="mx-2"
            v-model="showNormalStands"
            :label="$t('normalStands')"
            @change="filterAvailableStands('normalStands')"
          ></v-checkbox>
          <v-checkbox
            dense
            class="mx-2"
            v-if="areInternalStandsAllowed"
            v-model="showInternalStands"
            :label="$t('aaltoStands')"
            @change="filterAvailableStands('aaltoStands')"
          ></v-checkbox>
        </v-row>
        <v-row align="center">
          <v-col cols="12" v-if="filteredStands.length > 1">
            <v-select
              :items="filteredStands"
              :disabled="filteredStands.length < 2"
              item-text="name"
              return-object
              :value="selectedStandArea"
              @change="updateSelectedStandArea"
              :label="$t('area')"
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-select
              :disabled="selectedStandArea.groups.length < 2"
              :items="selectedStandArea.groups"
              item-text="name"
              return-object
              :value="selectedStandGroup"
              @change="updateSelectedStandGroup"
              :label="$t('group')"
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-select
              :disabled="selectedStandGroup.id == null"
              :items="selectedStandGroup.items"
              item-text="name"
              return-object
              :value="selectedStandItem"
              @change="updateSelectedStandItem"
              :label="$t('stand')"
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="float-right"
              small
              :disabled="
                selectedStandItem.id == null ||
                selectedStandItem.id == ''
              "
              color="blue darken-1"
              text
              @click="addSelectedStand"
            >
              <v-icon left small>add</v-icon>
              {{ $t('add') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" v-if="preSelectedStands.length > 0">
            <span class="subtitle-2">{{ $t('selectedStands') }}</span>
            <v-sheet color="grey lighten-2">
              <v-chip
                v-for="(stand, i) in preSelectedStands"
                :key="'stand-d-' + i"
                class="ma-2"
                color="primary"
                label
                small
                close
                @click:close="removeSelectedStandAndUpdateAvailable(i)"
              >
                <v-icon left small>event_seat</v-icon>
                {{ stand.name }}
              </v-chip>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="mt-2" v-else>
        <v-row align="center" justify="center" style="height: 300px">
          <v-progress-circular :size="80" color="primary" indeterminate />
        </v-row>
        <v-row align="center" justify="center">
          <span>{{ $t('checkStandAvailability') }}</span>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="blue darken-1" text @click="cancelStands()">
          <v-icon left>close</v-icon>
          {{ $t('cancel') }}
        </v-btn>

        <v-btn color="blue darken-1" text @click="saveStands()">
          <v-icon left>save_alt</v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// MODELS
import { Stand } from '@/models/fields/stand'
import { StandArea } from '@/models/standArea'
import { StandGroup } from '@/models/standGroup'
import { StandItem } from '@/models/standItem'

@Component({})
export default class StandSelectionComponent extends Vue {
  @Prop({ default: new Stand() }) data!: Stand
  @Prop() loading!: boolean
  @Prop() filterShowMiniStands!: boolean
  @Prop() filterShowNormalStands!: boolean
  @Prop() filterShowInternalStands!: boolean
  @Prop() filteredStands!: StandArea[]
  @Prop() preSelectedStandArea!: StandItem[]
  @Prop() preSelectedStands!: StandItem[]
  @Prop() selectedStandArea!: StandArea
  @Prop() selectedStandGroup!: StandGroup
  @Prop() selectedStandItem!: StandItem
  @Prop() areInternalStandsAllowed!: boolean

  showMiniStands = false
  showNormalStands = false
  showInternalStands = false

  @Watch('filterShowMiniStands', {immediate: true})
    updateMini() {
      this.showMiniStands = this.filterShowMiniStands
    }

  @Watch('filterShowNormalStands', {immediate: true})
    updateNormal() {
      this.showNormalStands = this.filterShowNormalStands
    }

  @Watch('filterShowInternalStands', {immediate: true})
    updateInternal() {
      this.showInternalStands = this.filterShowInternalStands
    }

  addSelectedStand() {
    this.$emit('addSelectedStand', this.selectedStandItem)
  }

  filterAvailableStands(value: string) {
    this.$emit('filterAvailableStands', value)
  }

  updateSelectedStandItem(item: StandItem) {
    this.$emit('updateSelectedStandItem', item)
  }

  updateSelectedStandGroup(group: StandGroup) {
    this.$emit('updateSelectedStandGroup', group)
  }

  updateSelectedStandArea(area: StandArea) {
    this.$emit('updateSelectedStandArea', area)
  }

  cancelStands() {
    this.$emit('cancelStands')
  }

  saveStands() {
    this.$emit('saveStands')
  }

  removeSelectedStandAndUpdateAvailable(i: string) {
    this.$emit('removeSelectedStandAndUpdateAvailable', i)
  }
}
</script>
<i18n>
{
  "en": {
    "selectedStands": "Selected stands",
    "cancel": "cancel",
    "save": "finish",
    "checkStandAvailability": "Checking availability for stands...",
    "standSelectionTitle": "Event stand selection",
    "miniStands": "Mini stands",
    "normalStands": "Basic stands",
    "aaltoStands": "Aalto stands",
    "area": "Area",
    "group": "Group",
    "stand": "Stand",
    "add": "choose"
  },
  "fi": {
    "selectedStands": "Valitut messupaikat",
    "cancel": "peruuta",
    "save": "valmis",
    "checkStandAvailability": "Tarkistetaan messupaikkojen varaustilanne...",
    "standSelectionTitle": "Tapahtuman messupaikan valinta",
    "miniStands": "Ministändit",
    "normalStands": "Perusständit",
    "aaltoStands": "Aalto-ständit",
    "area": "Alue",
    "group": "Ryhmä",
    "stand": "Paikka",
    "add": "valitse"
  },
  "sv": {
    "selectedStands": "Valda stativ",
    "cancel": "avboka",
    "save": "avsluta",
    "checkStandAvailability": "Kontrollera tillgängligheten för stativ...",
    "standSelectionTitle": "Val av stativ",
    "miniStands": "Mini stativ",
    "normalStands": "Normal stativ",
    "aaltoStands": "Aalto stativ",
    "area": "Område",
    "group": "Grupp",
    "stand": "Plats",
    "add": "välja"
  }
}
</i18n>
