import { Field } from "./interfaces/field";

export class Divider implements Field {
  isLine!: boolean;
  margin?: number;

  //field
  id!: string;
  isHidden!: boolean;
}
