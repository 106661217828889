import { BaseService } from "./baseService";
import { Observable } from "rxjs";
import { EventHeader } from "@/models/eventHeader";
import { EventRegistration } from "@/models/eventRegistration";
import { EventCheckin } from "@/models/eventRegister";

class EventCheckinService extends BaseService {
  // constructor () { super() }

  static instance: EventCheckinService;

  static get Instance() {
    return this.instance || (this.instance = new this());
  }

  getRegistration(eventId: string, registrationId: string): Observable<EventRegistration> {
    return this.get<EventRegistration>("/checkin/" + eventId + "/" + registrationId);
  }

  getEventHeader(id: string): Observable<EventHeader> {
    return this.get<EventHeader>("/checkin/" + id);
  }

  registerToEvent(checkin: EventCheckin): Observable<boolean> {
    return this.post<boolean, EventCheckin>("/checkin", checkin);
  }
}
export const eventCheckinService = EventCheckinService.Instance;
