import { Field } from "./interfaces/field";

export class Image implements Field {
  src!: string;
  width?: number;
  height?: number;

  //field
  id!: string;
  isHidden!: boolean;
}
