import { EventImg } from "@/models/eventImg";
import { Lang } from "@/enums/enums";
import { Section } from './section';

export class EditorEvent {

  id = "";
  supportedLangs: Lang[] = [];
  state: string = "";

  validationOptions: { name: string, value: string }[] = [];

  mappingEmailId?: string;
  mappingFirstNameId?: string;
  mappingLastNameId?: string;
  mappingPhoneId?: string;
  mappingCompanyId?: string;
  mappingPaytrailEmailId?: string;

  eventName: string = "";
  imgUrl: string = "";
  image?: EventImg;

  eventNameFi: string = "";
  descriptionFi: string = "";
  longDescriptionFi: string = "";
  marketingPageNameFi: string = "";
  marketingPageIdFi: string | undefined;

  eventNameEn: string = "";
  descriptionEn: string = "";
  longDescriptionEn: string = "";
  marketingPageNameEn: string = "";
  marketingPageIdEn: string | undefined;

  eventNameSv: string = "";
  descriptionSv: string = "";
  longDescriptionSv: string = "";
  marketingPageNameSv: string = "";
  marketingPageIdSv: string | undefined;

  confirmationMessageFi: string = "";
  confirmationMessageEn: string = "";
  confirmationMessageSv: string = "";

  confirmationMessageFiVisible: boolean = false;
  confirmationMessageEnVisible: boolean = false;
  confirmationMessageSvVisible: boolean = false;

  sections: Section[] = [];
}
