import { Lang } from '@/enums/enums';

export class Localisation {

  id: string = "";
  lang: Lang = Lang.fi;
  description: string = "";
  answerMaxLength: string = "";
  equipmentAdditionalQuestion: string = "";
  equipmentname: string = "";
  eventname: string = "";
  eventPageTexts: string = "";
  sectionName: string = "";
  sectionSubHeader: string = "";
  hintText: string = "";
  htmlText: string = "";
  infoText: string = "";
  infoUrl: string = "";
  infoUrlText: string = "";
  longdescription: string = "";
  marketingPageName?: string;
  name: string = "";
  option: string = "";
  paragraph: string = "";
  placeholder: string = "";
  question: string = "";
  richText: string = "";
  prefix: string = "";
  suffix: string = "";
  ticketName: string = "";
  validationText: string = "";
  fileId: string = "";
}
