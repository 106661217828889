<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden" class="pt-3">
      <template v-if="data.options.length > 3">
        <v-select
          :items="data.options"
          item-text="name"
          item-value="value"
          v-model="data.value"
          :label="label"
          :rules="rules"
          dense
          :append-outer-icon="data.helpText != null ? 'info' : ''"
          @click:append-outer="showHelpText()"
          @change="evaluateSkipLogic(data.id)"
        />
      </template>
      <template v-else>
        <v-radio-group
          row
          :label="label"
          :rules="rules"
          v-model="data.value"
          :append-icon="data.helpText != null ? 'info' : ''"
          @click:append="showHelpText()"
          @change="evaluateSkipLogic(data.id)"
        >
          <v-layout class="ma-1" row>
            <v-radio
              :label="r.name"
              :value="r.value"
              :key="i"
              v-for="(r, i) in data.options"
            ></v-radio>
          </v-layout>
        </v-radio-group>
      </template>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { RadioButton } from '@/models/fields/radioButton';

@Component({})
export default class RadioButtonComponent extends Vue {
  @Prop({ default: new RadioButton() }) data!: RadioButton;
  @Prop() label!: string
  @Prop() rules!: { (value: any): any }[]

  showHelp = false

  get elementLabel() {
    let lblText = '';
    if (this.data.label != null) {
      lblText = this.data.label;
      if (this.data.mandatory != null && this.data.mandatory) {
        lblText = lblText + ' *';
      }
    }
    return lblText;
  }

  showHelpText() {
    this.$emit('showHelpText', true)
  }

  evaluateSkipLogic(sourceId: string) {
    this.$emit('skipLogic', sourceId)
  }
}
</script>
