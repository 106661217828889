var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.ready)?_c('div',[_c('v-app-bar',{attrs:{"dense":"","app":"","color":"#2d2d2d","dark":""}},[_c('v-app-bar-nav-icon',[_c('v-img',{attrs:{"position":"left","alt":"Aalto Logo","src":_vm.logo,"contain":"","height":"50px","width":"10px"}})],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.eventHeader.name))])],1),_c('v-container',[_c('v-row',[(_vm.currentRegistration == null)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"maxsize"},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1),_c('qrcode-stream',{staticClass:"box center",on:{"decode":_vm.onDecode,"init":_vm.onInit}}),(_vm.readyToScan)?_c('p',{staticClass:"overline text-center"},[_vm._v("ready to scan")]):_vm._e(),(_vm.error != '')?_c('p',{staticClass:"overline text-center"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('p',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":_vm.openManualInput}},[_vm._v(" input manually ")])],1)],1)]):_c('v-col',{attrs:{"cols":"12"}},[(_vm.currentRegistration != null)?_c('v-card',{staticClass:"maxsize",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title mb-1"},[_vm._v(" "+_vm._s(_vm.currentRegistration.firstName)+" "+_vm._s(_vm.currentRegistration.lastName)+" ")]),(
                  _vm.currentRegistration.email && _vm.currentRegistration.email != ''
                )?_c('p',[_c('b',[_vm._v("Email: ")]),_vm._v(_vm._s(_vm.currentRegistration.email)+" ")]):_vm._e(),(
                  _vm.currentRegistration.company &&
                  _vm.currentRegistration.emcompanyail != ''
                )?_c('p',[_c('b',[_vm._v("Company: ")]),_vm._v(" "+_vm._s(_vm.currentRegistration.company)+" ")]):_vm._e(),(
                  _vm.currentRegistration.standBooked != null &&
                  _vm.currentRegistration.standBooked != ''
                )?_c('p',[_c('b',[_vm._v("Stand: ")]),_vm._v(" "+_vm._s(_vm.currentRegistration.standBooked)+" ")]):_vm._e(),(
                  _vm.currentRegistration.ticket != null &&
                  _vm.currentRegistration.ticket != ''
                )?_c('p',[_c('b',[_vm._v("Ticket: ")]),_vm._v(" "+_vm._s(_vm.currentRegistration.ticket)+" ")]):_vm._e()],1)],1),(
              _vm.currentRegistration != null &&
              _vm.currentRegistration.hasEventCheckin
            )?_c('v-alert',{staticClass:"mt-2 mb-2",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Already registered! ")]):_vm._e(),_c('v-card-actions',{staticClass:"mt-8"},[_c('v-spacer'),(
                _vm.currentRegistration != null &&
                !_vm.currentRegistration.hasEventCheckin
              )?_c('v-btn',{staticClass:"register-btn",attrs:{"text":"","outlined":"","color":"primary"},on:{"click":_vm.doCheckin}},[_vm._v("check-in")]):_c('v-btn',{staticClass:"register-btn",attrs:{"text":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.currentRegistration = null}}},[_vm._v("read new")])],1)],1):_vm._e()],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000,"color":_vm.resultColor},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.checkinResult = false}}},'v-btn',attrs,false),[_vm._v(" close ")])]}}],null,false,1511925441),model:{value:(_vm.checkinResult),callback:function ($$v) {_vm.checkinResult=$$v},expression:"checkinResult"}},[_vm._v(" "+_vm._s(_vm.checkinResultMessage)+" ")]),_c('v-dialog',{attrs:{"persistent":"","value":_vm.manualInput,"width":"350"}},[_c('v-card',{attrs:{"height":"200"}},[_c('v-card-title',{staticClass:"headline primary lighten-2"},[_vm._v("Manual input")]),_c('v-card-text',[_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Registration Code"},model:{value:(_vm.manualInputValue),callback:function ($$v) {_vm.manualInputValue=$$v},expression:"manualInputValue"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","outlined":"","tile":"","small":""},on:{"click":function($event){_vm.manualInput = false}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("cancel")]),_vm._v("cancel ")],1),_c('v-btn',{attrs:{"color":"success","outlined":"","tile":"","small":""},on:{"click":_vm.validateManualInput}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("send")]),_vm._v("send ")],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }