<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden" class="pt-4 pb-2">
      <v-row>
        <v-col cols="1">
          <v-badge color="white" overlap icon="grade" :value="data.mandatory">
            <v-btn
              class="mx-2"
              small
              fab
              dark
              color="primary"
              @click="openStandDialog()"
            >
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-badge>
        </v-col>
        <v-col cols="11">
          <v-sheet color="grey lighten-2">
            <v-chip
              v-for="(stand, i) in data.selectedStands"
              :key="'stand-' + i"
              class="ma-2"
              color="primary"
              label
              small
              close
              @click:close="removeSelectedStand(i)"
            >
              <v-icon left small>event_seat</v-icon>
              {{ stand.name }}
            </v-chip>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue} from 'vue-property-decorator';

// MODELS
import { Stand } from '@/models/fields/stand';

@Component({})
export default class StandComponent extends Vue {
  @Prop({ default: new Stand() }) data!: Stand;
  @Prop({ default: false }) loading!: boolean;

  openStandDialog() {
    this.$emit('openStandDialog');
  }

  removeSelectedStand(index: string) {
    this.$emit('removeSelectedStand', index);
  }
}
</script>
