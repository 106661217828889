<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden">
      <v-textarea
        :label="label"
        :counter="data.maxLength"
        :persistent-hint="data.hintText != null"
        :hint="data.hintText"
        :prefix="data.prefix"
        :suffix="data.suffix"
        :placeholder="data.placeholder"
        :prepend-icon="data.icon"
        :rows="data.rows != null ? data.rows : 5"
        :rules="rules"
        v-model="data.value"
        filled
        :append-icon="data.helpText != null ? 'info' : ''"
        @click:append="showHelpText()"
      ></v-textarea>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { TextBox } from '@/models/fields/textBox';

@Component({})
export default class TextBoxComponent extends Vue {
  @Prop({ default: new TextBox() }) data!: TextBox
  @Prop() label!: string
  @Prop() rules!: { (value: any): any }[]

  showHelpText() {
    this.$emit('showHelpText', true)
  }

  emitUpdate(value: string) {
    this.$emit('update', value)
  }
}
</script>
