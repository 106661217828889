<template>
  <v-dialog v-model="isOpen" persistent max-width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ $t('cancelRegistration') }}
      </v-card-title>
      <v-card-text>
        <p class="mt-3">{{ $t('cancelRegistrationText') }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="emitUpdate(false)">
          {{ $t('close') }}
        </v-btn>
        <v-btn color="error" text @click="emitUpdate(true)">
          {{ $t('cancelEvent') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CancelComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean;

  emitUpdate(canceled: boolean) {
    this.$emit('cancelDialogIsUpdated', canceled);
  }
}
</script>

<i18n>
{
  "en": {
    "close": "close",
    "cancelEvent": "cancel your registration",
    "cancelRegistration": "Event registration cancellation",
    "cancelRegistrationText": "You are about to cancel you event registration. Click 'cancel registration' to complete cancellation."
  },
  "fi": {
    "close": "sulje",
    "cancelEvent": "peruuta ilmoittautuminen",
    "cancelRegistration": "Tapahtumailmoittautumisen peruutus",
    "cancelRegistrationText": "Olet peruuttamassa tapahtumailmoittautumistasi. Valitse 'peruuta ilmoittautuminen' viimeistelläksesi peruutus."
  },
  "sv": {
    "close": "stänga",
    "cancelEvent": "avbryt din registrering",
    "cancelRegistration": "Avbokning av evenemang",
    "cancelRegistrationText": "Du håller på att avbryta din evenemangsregistrering. Klicka på 'avbryt registrering' för att slutföra avbokningen."
  }
}
</i18n>
