// MODELS
import { FormField } from '@/models/formField';
import { NameValue } from '@/models/fields/nameValue';
import { Pass } from '@/models/pass';
import { StandItem } from '@/models/standItem';

// GETS ELEMENT LABEL
export function getElementLabel(value: FormField) {
  let lblText = '';
  if (value.data.label != null) {
    lblText = value.data.label;
    if (value.data.mandatory != null && value.data.mandatory) {
      lblText = lblText + ' *';
    }
  }
  return lblText;
}

// GETS PASSES ITEMS
export function getPassesItems(eventPasses: Pass[]): NameValue[] {
  const items: NameValue[] = [];

  for (let i = 0; i < eventPasses.length; i++) {
    let currentPass = eventPasses[i];
    if (currentPass.noOfPassesLeft > 0) {
      let priceText = eventPasses[i].price + '€';
      if (currentPass.discount != undefined)
        priceText =
          '<del>' +
          priceText +
          '</del> ' +
          currentPass.discount.discountedPrice +
          '€';

      items.push({
        name: eventPasses[i].translatedName + ' - ' + priceText,
        value: eventPasses[i].id,
      });
    }
  }
  return items;
}

// GETS RELATED STANDS
export function getRelatedStandList(standItemList: StandItem[]): string[] {
  let filterArr: string[] = [];

  for (const s of standItemList) {
    filterArr = filterArr.concat(s.relatedStandItems);
  }
  return filterArr;
}

// GETS FILTERED STAND LIST
export function getStandFilterList(preSelectedStands: StandItem[]): string[] {
  const selectedIds = preSelectedStands.map((x) => x.id);
  return getRelatedStandList(preSelectedStands).filter(
    (f) => !selectedIds.includes(f)
  );
}
