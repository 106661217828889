<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden">
      <template v-if="data.value != null && data.value != ''">
        <span class="subtitle-1">{{ label }}</span>
        <v-chip
          class="ma-2"
          color="primary"
          label
          small
          close
          @click:close="deleteFile(data.value)"
          @click="openFile(data.value)"
        >
          <v-icon left small>file_copy</v-icon>
          {{ data.fileName }}
        </v-chip>
      </template>
      <v-file-input
        v-else
        :label="label"
        :loading="loading"
        :accept="
          data.allowedFormats != null
            ? data.allowedFormats.toString()
            : ''
        "
        :show-size="data.maxSize != null"
        :placeholder="data.placeholder"
        :persistent-hint="data.hintText != null"
        :hint="data.hintText"
        :rules="rules"
        @change="uploadFile"
      />
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator'

// MODELS
import { FileField } from '@/models/fields/file'

@Component({})
export default class FileComponent extends Vue {
  @Prop({ default: new FileField() }) data!: FileField
  @Prop({default: false}) loading!: boolean
  @Prop() rules!: { (value: any): any }[]
  @Prop() label!: string

  deleteFile() {
    this.$emit('deleteFile')
  }

  uploadFile(file: File) {
    this.$emit('uploadFile', file)
  }

  openFile(url: string) {
    window.open(url, '_blank');
  }
}
</script>
