import { Mandatory } from "./interfaces/mandatory";
import { InputField } from "./interfaces/inputField";
import { Field } from "./interfaces/field";

export class FileField implements Mandatory, InputField, Field {
  value?: string;
  fileName?: string;
  label!: string;
  mandatory!: boolean;
  hintText?: string;
  allowedFormats?: string[] = [];
  maxSize?: number;
  placeholder?: string;

  //field
  id!: string;
  isHidden!: boolean;
}
