// HELPERS
import { find } from "lodash"
// MODELS
import { FormField } from '@/models/formField'
import { Mandatory } from '@/models/fields/interfaces/mandatory'
import { RegistrationForm } from '@/models/registrationForm'
import { SkipLogic } from '@/models/skipLogic'
import { SkipLogicRule } from '@/models/skipLogicRule'

// ENUMS
import { FieldType, SkipLogicOperator, SkipLogicGroupType } from '@/enums/enums'
import { CheckBox } from '@/models/fields/checkBox'


/**
 * Finds related skip logics
 * @date 8/16/2023 - 8:52:45 PM
 * @author Manne Muoniovaara <manne.muoniovaara@digia.com>
 *
 * @export
 * @param {string} sourceId
 * @param {SkipLogic[]} skipLogicList
 * @returns {SkipLogic[]}
 */
export function findRelatedSkipLogics(sourceId: string, skipLogicList: SkipLogic[]): SkipLogic[] {
  const relatedLogics: SkipLogic[] = []
  for (const l of skipLogicList) {
    for (const r of l.rules) {
      if (r.fieldId == sourceId) {
        relatedLogics.push(l)
        break
      }
    }
  }
  return relatedLogics
}


/**
 * Counts visible forms
 * @date 8/16/2023 - 8:52:16 PM
 * @author Manne Muoniovaara <manne.muoniovaara@digia.com>
 *
 * @export
 * @param {RegistrationForm[]} forms
 * @returns {number}
 */
export function visibleFormCount(forms: RegistrationForm[]): number {
  let count = 0
  for (let form of forms) {
    if (!form.isHidden) count++
  }
  return count
}


/**
 * Returns target for skip logic
 * @date 8/16/2023 - 8:51:32 PM
 * @author Manne Muoniovaara <manne.muoniovaara@digia.com>
 *
 * @export
 * @param {string} id
 * @param {boolean} isField
 * @param {RegistrationForm[]} value
 * @returns {(FormField | RegistrationForm | CheckBox | undefined)}
 */
export function getFieldOrForm(
  id: string,
  isField: boolean,
  value: RegistrationForm[]
): FormField | RegistrationForm | CheckBox | undefined {

  if (!isField) {
    const form = find(value, ['id', id]);
    return form ? form : undefined
  }

  for (const form of value) {
    for (const field of form.fields) {

      if ('checkBoxes' in field.data) {
        const checkBox = find(field.data.checkBoxes, ['id', id]);
        if (checkBox) {
          return field
        }
      }

      if (field.data.id === id) {
        return field
      }
    }
  }
}


/**
 * Evaluates Rules against Forms
 * @date 8/16/2023 - 8:13:30 PM
 * @author Manne Muoniovaara <manne.muoniovaara@digia.com>
 *
 * @export
 * @param {SkipLogicRule} rule
 * @param {RegistrationForm[]} forms
 * @returns {(boolean | null)}
 */
export function evaluateRuleAgainstForms(rule: SkipLogicRule, forms: RegistrationForm[]): boolean | null {
  let formField = getFieldOrForm(rule.fieldId, true, forms)

  if (formField === undefined) {
    return null
  }

  return evaluateRule(formField as FormField, rule)
}

/**
 * Evaluates skip logic rules
 * @date 8/16/2023 - 8:05:57 PM
 * @author Manne Muoniovaara <manne.muoniovaara@digia.com>
 *
 * @export
 * @param {SkipLogicRule} rule
 * @param {RegistrationForm[]} value
 * @returns {(boolean | null)}
 */
export function evaluateRule(formField: FormField, rule: SkipLogicRule): boolean | null {

  if (formField.type === FieldType.checkBoxGroup) {
    const { checkBoxes } = formField.data
    const checkBox = find(checkBoxes, ['id', rule.fieldId]);
    if (checkBox) {
      return rule.logicOperator === SkipLogicOperator.equals
        ? checkBox.value === rule.expectedCheckBoxValue
        : checkBox.value !== rule.expectedCheckBoxValue
    }
  }

  const { value: formFieldValue } = formField.data
  if (formField.type === FieldType.checkBox) {
    return rule.logicOperator === SkipLogicOperator.equals
      ? formFieldValue === rule.expectedCheckBoxValue
      : formFieldValue !== rule.expectedCheckBoxValue
  }

  if (formField.type === FieldType.radioButton) {
    return rule.logicOperator === SkipLogicOperator.equals
      ? formFieldValue === rule.optionId
      : formFieldValue !== rule.optionId
  }

  return null
}


/**
 * Returns true if object holds [mandatory] -key
 * @date 8/16/2023 - 8:13:48 PM
 * @author Manne Muoniovaara <manne.muoniovaara@digia.com>
 *
 * @export
 * @param {*} object
 * @returns {object is Mandatory}
 */
export function objIsMandatory(object: any): object is Mandatory {
  return 'mandatory' in object
}


/**
 * Evaluates logic in skip logic
 * @date 8/16/2023 - 8:16:15 PM
 * @author Manne Muoniovaara <manne.muoniovaara@digia.com>
 *
 * @export
 * @param {SkipLogic} logic
 * @param {RegistrationForm[]} forms
 * @returns {boolean}
 */
export function evaluateLogic(logic: SkipLogic, forms: RegistrationForm[]): boolean {
  let result = true
  const ruleResults: boolean[] = []

  for (const rule of logic.rules) {
    const r = evaluateRuleAgainstForms(rule, forms)
    if (r == null) {
      continue
    }
    ruleResults.push(r)
  }
  if (ruleResults.length === 0) return false

  if (logic.logicType == SkipLogicGroupType.and) {
    for (const r of ruleResults) {
      if (!r) {
        return false
      }
    }
  } else {
    for (const r of ruleResults) {
      if (r) {
        return true
      }
      return false
    }
  }

  return result
}
