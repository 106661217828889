import { CheckBox } from "./checkBox";
import { Mandatory } from "./interfaces/mandatory";
import { Field } from "./interfaces/field";

export class CheckBoxGroup implements Mandatory, Field {
  label?: string;
  checkBoxes: CheckBox[] = [];
  mandatory!: boolean;
  value: boolean = false;

  //field
  id!: string;
  isHidden!: boolean;

  /*
  get value() {
    const values: string[] = [];
    for (const s of this.checkBoxes.map(v => v.value)) {
      if (s != null && s != "") {
        values.push(s);
      }
    }

    return values.toString();
  }*/
}
