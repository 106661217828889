<template>
  <v-expand-transition>
  <v-card flat v-show="!data.isHidden" class="pt-4">
    <v-row dense>
      <v-col cols="8">
        <v-list-item-title class="headline-s mb-1">{{
          data.label
        }}</v-list-item-title>
        <v-list-item-subtitle
          ><span class="subtitle-2">{{ data.price }} € </span
          ><span class="font-italic caption"
            >(alv {{ data.vat }}%)</span
          ></v-list-item-subtitle
        >
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="data.orderedQuantity"
          v-if="
            data.maxOrderAmount != null &&
            data.maxOrderAmount > 1
          "
          :label="$t('quantity')"
          :suffix="data.suffix"
          :prefix="data.prefix"
          :rules="rules"
          :hint="
            data.maxOrderAmount != null
              ? 'Max ' + data.maxOrderAmount
              : ''
          "
        />
        <template v-else>
          <v-chip
            small
            v-if="data.maxOrderAmount == 0"
            color="red"
            label
            text-color="white"
          >
            {{ $t('soldOut') }}
          </v-chip>
          <v-checkbox v-else v-model="data.ordered" />
        </template>
      </v-col>
      <v-col cols="1">
        <v-icon
          v-if="data.helpText != null"
          class="float-right pt-5"
          @click="showHelpText()"
          >info</v-icon
        >
      </v-col>
    </v-row>
    <v-row dense v-if="data.additionalInfoLabel != null">
      <v-col cols="6">
        <v-text-field
          dense
          :label="data.additionalInfoLabel"
          v-model="data.value"
        />
      </v-col>
    </v-row>
  </v-card>
</v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { Equipment } from '@/models/fields/equipment';

@Component({})
export default class EquipmentComponent extends Vue {
  @Prop({ default: new Equipment() }) data!: Equipment
  @Prop() rules!: { (value: any): any }[]

  showHelpText() {
    this.$emit('showHelpText', true);
  }
}
</script>
<i18n>
{
  "en": {
    "quantity": "Quantity"
  },
  "fi": {
    "quantity": "Määrä"
  },
  "sv": {
    "quantity": "Kvantitet"
  }
}
</i18n>
