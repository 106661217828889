<template>
    <v-dialog v-model="isOpen" persistent max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('equipmentNotAvailable') }}
        </v-card-title>
        <v-card-text>
          <p>{{ $t('equipmentNotAvailableText') }}</p>
          <ul>
            <li
              v-for="(equipment, i) in notAvailableEquipments"
              :key="'list-' + i"
            >
              {{ equipment }}
            </li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="emitUpdate"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { ListItem } from "@/models/listItem";

@Component({})
export default class EquipmentsNotAvailableComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean
  @Prop() notAvailableEquipments!: ListItem[]

  emitUpdate() {
    this.$emit('equipmentsNotAvailableDialogIsUpdated')
  }
}
</script>

<i18n>
{
  "en": {
    "close": "close",
    "equipmentNotAvailable": "Some products are no longer available",
    "equipmentNotAvailableText": "Unfortunately, one or more of the products in the reservation are no longer available. Please, review following products: "
  },
  "fi": {
    "close": "sulje",
    "equipmentNotAvailable": "Osa tuotteista ei enää saatavilla",
    "equipmentNotAvailableText": "Yksi tai useampi varauksen tuotteista, ei valitettavasti ole enää saatavilla. Ole hyvä ja tarkista lomakeelta seuraavat tuotteet: "
  },
  "sv": {
    "close": "stänga",
    "equipmentNotAvailable": "Vissa produkter är inte längre tillgängliga",
    "equipmentNotAvailableText": "Tyvärr är en eller flera av produkterna i bokningen inte längre tillgängliga. Vänligen, granska följande produkter: "
  }
}
</i18n>
