<template>
  <v-tabs v-model="selectedTab" fixed-tabs>
    <v-tab> General </v-tab>
    <v-tab> finnish </v-tab>
    <v-tab> english </v-tab>
    <v-tab> swedish </v-tab>
    <v-tab-item>
      <!-- General Tab -->
      <v-card flat>
        <v-card-text>
          <v-row class="ma-0">
            <v-col cols="6">
              <v-text-field
                dense
                v-model="value.name"
                label="Name"
                hint="Editor name for field. Not shown in UI."
              />
              <v-select
                v-model="value.fieldType"
                @change="fieldTypeChanged"
                label="Type"
                :items="typeOptions"
                item-text="name"
                item-value="value"
              />
              <v-autocomplete
                v-model="value.equipmentId"
                label="Related Equipment"
                :items="equipmentOptions"
                item-value="id"
                item-text="name"
                v-if="value.fieldType == 12"
              />
              <v-select
                v-model="value.keyFieldMapping"
                :disabled="value.id == ''"
                persistent-hint
                :hint="keyFieldHint"
                @change="keyFieldChanged"
                label="Key field mapping"
                item-text="name"
                item-value="value"
                :items="keyFieldOptions"
                v-if="value.fieldType == '3'"
              />
              <v-text-field
                v-model="value.textFontSize"
                :rules="pixelRules"
                label="Text font size"
                v-if="value.fieldType == '0' || value.fieldType == '1'"
              />

              <v-menu bottom offset-y v-if="value.fieldType == '1'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="value.textColor"
                    label="Text colour"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon :color="value.textColor">palette</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-color-picker
                  v-model="value.textColor"
                  dot-size="22"
                  hide-inputs
                  hide-mode-switch
                />
              </v-menu>

              <v-text-field
                v-model="value.lineMarginInPixels"
                :rules="pixelRules"
                label="Line/Spacer Margin"
                v-if="value.fieldType == '2'"
                hint="in pixels"
              />
              <v-text-field
                v-model="value.imageHeight"
                label="Image Height Pixels"
                v-if="value.fieldType == '7'"
              />
              <v-text-field
                v-model="value.imageWidth"
                label="Image Width Pixels"
                v-if="value.fieldType == '7'"
              />
              <v-autocomplete
                v-model="value.icon"
                label="Icon"
                :items="iconList"
                v-if="value.fieldType == '3' || value.fieldType == '6'"
              >
                <template v-slot:selection="data">
                  <v-list-item-avatar class="ma-0">
                    <v-icon>{{ data.item }}</v-icon>
                  </v-list-item-avatar>
                  {{ data.item }}
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-icon>{{ data.item }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-select
                label="Validation"
                v-model="value.validationRegExpId"
                :items="validationOptions"
                item-text="name"
                item-value="value"
                v-if="value.fieldType == '3'"
              />
              <!-- <v-text-field label="File Types Allowed" dense hint="pdf;docx" v-if="value.fieldType == '8'" /> -->
              <!--<v-select label="Equipment" dense :items="equipmentList" v-if="value.fieldType == '12'" />-->
              <!--<v-switch label="Add additional equipment question" dense v-if="value.fieldType == '12'" />-->
              <v-text-field
                v-model="value.fileMaxSize"
                :rules="answerRules"
                label="File Max Size"
                hint="5242880 = 5Mb"
                v-if="value.fieldType == '8'"
              />

              <v-sheet
                max-height="240"
                style="overflow: auto"
                v-if="value.fieldType == '4' || value.fieldType == '10'"
              >
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(item, i) in value.options"
                      :key="'opt-' + i"
                      @click="openOptionDetails(i)"
                    >
                      <v-list-item-icon>
                        <v-icon>rule</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-btn
                  class="ma-4"
                  tile
                  small
                  color="success"
                  @click="addNewOption"
                  ><v-icon left>add</v-icon>add</v-btn
                >
              </v-sheet>
            </v-col>
            <v-col cols="6">
              <v-switch
                v-model="value.visibleByDefault"
                label="Visible by default"
              />
              <v-switch
                v-model="value.required"
                label="Is Required"
                v-if="
                  value.fieldType != '0' &&
                  value.fieldType != '1' &&
                  value.fieldType != '2' &&
                  value.fieldType != '7' &&
                  value.fieldType != '9'
                "
              />
              <v-switch
                v-model="value.isCursive"
                label="Text cursive"
                v-if="value.fieldType == '0'"
              />
              <v-switch
                v-model="value.isBold"
                label="Text bold"
                v-if="value.fieldType == '0'"
              />
              <v-switch
                v-model="value.isLine"
                label="Show as line"
                v-if="value.fieldType == '2'"
              />
              <v-text-field
                v-model="value.questionResponseMaxLength"
                :rules="answerRules"
                label="Answer Max Length"
                v-if="value.fieldType == '3' || value.fieldType == '6'"
              />
              <v-text-field
                v-model="value.numberOfRows"
                label="Number of rows visible"
                :rules="answerRules"
                class="ma-2"
                v-if="value.fieldType == '6'"
              />
              <v-switch
                v-model="value.isRelatedToInvoicing"
                label="Related to invoicing"
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '6' ||
                  value.fieldType == '4' ||
                  value.fieldType == '5' ||
                  value.fieldType == '10'
                "
              />
              <v-select
                item-value="value"
                item-text="name"
                label="Invoicing information"
                :items="invoicingInformationList"
                v-if="value.isRelatedToInvoicing"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <template v-for="(num, i) in 3">
      <v-tab-item :key="'loc-' + i">
        <template v-if="hasLocalisation(i)">
          <v-row
            class="ma-0"
            v-if="value.fieldType != '9' && value.fieldType != '0'"
          >
            <v-col cols="6">
              <v-text-field
                v-model="getLocalisation(i).question"
                label="Question/Title"
                dense
                v-if="
                  value.fieldType == '1' ||
                  value.fieldType == '3' ||
                  value.fieldType == '4' ||
                  value.fieldType == '5' ||
                  value.fieldType == '6' ||
                  value.fieldType == '8' ||
                  value.fieldType == '10' ||
                  value.fieldType == '13'
                "
              />
              <v-text-field
                v-model="getLocalisation(i).equipmentname"
                label="Equipment name"
                dense
                v-if="value.fieldType == '12'"
              />
              <v-text-field
                v-model="getLocalisation(i).equipmentAdditionalQuestion"
                label="Additional Equipment Info"
                dense
                v-if="value.fieldType == '12'"
              />
              <v-text-field
                v-model="getLocalisation(i).validationText"
                label="Validation error text"
                dense
                v-if="value.fieldType == '3' || value.fieldType == '6'"
              />
              <v-text-field
                v-model="getLocalisation(i).suffix"
                label="Text suffix"
                dense
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '6' ||
                  value.fieldType == '12'
                "
              />
              <v-text-field
                v-model="getLocalisation(i).prefix"
                label="Text prefix"
                dense
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '6' ||
                  value.fieldType == '12'
                "
              />
              <v-text-field
                v-model="getLocalisation(i).placeholder"
                label="Placeholder text"
                dense
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '6' ||
                  value.fieldType == '8'
                "
              />
              <v-text-field
                v-model="getLocalisation(i).hintText"
                label="Hint text"
                dense
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '6' ||
                  value.fieldType == '8'
                "
              />
            </v-col>
            <v-col cols="12" v-if="value.fieldType == '7'">
              <v-autocomplete
                v-model="getLocalisation(i).fileId"
                label="Image"
                :items="imageOptions"
                item-value="id"
                item-text="name"
              />
              <v-img
                max-height="250"
                contain
                :src="getImageUrl(getLocalisation(i).fileId)"
              />
            </v-col>
            <v-col :cols="getColsForType(value.fieldType)">
              <v-text-field
                v-model="getLocalisation(i).infoText"
                label="Info text"
                dense
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '4' ||
                  value.fieldType == '5' ||
                  value.fieldType == '6' ||
                  value.fieldType == '10' ||
                  value.fieldType == '11' ||
                  value.fieldType == '12' ||
                  value.fieldType == '13'
                "
              />
              <v-text-field
                v-model="getLocalisation(i).infoUrlText"
                label="Info URL button text"
                dense
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '4' ||
                  value.fieldType == '5' ||
                  value.fieldType == '6' ||
                  value.fieldType == '10' ||
                  value.fieldType == '11' ||
                  value.fieldType == '12' ||
                  value.fieldType == '13'
                "
              />
              <v-text-field
                v-model="getLocalisation(i).infoUrl"
                label="Info URL"
                dense
                v-if="
                  value.fieldType == '3' ||
                  value.fieldType == '4' ||
                  value.fieldType == '5' ||
                  value.fieldType == '6' ||
                  value.fieldType == '10' ||
                  value.fieldType == '11' ||
                  value.fieldType == '12' ||
                  value.fieldType == '13'
                "
              />
            </v-col>
          </v-row>
          <v-row class="ma-0" v-else>
            <v-col cols="12">
              <v-textarea
                v-model="getLocalisation(i).paragraph"
                label="Paragraph content"
                filled
                rows="8"
                v-if="value.fieldType == '0'"
              />
              <html-editor
                v-model="getLocalisation(i).htmlText"
                v-if="value.fieldType == '9'"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="mt-6">
            <h3 class="text-center">No localisation</h3>
          </div>
        </template>
      </v-tab-item>
    </template>
    <v-dialog scrollable persistent v-model="optionDialog" width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Option settings <v-spacer /><v-icon class="mr-3"
            >rule</v-icon
          ></v-card-title
        >
        <v-card-text class="grow">
          <v-row justify="center">
            <v-col cols="12">
              <v-text-field
                label="Option Name"
                hint="Not visible in form"
                hide-details="auto"
                class="pb-5"
                v-model="selectedOption.name"
              ></v-text-field>
              <v-text-field
                label="Name (FI)"
                hide-details="auto"
                v-model="selectedOption.nameFi"
              ></v-text-field>
              <v-text-field
                label="Name (EN)"
                hide-details="auto"
                v-model="selectedOption.nameEn"
              ></v-text-field>
              <v-text-field
                label="Name (SE)"
                hide-details="auto"
                v-model="selectedOption.nameSv"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" outlined tile small @click="deleteOption()">
            <v-icon left small>delete</v-icon>delete
          </v-btn>
          <v-btn
            color="success"
            outlined
            tile
            small
            @click="optionDialog = false"
          >
            <v-icon left small>done</v-icon>OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-tabs>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import HtmlEditor from '@/components/FormEditor/HtmlEditor.vue';

// SERVICES
import { eventEditorService } from '@/services/eventEditorService';

// MODELS
import { EditorEquipment } from '@/models/editorEquipment';
import { EditorField } from '@/models/editor/EditorField';
import { EventImg } from '@/models/eventImg';
import { ListProvider } from '@/models/editor/listProvider';
import { Localisation } from '@/models/editor/localisation';
import { NameValue } from '@/models/fields/nameValue';
import { Option } from '@/models/editor/option';

// ENUMS
import { FieldType, Lang } from '@/enums/enums';

@Component({
  components: {
    HtmlEditor,
  },
})
export default class ComponentEntityEditor extends Vue {
  @Prop() value!: EditorField;
  @Prop({
    default: function () {
      return [];
    },
  })
  validationOptions!: { name: string; value: string }[];
  selectedTab = 0;
  optionDialog = false;
  selectedOptionIndex: number = -1;
  selectedOption: Option = { name: 'new option', id: '' };
  imageOptions: EventImg[] = [];
  equipmentOptions: EditorEquipment[] = [];

  @Watch('value')
  valueChanged() {
    this.selectedTab = 0;
  }

  mounted() {
    this.getImageOptions();
    this.getEquipmentOptions();
  }

  getLocalisation(lang: Lang): Localisation | undefined {
    return this.value.localisations.find((x) => x.lang == lang);
  }

  hasLocalisation(tabIdx: number) {
    if (
      this.value.localisations == null ||
      this.value.localisations.length == 0
    )
      return false;

    return this.getLocalisation(tabIdx) != undefined;
  }
  /*
  get sortedLocalisations(): Localisation [] {
    if(this.value.localisations == null || this.value.localisations.length == 0)
      return [];

    return this.value.localisations.sort((a: Localisation, b: Localisation) =>
    {
      return a.lang - b.lang;
    });
  }*/

  getImageUrl(fileId: string) {
    let img = this.imageOptions.filter((x) => x.id == fileId);
    if (img != null && img.length > 0) {
      return img[0].src;
    }
    return '';
  }

  getEquipmentOptions() {
    eventEditorService
      .getEquipments(
        this.$route.query.id as string,
        this.$route.params.registrationId
      )
      .subscribe((result: EditorEquipment[]) => {
        this.equipmentOptions = result;
      });
  }

  getImageOptions() {
    eventEditorService
      .getImages(this.$route.params.registrationId)
      .subscribe((result: EventImg[]) => {
        this.imageOptions = result;
      });
  }

  fieldTypeChanged() {
    if (this.value.options == null) this.value.options = [];
  }

  getColsForType(fieldType: FieldType): number {
    if (
      fieldType == FieldType.stand ||
      fieldType == FieldType.ticket ||
      fieldType == FieldType.checkBoxGroup ||
      fieldType == FieldType.radioButton
    )
      return 12;

    return 6;
  }

  htmlUpdated(value: string) {
    this;
  }

  deleteOption() {
    this.value.options.splice(this.selectedOptionIndex, 1);
    this.optionDialog = false;
  }

  openOptionDetails(index: number) {
    this.selectedOptionIndex = index;
    this.selectedOption = this.value.options[index];
    this.optionDialog = true;
  }

  addNewOption() {
    this.value.options.push({ name: 'new option', id: '' });
  }

  get typeOptions(): { name: string; value: number }[] {
    return ListProvider.getTypeOptions();
  }

  get keyFieldOptions() {
    return ListProvider.keyFieldOptions;
  }

  pixelRules = [this.mustBeNumber, this.pixelRule];

  answerRules = [this.mustBeNumber];

  get keyFieldHint() {
    if (this.value.id == '') {
      return 'field must be saved before keyfield mapping!';
    }
    return '';
  }

  keyFieldChanged() {
    this.$emit('keyFieldChanged', this.value.keyFieldMapping, this.value.id);
  }

  mustBeNumber(r: any) {
    if (r != undefined && r != '' && isNaN(r)) {
      return 'must be a number';
    }
    return true;
  }

  pixelRule(r: any) {
    if (r != '' && r > 100) {
      return 'font must be less than 100';
    }

    if (r != '' && r < 0) {
      return 'font must be positive integer';
    }

    return true;
  }
  /*
  getLangIndex(lang: Lang): number {
    for(var i = 0; i < this.value.localisations.length; i += 1) {
        if(this.value.localisations[i].lang === lang) {
            return i;
        }
    }
    return -1;
  }*/

  get equipmentList(): string[] {
    return ['equipment list', 'equiopments todo'];
  }
  get invoicingInformationList(): NameValue[] {
    return ListProvider.invoicingTypeCode;
  }

  get iconList(): string[] {
    return ListProvider.icons;
  }
}
</script>
