<template>
  <v-dialog v-model="isOpen" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ $t('orderSummary') }}
      </v-card-title>
      <v-list disabled two-line flat>
        <v-subheader>{{ header }}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-row no-gutters>
              <v-col cols="1">
                <v-list-item-title v-html="i + 1 + '.'"></v-list-item-title>
              </v-col>
              <v-col cols="8">
                <v-list-item-title v-html="item.title"></v-list-item-title>
              </v-col>
              <v-col cols="3">
                <v-list-item-title v-html="item.subtitle"></v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <hr class="ma-2" />
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            {{ $t('totalAmount') }}: {{ totalPrice }}€
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="emitUpdate(false)">
          {{ $t('cancelOrder') }}
        </v-btn>
        <v-btn color="primary" text @click="emitUpdate(true)">
          {{ $t('toPayment') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { ListItem } from "@/models/listItem";

@Component({})
export default class PaymentSummaryComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean
  @Prop() header!: string
  @Prop() items!: ListItem[]
  @Prop() totalPrice!: number

  emitUpdate(toPayment: boolean) {
    this.$emit('paymentSummaryDialogIsUpdated', toPayment)
  }
}
</script>

<i18n>
{
  "en": {
    "cancelOrder": "cancel",
    "orderSummary": "Order summary",
    "toPayment": "to payment",
    "totalAmount": "Total amount"
  },
  "fi": {
    "cancelOrder": "peruuta",
    "orderSummary": "Tilauksen yhteenveto",
    "toPayment": "maksuun",
    "totalAmount": "Tilauksen yhteissumma"
  },
  "sv": {
    "cancelOrder": "avbryt",
    "orderSummary": "Ordersammanfattning",
    "toPayment": "till betalning",
    "totalAmount": "Totala summan"
  }
}
</i18n>
