import { Validatable } from "./interfaces/validatable";
import { Mandatory } from "./interfaces/mandatory";
import { InputField } from "./interfaces/inputField";
import { Field } from "./interfaces/field";

export class TextInput implements Validatable, Mandatory, InputField, Field {
  label!: string;
  mandatory!: boolean;
  maxLength?: number;
  hintText?: string;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  validation?: string;
  validationErrorMessage?: string;
  icon?: string;

  //input interface
  value?: string;
  helpText?: string;
  helpLinkText?: string;
  helpLinkUrl?: string;

  //field
  id!: string;
  isHidden!: boolean;
}
