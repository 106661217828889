export enum FieldType {
  undefined = -1,
  textParagraph = 0,
  header = 1,
  divider = 2,
  textInput = 3,
  radioButton = 4,
  checkBox = 5,
  textBox = 6,
  image = 7,
  file = 8,
  html = 9,
  checkBoxGroup = 10,
  stand = 11,
  equipment = 12,
  ticket = 13,
  checkBoxGroupCB = 20
}

export enum Lang {
  fi = 0,
  en = 1,
  sv = 2
}

export enum PaymentType {
  none = 0,
  paytrail = 1,
  invoice = 2
}

export enum SkipLogicGroupType {
  and = 225080000,
  or = 225080001
}

export enum SkipLogicOperator {
  equals = 225080000,
  notEquals = 225080001
}

export enum SkipLogicActionType {
  hide = 225080000,
  showAndSetAsOptional = 225080001,
  showAndSetAsRequired = 225080002,
  show = 225080003
}
