<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden" class="pt-2">
      <v-text-field
        :label="label"
        :counter="data.maxLength"
        :persistent-hint="data.hintText != null"
        :hint="data.hintText"
        :prefix="data.prefix"
        :suffix="data.suffix"
        :placeholder="data.placeholder"
        :prepend-icon="data.icon"
        :rules="rules"
        :value="data.value"
        @input="emitUpdate"
        :append-icon="data.helpText != null ? 'info' : ''"
        @click:append="showHelpText()"
      />
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { TextInput } from '@/models/fields/textInput';

@Component({})
export default class TextInputComponent extends Vue {
  @Prop({ default: new TextInput() }) data!: TextInput
  @Prop() label!: string
  @Prop() rules!: { (value: any): any }[]

  emitUpdate(value: string) {
    this.$emit('update', value)
  }

  showHelpText() {
    this.$emit('showHelpText', true)
  }
}
</script>
