import Vue from "vue";
import VueRouter from "vue-router";
import MainApp from "@/App.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:eventId/:registrationId?",
    name: "EventRegistration",
    component: MainApp
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
