// MODELS
import { EditorEvent } from '@/models/editor/editorEvent'

//ENUMS
import { FieldType, Lang } from '@/enums/enums'

interface ConfirmKeyMapping {
  id: string,
  type: number
}

// MAPS KEYS TO FIELDS
export function doKeyFieldMapping(editorEvent: EditorEvent, confirmKeyMapping: ConfirmKeyMapping) {
  switch (confirmKeyMapping.type) {
    case 1: //email
      editorEvent.mappingEmailId = confirmKeyMapping.id
      break
    case 2: //first name
      editorEvent.mappingFirstNameId = confirmKeyMapping.id
      break
    case 3: //last name
      editorEvent.mappingLastNameId = confirmKeyMapping.id
      break
    case 4: //phone
      editorEvent.mappingPhoneId = confirmKeyMapping.id
      break
    case 5: //company
      editorEvent.mappingCompanyId = confirmKeyMapping.id
      break
    case 6: //paytrail email
      editorEvent.mappingPaytrailEmailId = confirmKeyMapping.id
      break
  }

  return editorEvent
}

// FIXES TEXT FIELD MAPPINGS
export function fixTextFieldMappings(editorEvent: EditorEvent) {
  for (let s of editorEvent.sections) {
    for (let f of s.fields) {
      if (f.fieldType == FieldType.textInput && f.keyFieldMapping != 0) {
        switch (f.keyFieldMapping) {
          case 1: //email
            if (editorEvent.mappingEmailId != f.id)
              f.keyFieldMapping = 0
            break
          case 2: //first name
            if (editorEvent.mappingFirstNameId != f.id)
              f.keyFieldMapping = 0
            break
          case 3: //last name
            if (editorEvent.mappingLastNameId != f.id)
              f.keyFieldMapping = 0
            break
          case 4: //phone
            if (editorEvent.mappingPhoneId != f.id)
              f.keyFieldMapping = 0
            break
          case 5: //company
            if (editorEvent.mappingCompanyId != f.id)
              f.keyFieldMapping = 0
            break
          case 6: //paytrail email
            if (editorEvent.mappingPaytrailEmailId != f.id)
              f.keyFieldMapping = 0
            break
        }
      }
    }
  }

  return editorEvent
}

export function currentKeyFieldMapping(editorEvent: EditorEvent, id: string): number {
  if (editorEvent.mappingEmailId == id) {
    return 1;
  }
  if (editorEvent.mappingFirstNameId == id) {
    return 2;
  }
  if (editorEvent.mappingLastNameId == id) {
    return 3;
  }
  if (editorEvent.mappingPhoneId == id) {
    return 4;
  }
  if (editorEvent.mappingCompanyId == id) {
    return 5;
  }
  if (editorEvent.mappingPaytrailEmailId == id) {
    return 6;
  }
  return 0;
}
