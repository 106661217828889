import { BaseService } from "./baseService";
import { Observable } from "rxjs";
import { EditorEvent } from '@/models/editor/editorEvent';
import { Event } from "@/models/event";
import { EventImg } from '@/models/eventImg';
import { EditorEquipment } from '@/models/editorEquipment';
import { NameValue } from "@/models/editor/nameValue";

class EventEditorService extends BaseService {
  // constructor () { super() }

  static instance: EventEditorService;

  static get Instance() {
    return this.instance || (this.instance = new this());
  }

  getEventPreview(id: string = "", lang: string = "", key: string = ""): Observable<Event> {
    if (lang != "") {
      return this.get<Event>("/editor/preview/" + id + "/" + lang, "", { headers: { apikey: key } });
    } else {
      return this.get<Event>("/editor/preview/" + id, "", { headers: { apikey: key } });
    }
  }

  getEditorEvent(id: string = "", key: string = ""): Observable<EditorEvent> {
    return this.get<EditorEvent>("/editor/event/" + id, "", { headers: { apikey: key } });
  }

  saveEditorEvent(evt: EditorEvent, key: string = ""): Observable<string> {
    return this.post<string, EditorEvent>("/editor/event", evt, { headers: { apikey: key } });
  }

  getEquipments(id: string = "", key: string = ""): Observable<EditorEquipment[]> {
    return this.get<EditorEquipment[]>("/editor/event/" + id + "/equipments", "", { headers: { apikey: key } });
  }

  getImages(key: string = ""): Observable<EventImg[]> {
    return this.get<EventImg[]>("/editor/images", "", { headers: { apikey: key } });
  }

  getPages(key: string = ""): Observable<NameValue[]> {
    return this.get<NameValue[]>("/editor/pages", "", { headers: { apikey: key } });
  }

}
export const eventEditorService = EventEditorService.Instance;
