import { Field } from "./interfaces/field";

export class Header implements Field {
  label!: string;
  color?: string;
  fontSize?: number;

  //field
  id!: string;
  isHidden!: boolean;
}
