<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden">
      <div class="pt-2">
        <v-checkbox
          style="display: inline-block"
          class="ma-0 mr-4"
          dense
          :label="label"
          v-model="data.value"
          @change="skipLogic(data.id)"
        />
        <v-icon
          class="help-text"
          v-if="data.helpText != null ? 'info' : ''"
          @click="showHelpText()"
          >info</v-icon
        >
      </div>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// MODELS
import { CheckBox } from '@/models/fields/checkBox'

@Component({})
export default class CheckBoxComponent extends Vue {
  @Prop({ default: new CheckBox() }) data!: CheckBox
  @Prop() label!: string

  showHelp = false

  skipLogic(id: string) {
    this.$emit('skipLogic', id)
  }

  showHelpText() {
    this.$emit('showHelpText', true)
  }
}
</script>
<style scoped>
.help-text {
  top: -7px;
  left: 0px;
  right: auto;
  position: relative;
}
</style>
