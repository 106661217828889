<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden" class="pt-3">
      <template v-if="passesItems.length > 0">
        <v-text-field
          :loading="discountValidationInProgress"
          :value="discountCode"
          v-if="hasDiscountCodes"
          :label="$t('discountCode')"
          @focus="resetDiscountField()"
          @blur="validateDiscountCode()"
          @input="emitDiscountCode"
          v-on:keyup.enter="validateDiscountCode()"
        >
          <template v-slot:append>
            <v-tooltip bottom v-if="ticketValidationState == 'validated'">
              <template v-slot:activator="{ on, attrs }">
                <span class="discountValidState">
                  {{ $t('discountState_valid') }}
                </span>
                <v-icon v-bind="attrs" v-on="on" color="green">check</v-icon>
              </template>
              <span v-html="ticketDiscountText" />
            </v-tooltip>
            <span
              v-if="ticketValidationState == 'error'"
              class="discountErrorState"
            >
              {{ $t('discountState_notvalid') }} </span
            ><v-icon color="red" v-if="ticketValidationState == 'error'"
              >error</v-icon
            >
            <v-icon
              color="green"
              v-if="ticketValidationState == 'check'"
              @click="validateDiscountCode()"
              >search</v-icon
            >
          </template>
        </v-text-field>
        <template>
          <v-select
            :disabled="isTicketDisabled"
            :items="passesItems"
            item-text="name"
            item-value="value"
            v-model="data.value"
            :label="label"
            :rules="rules"
            @change="ticketChanged()"
            dense
            :append-outer-icon="data.helpText != null ? 'info' : ''"
            @click:append-outer="showHelpText()"
          >
            <div slot="item" slot-scope="{ item }" v-html="item.name" />
            <div slot="selection" slot-scope="{ item }" v-html="item.name" />
          </v-select>
        </template>
      </template>
      <div v-else class="ma-4 text-center red--text title">
        {{ $t('soldOut') }}
      </div>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { NameValue } from '@/models/fields/nameValue';
import { Ticket } from '@/models/fields/ticket';

@Component({})
export default class TicketComponent extends Vue {
  @Prop({ default: new Ticket() }) data!: Ticket;
  @Prop() passesItems!: NameValue[];
  @Prop() rules!: { (value: any): any }[];
  @Prop() label!: string;
  @Prop() discountCode!: string;
  @Prop() ticketValidationState!: string;
  @Prop() ticketDiscountText!: string;
  @Prop({ default: false }) discountValidationInProgress!: boolean;
  @Prop({ default: false }) isTicketDisabled!: boolean;
  @Prop({ default: false }) hasDiscountCodes!: boolean

  showHelpText() {
    this.$emit('showHelpText');
  }

  ticketChanged() {
    this.$emit('ticketChanged');
  }

  validateDiscountCode() {
    this.$emit('validateDiscountCode');
  }

  resetDiscountField() {
    this.$emit('resetDiscountField');
  }

  emitDiscountCode(value: string) {
    this.$emit('discountCode', value);
  }
}
</script>
<i18n>
{
  "en": {
    "discountCode": "Promo code",
    "discountState_valid": "- verified -",
    "discountState_notvalid": "- not valid or not available -",
    "soldOut": "SOLD-OUT"
  },
  "fi": {
    "discountCode": "Markkinointikoodi",
    "discountState_valid": "- vahvistettu -",
    "discountState_notvalid": "- virheellinen tai ei saatavilla -",
    "soldOut": "LOPPUUNMYYTY"
  },
  "sv": {
    "discountCode": "Marknadsföringskod",
    "discountState_valid": "- verifierad -",
    "discountState_notvalid": "- inte giltig eller inte tillgänglig -",
    "soldOut": "UTSÅLD"
  }
}
</i18n>
