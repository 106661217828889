<template>
  <v-dialog v-model="isOpen" persistent width="500">
    <v-card>
      <v-card-title class="headline">
        {{ $t('paymentTermsTitle') }}
      </v-card-title>
      <v-card-text
        ><div class="pb-4">{{ $t('paymentTerms') }}</div>
        <v-checkbox v-model="isAccepted" :label="$t('paymentTermsCheckBox')" />
        <div class="text-h6 pb-2">{{ $t('paytrailPaymentTermsTitle') }}</div>
        <div class="pb-2">{{ $t('paytrailPaymentTermsText') }}</div>
        <div class="break-line">{{ $t('paytrailPaymentTermsAddress') }}</div>
        <a :href="$t('paytrailPaymentTermsLink')" target="_blank">{{
          stripHttps($t('paytrailPaymentTermsLink'))
        }}</a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="emitIsUpdated(false)">
          {{ $t('cancelOrder') }}
        </v-btn>
        <v-btn
          :disabled="!isAccepted"
          color="green darken-1"
          text
          @click="emitIsUpdated(true)"
        >
          {{ $t('toPayment') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TermsComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean;

  isAccepted = false;

  emitIsUpdated(isAccepted: boolean) {
    this.$emit('termsIsUpdated', isAccepted);
  }

  stripHttps(url: string) {
    return url.replace('https://', '')
  }
}
</script>


<i18n>
{
  "en": {
    "cancelOrder": "cancel",
    "paymentTerms": "The ticket you selected will be reserved for you when you proceed to payment. Reservation will be canceled, if the payment is not completed within 30 minutes.",
    "paymentTermsCheckBox": "I accept the terms",
    "paymentTermsTitle": "Payment terms",
    "paytrailPaymentTermsTitle": "Payment Service Provider",
    "paytrailPaymentTermsText": "Paytrail Plc acts as a collecting payment service provider and is an authorized Payment Institution. Paytrail Plc will be shown as the recipient on your bank or credit card statement. Paytrail Plc will forward the payment to the merchant. For reclamations, please contact the website the payment was made to.",
    "paytrailPaymentTermsAddress": "Paytrail Plc\nInnova 2\nLutakonaukio 7\n40100 Jyväskylä\nBusiness ID 2122839-7",
    "paytrailPaymentTermsLink": "https://www.paytrail.com/en/consumer/information-about-paying",
    "toPayment": "to payment"
  },
  "fi": {
    "cancelOrder": "peruuta",
    "paymentTerms": "Valitsemasi lippu varataan sinulle, kun siirryt maksamaan. Lipun varaus purkautuu, mikäli maksua ei ole suoritettu 30 minuutin sisällä.",
    "paymentTermsCheckBox": "Hyväksyn ehdot",
    "paymentTermsTitle": "Maksuehdot",
    "paytrailPaymentTermsTitle": "Maksupalveluntarjoaja",
    "paytrailPaymentTermsText": "Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.",
    "paytrailPaymentTermsAddress": "Paytrail Oyj, y-tunnus: 2122839-7\nInnova 2\nLutakonaukio 7\n40100 Jyväskylä",
    "paytrailPaymentTermsLink": "https://www.paytrail.com",
    "toPayment": "maksuun"
  },
  "sv": {
    "cancelOrder": "avbryt",
    "paymentTerms": "Biljetten du valt kommer att reserveras åt dig när du fortsätter med betalningen. Bokningen kommer att annulleras om betalningen inte slutförs inom 30 minuter.",
    "paymentTermsCheckBox": "Jag accepterar villkoren",
    "paymentTermsTitle": "Betalningsvillkor",
    "paytrailPaymentTermsTitle": "Betalningsförmedlare",
    "paytrailPaymentTermsText": "Betalningsförmedlingstjänsten utförs och som leverantör står Paytrail Oyj(2122839-7) i samarbete med banker och kreditinstitut. Betalningar med Visa Electron eller MasterCard-kort syns Paytrail Oyj som mottagare i korträkningen och även förmedlar betalningen till köpmannen. Paytrail Oyj har verksamhetstillstånd för betalningsinstitut beviljat av Finansinspektionen. Vid klagomål ber vi er i första hand vara i kontakt med nätbutiken.",
    "paytrailPaymentTermsAddress": "Paytrail Oyj\nFO-nummer: 2122839-7\nInnova 2\nLutakonaukio 7 40100 Jyväskylä",
    "paytrailPaymentTermsLink": "https://www.paytrail.com/kuluttaja/information-om-betalning",
    "toPayment": "till betalning"
  }
}
</i18n>
