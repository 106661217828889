<template>
  <v-row>
    <v-col cols="12">
      <v-col class="mb-4">
        <p
          v-if="value.marketingPageId == null || value.marketingPageId == ''"
          class="headline"
        >
          {{ value.header }}
        </p>
        <p
          v-if="value.marketingPageId == null || value.marketingPageId == ''"
          class="subtitle-1 font-weight-regular"
          v-html="value.subHeader"
        />
      </v-col>

      <v-img
        contain
        v-if="
          (value.marketingPageId == null || value.marketingPageId == '') &&
          value.image != null
        "
        :src="value.image.src"
        class="my-3 ml-3"
        :width="value.image.width"
        :height="value.image.height"
      />
    </v-col>
    <v-col class="mb-5 ml-3" cols="12">
      <div
        v-html="value.additionalInformation"
        v-if="value.marketingPageId == null || value.marketingPageId == ''"
      />
      <div v-else v-html="marketingPage.bodyContent" />
    </v-col>
  </v-row>
</template>
<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { EmbeddedMarketingPage } from '@/models/embeddedMarketingPage';
import { Event } from '@/models/event';

@Component({})
export default class HeaderComponent extends Vue {
  @Prop() value!: Event
  @Prop() marketingPage!: EmbeddedMarketingPage
}
</script>
