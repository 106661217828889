<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>language</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-if="supportedLangs.indexOf(0) > -1"
        @click="emitUpdate('fi')"
      >
        <v-list-item-title>Suomi</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="supportedLangs.indexOf(1) > -1"
        @click="emitUpdate('en')"
      >
        <v-list-item-title>English</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="supportedLangs.indexOf(2) > -1"
        @click="emitUpdate('sv')"
      >
        <v-list-item-title>Svenska</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { Lang } from "@/enums/enums";

@Component({})
export default class MenuComponent extends Vue {
  @Prop() supportedLangs!: Lang[]

  emitUpdate(languageCode: string) {
    this.$emit('menuIsUpdated', languageCode)
  }
}
</script>
