import { InputField } from "./interfaces/inputField";
import { Field } from "./interfaces/field";
import { Mandatory } from "./interfaces/mandatory";

export class Ticket implements InputField, Field, Mandatory {
  mandatory = true;

  hasDiscountCodes: boolean = false;

  label!: string;
  valueName?: string;
  //input interface
  value?: string;
  helpText?: string;
  helpLinkText?: string;
  helpLinkUrl?: string;

  //field
  id!: string;
  isHidden = false;
}
