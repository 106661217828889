<template>
  <div v-if="value != undefined">
    <v-row justify="center">
      <v-col cols="6">
        <span class="subtitle-2">Section</span>
        <v-text-field
          label="Header"
          hide-details="auto"
          class="ma-2 mb-5"
          v-model="value.header"
        />
        <v-switch
          class="ma-2 mb-5"
          v-model="value.visibleByDefault"
          label="Visible by default"
        />
        <span class="subtitle-2">Translations</span>
        <v-text-field
          label="Name (FI)"
          hide-details="auto"
          class="ma-2"
          v-model="value.nameFi"
        ></v-text-field>
        <v-text-field
          label="Name (EN)"
          hide-details="auto"
          class="ma-2"
          v-model="value.nameEn"
        ></v-text-field>
        <v-text-field
          label="Name (SE)"
          hide-details="auto"
          class="ma-2 mb-6"
          v-model="value.nameSv"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <span class="subtitle-2">Components</span>
        <form-component
          :validationOptions="validationOptions"
          :value="value.fields"
          :langs="langs"
          @keyFieldChanged="keyFieldChanged"
          @fieldMoved="fieldMoved"
          @deleteField="deleteField"
          @addField="addField"
          :sectionIdx="index"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormComponent from '@/components/FormEditor/FormComponentEditor.vue';

// MODELS
import { Section } from '@/models/editor/section';

// ENUMS
import { Lang } from '@/enums/enums';

@Component({
  components: {
    FormComponent,
  },
})
export default class FormSectionEditor extends Vue {
  @Prop() value!: Section;
  @Prop({
    default: function () {
      return [];
    },
  })
  validationOptions!: { name: string; value: string }[];

  @Prop({ default: 0 }) index!: number;
  @Prop({
    default: function () {
      return [];
    },
  })
  langs!: Lang[];

  fieldMoved(
    fromSectionIdx: number,
    fromFieldIdx: number,
    toSectionIdx: number,
    toFieldIdx: number
  ) {
    //console.log(fromSectionIdx + fromFieldIdx + toSectionIdx + toFieldIdx)
    this.$emit(
      'fieldMoved',
      fromSectionIdx,
      fromFieldIdx,
      toSectionIdx,
      toFieldIdx
    );
  }

  keyFieldChanged(fieldType: number, fieldId: string) {
    this.$emit('keyFieldChanged', fieldType, fieldId);
  }

  addField(sectionIdx: number) {
    this.$emit('addField', sectionIdx);
  }

  deleteField(sectionIdx: number, fieldIdx: number) {
    this.$emit('deleteField', sectionIdx, fieldIdx);
  }
}
</script>
