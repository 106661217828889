<template>
    <v-dialog v-model="isOpen" persistent max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('registerSuccess') }}
        </v-card-title>
        <v-card-text>
          <p
            class="mt-3"
            v-if="
              confirmationMessage == null ||
              confirmationMessage == ''
            "
          >
            {{ $t('registerThankYou') }}
          </p>
          <p class="mt-3" v-html="registrationSuccessMessage" />
          <p class="mt-3" v-if="confirmationMessageLinkVisible">
            <span
              >{{ $t('registerSuccessTextLink') }}
              <a :href="afterRegistrationLink">{{
                $t('registerSuccessTextLink_link')
              }}</a>
              {{ $t('registerSuccessTextLink2') }}
            </span>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emitUpdate()">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ResultsComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean
  @Prop({ default: '' }) afterRegistrationLink!: string
  @Prop({ default: '' }) confirmationMessage!: string
  @Prop({ default: false }) confirmationMessageLinkVisible!: boolean

  emitUpdate() {
    this.$emit('resultsDialogIsUpdated')
  }

  get registrationSuccessMessage() {
    if (
      this.confirmationMessage == null ||
      this.confirmationMessage == ''
    )
      return this.$t('registerSuccessText');
    else return this.confirmationMessage;
  }
}
</script>

<i18n>
{
  "en": {
    "close": "close",
    "registerSuccess": "Your registration is complete",
    "registerThankYou": "Thank you!",
    "registerSuccessText": "Your registration for the event is now complete. An order confirmation email will be sent to the contact email provided on the form.",
    "registerSuccessTextLink": "Please copy the ",
    "registerSuccessTextLink_link": "LINK",
    "registerSuccessTextLink2": " in order to edit or cancel your registration."
  },
  "fi": {
    "close": "sulje",
    "registerSuccess": "Rekisteröityminen onnistunut",
    "registerThankYou": "Kiitos!",
    "registerSuccessText": "Rekisteröitymisesi tapahtumaan on valmis. Saat pian tilausvahvistuksen ilmoittamaasi sähköpostiosoitteeseen.",
    "registerSuccessTextLink": "Ole hyvä ja kopioi ",
    "registerSuccessTextLink_link": "LINKKI,",
    "registerSuccessTextLink2": " jonka avulla voit muuttaa rekisteröitymistietojasi tai peruuttaa osallistumisesi."
  },
  "sv": {
    "close": "stänga",
    "registerSuccess": "Din registrering är klar",
    "registerThankYou": "Tack!",
    "registerSuccessText": "Din registrering för evenemanget är nu klar. Ett orderbekräftelsemail kommer att skickas till kontaktmeddelandet på formuläret.",
    "registerSuccessTextLink": "Kopiera ",
    "registerSuccessTextLink_link": "LÄNK",
    "registerSuccessTextLink2": " för att redigera eller avbryta din registrering.",
    "cancelEvent": "avbryt din registrering",
    "cancelRegistration": "Avbokning av evenemang",
    "cancelRegistrationText": "Du håller på att avbryta din evenemangsregistrering. Klicka på 'avbryt registrering' för att slutföra avbokningen."
  }
}
</i18n>
