<template>
  <div>
    <!-- textParagraph -->
    <template v-if="value.type == 0">
      <TextParagraph :data="value.data" />
    </template>

    <!-- header -->
    <template v-if="value.type == 1">
      <Header :data="value.data" />
    </template>

    <!-- divider -->
    <template v-if="value.type == 2">
      <Divider :data="value.data" />
    </template>

    <!-- textInput -->
    <template v-if="value.type == 3">
      <TextInput
        :data="value.data"
        :label="elementLabel"
        :rules="elementRules"
        @update="updateValue"
        @showHelpText="showInputHelpText = true"
      />
    </template>

    <!-- radioButton -->
    <template v-if="value.type == 4">
      <RadioButton
        :data="value.data"
        :label="elementLabel"
        :rules="elementRules"
        @update="updateValue"
        @skipLogic="evaluateSkipLogic"
        @showHelpText="showInputHelpText = true"
      />
    </template>

    <!-- checkBox -->
    <template v-if="value.type == 5">
      <CheckBox
        :data="value.data"
        :label="elementLabel"
        @update="updateValue"
        @skipLogic="evaluateSkipLogic"
        @showHelpText="showInputHelpText = true"
      />
    </template>

    <!-- checkBoxGroup -->
    <template v-if="value.type == 10">
      <CheckBoxGroup
        :data="value.data"
        @groupCheckBoxChanged="groupCheckBoxChanged"
        @showHelpText="showInputHelpText = true"
      />
    </template>

    <!-- textBox -->
    <template v-if="value.type == 6">
      <TextBox
        :data="value.data"
        :label="elementLabel"
        :rules="elementRules"
        @update="updateValue"
        @showHelpText="showInputHelpText = true"
      />
    </template>

    <!-- image -->
    <template v-if="value.type == 7">
      <ImageComponent
        :data="value.data"
      />
    </template>

    <!-- file -->
    <template v-if="value.type == 8">
      <FileComponent
        :data="value.data"
        :label="elementLabel"
        :loading="fieldLoading"
        :rules="elementRules"
        @deleteFile="deleteFile"
        @uploadFile="handleFileUpload"
      />
    </template>

    <!-- html -->
    <template v-if="value.type == 9">
      <v-expand-transition>
        <v-card flat v-show="!value.data.isHidden">
          <div v-html="value.data.html" />
        </v-card>
      </v-expand-transition>
    </template>

    <!-- stand --->
    <template v-if="value.type == 11">
      <Stand
        :data="value.data"
        @openStandDialog="openStandDialog()"
        @removeSelectedStand="removeSelectedStand"
      />
    </template>

    <!-- equipment --->
    <template v-if="value.type == 12">
      <Equipment
          :data="value.data"
          :rules="elementRules"
          @showHelpText="showInputHelpText = true"
      />
    </template>

    <!-- ticket -->
    <template v-if="value.type == 13">
      <Ticket
        :data="value.data"
        :hasDiscountCodes="hasDiscountCodes()"
        :isTicketDisabled="isTicketDisabled()"
        :label="elementLabel"
        :rules="elementRules"
        :passesItems="passesItems"
        :ticketValidationState="ticketValidationState"
        :discountCode="discountCode"
        :discountValidationInProgress="discountValidationInProgress"
        :ticketDiscountText="ticketDiscountText"
        @resetDiscountField="resetDiscountField()"
        @validateDiscountCode="validateDiscountCode()"
        @ticketChanged="ticketChanged()"
        @discountCode="updateDiscountCode"
        @showHelpText="showInputHelpText = true"
      />
    </template>

    <!-- stand selection dialog -->
    <v-dialog v-model="showStandSelect" persistent width="500">
      <StandSelection
        :data="value.data"
        :loading="fieldLoading"
        :filterShowMiniStands="filterShowMiniStands"
        :filterShowNormalStands="filterShowNormalStands"
        :filterShowInternalStands="filterShowInternalStands"
        :filteredStands="filteredStands"
        :preSelectedStands="preSelectedStands"
        :selectedStandArea="selectedStandArea"
        :selectedStandGroup="selectedStandGroup"
        :selectedStandItem="selectedStandItem"
        :areInternalStandsAllowed="areInternalStandsAllowed"
        @addSelectedStand="addSelectedStand()"
        @cancelStands="cancelStands()"
        @filterAvailableStands="filterAvailableStands"
        @removeSelectedStandAndUpdateAvailable="(index) => removeSelectedStandAndUpdateAvailable(index, true)"
        @saveStands="saveStands()"
        @updateSelectedStandGroup="updateSelectedStandGroup"
        @updateSelectedStandArea="updateSelectedStandArea"
        @updateSelectedStandItem="updateSelectedStandItem"
      />
    </v-dialog>

    <!-- stand already slected dialog-->
    <PreBookFailed
      :data="preBookFailedDialog"
      @closeDialog="preBookFailedDialog = false"
    />

    <!-- help text dialog -->
    <v-dialog v-model="showInputHelpText" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{
            value.data.label != null ? value.data.label : $t('helpTextHeader')
          }}
        </v-card-title>
        <v-card-text class="mt-2">{{ value.data.helpText }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="openHelpLink"
            v-if="
              value.data.helpLinkText != null && value.data.helpLinkUrl != null
            "
          >
            <v-icon left>link</v-icon>
            {{ value.data.helpLinkText }}
          </v-btn>

          <v-btn color="blue darken-1" text @click="showInputHelpText = false">
            <v-icon left>close</v-icon>
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
// VUE

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CheckBox from './FormElement/CheckBox.vue'
import CheckBoxGroup from './FormElement/CheckBoxGroup.vue'
import Divider from './FormElement/Divider.vue'
import Equipment from './FormElement/Equipment.vue'
import FileComponent from './FormElement/File.vue'
import Header from './FormElement/Header.vue'
import ImageComponent from './FormElement/Image.vue'
import PreBookFailed from './FormElement/Dialogs/PreBookFailed.vue'
import RadioButton from './FormElement/RadioButton.vue'
import Stand from './FormElement/Stand.vue'
import StandSelection from './FormElement/StandSelection.vue'
import TextBox from './FormElement/TextBox.vue'
import TextInput from './FormElement/TextInput.vue'
import TextParagraph from './FormElement/TextParagraph.vue'
import Ticket from './FormElement/Ticket.vue'

// SERVICES
import { eventRegistrationService } from '@/services/eventRegistrationService'

// MODELS
import { CheckBoxGroup as CheckBoxGroupModel } from '@/models/fields/checkBoxGroup'
import { Discount } from '@/models/discount'
import { FormField } from '@/models/formField'
import { NameValue } from '@/models/fields/nameValue'
import { Pass } from '@/models/pass'
import { Stand as StandModel } from '@/models/fields/stand'
import { StandArea } from '@/models/standArea'
import { StandGroup } from '@/models/standGroup'
import { StandItem } from '@/models/standItem'
import { Ticket as TicketModel } from '@/models/fields/ticket'

// ENUMS
import { FieldType } from '@/enums/enums'

// HELPERS
import {
  getElementLabel,
  getPassesItems,
  getRelatedStandList,
  getStandFilterList
} from '@/helpers/RegistrationForm/formElement'

@Component({
  components: {
    CheckBox,
    CheckBoxGroup,
    Divider,
    Equipment,
    FileComponent,
    Header,
    ImageComponent,
    PreBookFailed,
    RadioButton,
    Stand,
    StandSelection,
    TextBox,
    TextInput,
    TextParagraph,
    Ticket
  },
})
export default class FormElementComponent extends Vue {
  @Prop() value!: FormField
  @Prop() eventId!: string
  @Prop() registrationId!: string
  @Prop({ default: false }) areInternalStandsAllowed!: boolean
  @Prop({
    default: function () {
      return []
    },
  })

  eventPasses!: Pass[]
  fieldLoading = false
  showInputHelpText = false
  showStandSelect = false
  preBookFailedDialog = false
  availableStands: StandArea[] = []
  filteredStands: StandArea[] = []

  selectedStandArea: StandArea = new StandArea()
  selectedStandGroup: StandGroup = new StandGroup()
  selectedStandItem: StandItem = new StandItem()
  preSelectedStands: StandItem[] = []

  filterShowMiniStands = false
  filterShowNormalStands = false
  filterShowInternalStands = false //this.areInternalStandsAllowed ? true : false

  ticketValidationState: 'validated' | 'error' | 'check' = 'check'
  ticketDiscountText = ''
  discountValidationInProgress: boolean = false
  discountCode = ''

  updateValue(value: string) {
    this.value.data.value = value
  }

  updateDiscountCode(value: string) {
    this.discountCode = value
  }

  get elementLabel() {
    return getElementLabel(this.value)
  }

  maxLength() {
    if (
      this.value.data.maxLength == null ||
      this.value.data.value == null ||
      this.value.data.value == ''
    )
      return true

    if (this.value.data.value.length > this.value.data.maxLength) {
      return this.$t('maxLengthExceeded').toString()
    }
    return true
  }

  requiredRule(value: any) {
    return !!value || this.$t('required').toString()
  }

  validationRule() {
    if (
      this.value.data.validation == null ||
      this.value.data.value == null ||
      this.value.data.value == ''
    )
      return true

    return (
      new RegExp(this.value.data.validation).test(this.value.data.value) ||
      (this.value.data.validationErrorMessage != null
        ? this.value.data.validationErrorMessage
        : this.$t('notAcceptableValue'))
    )
  }

  get elementRules() {
    //return getElementRules(this)
    const rules: { (value: any): any }[] = []
    if (this.value.data.mandatory != null && this.value.data.mandatory) {
      rules.push(this.requiredRule)
    }

    if (this.value.data.validation != null) {
      rules.push(this.validationRule)
    }

    if (this.value.data.maxLength != null && this.value.type != 8) {
      rules.push(this.maxLength)
    }

    //this applies only to equipments
    if (this.value.type == 12 && this.value.data.maxOrderAmount != null) {
      rules.push(this.maxOrderAmount)
    }

    return rules
  }

  ticketChanged() {
    let ticket = this.value.data as TicketModel
    let selectedPass = this.eventPasses.filter((x) => x.id == ticket.value)
    if (selectedPass != undefined && selectedPass.length == 1)
      ticket.valueName = selectedPass[0].name

    this.evaluateSkipLogic(this.value.data.id)
  }

  resetDiscountField() {
    if (this.discountCode != '' && this.ticketValidationState == 'error') {
      this.discountCode = ''
    }

    this.ticketValidationState = 'check'
  }

  hasDiscountCodes(): boolean {
    return (this.value.data as TicketModel).hasDiscountCodes
  }

  updateSelectedStandArea(area: StandArea) {
    this.selectedStandArea = area
  }

  updateSelectedStandGroup(group: StandGroup) {
    this.selectedStandGroup = group
  }

  updateSelectedStandItem(item: StandItem) {
    this.selectedStandItem = item
  }

  isTicketDisabled() {
    return this.registrationId != null || this.discountValidationInProgress
  }

  resetDiscounts() {
    for (let pass of this.eventPasses) {
      if (pass != undefined) pass.discount = undefined
    }
    this.ticketDiscountText = ''
  }

  validateDiscountCode() {
    this.resetDiscounts()

    if (this.discountCode == null || this.discountCode == '') {
      this.ticketValidationState = 'check'
      return
    }

    this.discountValidationInProgress = true
    eventRegistrationService
      .getDiscounts(this.eventId, this.discountCode)
      .subscribe(
        (results: Discount[]) => {
          if (results == null || results.length == 0) {
            this.ticketValidationState = 'error'
            this.discountValidationInProgress = false
          } else {
            for (let d of results) {
              let pass = this.eventPasses.find((x) => x.id == d.passId)
              if (pass != undefined) {
                pass.discount = d

                if (d.euros != undefined)
                  this.ticketDiscountText +=
                    pass.translatedName +
                    ' (' +
                    pass.price +
                    '€) -' +
                    d.euros +
                    '€'
                else
                  this.ticketDiscountText +=
                    pass.translatedName +
                    ' (' +
                    pass.price +
                    '€) -' +
                    d.percentage +
                    '%'
              }
              this.ticketDiscountText += '<br />'
            }

            this.ticketValidationState = 'validated'
            this.discountValidationInProgress = false
          }
        },
        (error: any) => {
          this.ticketValidationState = 'error'
          this.discountValidationInProgress = false
        }
      )
  }

  get passesItems() : NameValue[] {
    return getPassesItems(this.eventPasses)
  }

  evaluateSkipLogic(sourceId: string) {
    this.$emit('skipLogic', sourceId)
  }

  addSelectedStand() {
    if (this.selectedStandItem.id == null || this.selectedStandItem.id == '')
      return

    if (
      !this.preSelectedStands.some(
        (e: StandItem) => e.id === this.selectedStandItem.id
      )
    ) {
      eventRegistrationService
        .preBookStand(this.selectedStandItem.id)
        .subscribe((result: boolean) => {
          if (result) {
            this.preSelectedStands.push(this.selectedStandItem)
            this.filterAvailableStands()
          } else {
            //if service returns false -> already booked
            this.preBookFailedDialog = true
            this.showStandSelect = false
          }
        })
    }
  }

  groupCheckBoxChanged(checkBoxId: string) {
    if (this.value.type == FieldType.checkBoxGroup) {
      let anyCheckBoxSelected = false
      for (let c of (this.value.data as CheckBoxGroupModel).checkBoxes) {
        if (c.value) {
          anyCheckBoxSelected = true
          break
        }
      }

      (this.value.data as CheckBoxGroupModel).value = anyCheckBoxSelected
    }

    this.evaluateSkipLogic(checkBoxId)
  }

  removeSelectedStandAndUpdateAvailable(
    index: number,
    preSelection = false,
    cascade = true
  ) {
    this.removeSelectedStand(index, preSelection, cascade)
    this.updateAvailableStands()
  }

  removeSelectedStand(index: number, preSelection = false, cascade = true) {
    let standToRemove: StandItem | null = null
    if (preSelection) {
      standToRemove = this.preSelectedStands[index]
      this.preSelectedStands.splice(index, 1)
      if (cascade) {
        this.cascadeRemoveStands(this.preSelectedStands, preSelection, cascade)
      }
    } else {
      standToRemove = this.value.data.selectedStands[index]
      this.value.data.selectedStands.splice(index, 1)
      if (cascade) {
        this.cascadeRemoveStands(
          this.value.data.selectedStands,
          preSelection,
          cascade
        )
      }
    }

    if (standToRemove != null) {
      eventRegistrationService.cancelStandPreBook(standToRemove.id)
    }
  }

  cascadeRemoveStands(
    standItemList: StandItem[],
    preSelection = false,
    cascade = true
  ) {
    //also remove items that do not match standFilterList -> cascade remove
    if (standItemList.length > 1) {
      const allRelatedItems = getRelatedStandList(standItemList)
      for (let i = 0; i < standItemList.length; i++) {
        if (!allRelatedItems.includes(standItemList[i].id)) {
          this.removeSelectedStand(i, preSelection, cascade)
        }
      }
    }
  }

  cancelStands() {
    for (let i = 0; i < this.preSelectedStands.length; i++) {
      eventRegistrationService.cancelStandPreBook(this.preSelectedStands[i].id)
    }
    this.showStandSelect = false
  }

  saveStands() {
    (this.value.data as StandModel).selectedStands = JSON.parse(
      JSON.stringify(this.preSelectedStands)
    )
    this.showStandSelect = false
  }

  updateAvailableStands() {
    this.fieldLoading = true
    eventRegistrationService
      .getAvailableStands(this.eventId, this.registrationId)
      .subscribe((result: StandArea[]) => {
        this.availableStands = result
        this.filterAvailableStands()
        this.fieldLoading = false
      })
  }

  filterAvailableStands(value?: string) {
    if (value) {
      if (value === 'miniStands') this.filterShowMiniStands = !this.filterShowMiniStands
      if (value === 'normalStands') this.filterShowNormalStands = !this.filterShowNormalStands
      if (value === 'aaltoStands') this.filterShowInternalStands = !this.filterShowInternalStands
    }

    this.filteredStands = JSON.parse(JSON.stringify(this.availableStands)) //create deep copy from availableStands

    //reset selections
    this.selectedStandArea = new StandArea()
    this.selectedStandGroup = new StandGroup()
    this.selectedStandItem = new StandItem()

    const standFilter = getStandFilterList(this.preSelectedStands)

    this.filteredStands.forEach((a) =>
      a.groups.forEach(
        (g) =>
          (g.items = g.items.filter(
            (i) =>
              (this.preSelectedStands != null &&
              this.preSelectedStands.length > 0
                ? standFilter.includes(i.id) && i.relatedItemsSelectionLogic
                : true) &&
              (i.isInternalStand ? this.filterShowInternalStands : true) &&
              (i.isMiniStand ? this.filterShowMiniStands : true) &&
              (!i.isMiniStand && !i.isInternalStand
                ? this.filterShowNormalStands
                : true)
          ))
      )
    )

    //remove groups and areas without items
    this.filteredStands = this.filteredStands.filter(
      (a) => (a.groups = a.groups.filter((g) => g.items.length > 0)).length > 0
    )

    if (this.filteredStands.length == 1) {
      this.selectedStandArea = this.filteredStands[0]
    }
  }

  openStandDialog() {
    this.updateAvailableStands()
    this.preSelectedStands = JSON.parse(
      JSON.stringify((this.value.data as StandModel).selectedStands)
    ) //create deep copy from selectedStands
    this.showStandSelect = true
  }

  @Watch('value.data.selectedStands')
  selectedStandsChanged() {
    Vue.set(
      this.value.data,
      'value',
      this.value.data.selectedStands.length > 0 ? 'selected' : ''
    )
  }

  @Watch('selectedStandArea')
  standAreaChanged() {
    if (this.selectedStandArea.groups.length == 1) {
      this.selectedStandGroup = this.selectedStandArea.groups[0]
    }
  }

  @Watch('selectedStandGroup')
  standGroupChanged() {
    if (this.selectedStandGroup.items.length == 1) {
      this.selectedStandItem = this.selectedStandGroup.items[0]
    }
  }

  handleFileUpload(file: File) {
    if (!file) {
      this.value.data.hintText = ''
      return
    }

    const maxFileSize =
      this.value.data.maxSize != null ? this.value.data.maxSize : 5242880

    if (file.size == null || file.size == 0) return

    if (file.size > maxFileSize) {
      this.value.data.hintText = this.$t('fileTooBig').toString()
      return
    }

    const formData = new FormData()
    formData.append('file', file)
    this.value.data.fileName = file.name
    this.fieldLoading = true

    eventRegistrationService
      .sendFormFile(this.value.data.id, formData)
      .subscribe((result: string) => {
        if (this.value.data.value == null) {
          Vue.set(this.value.data, 'value', result)
        } else {
          this.value.data.value = result
        }
        this.fieldLoading = false
      })
  }

  deleteFile() {
    this.value.data.value = ''
  }

  maxOrderAmount(value: any) {
    if (isNaN(this.value.data.orderedQuantity)) {
      return this.$t('notNumber').toString()
    }

    if (
      this.value.data.maxOrderAmount == null ||
      this.value.data.orderedQuantity == null ||
      this.value.data.orderedQuantity == ''
    )
      return true

    if (
      (this.value.data.orderedQuantity as number) >
      this.value.data.maxOrderAmount
    ) {
      return this.$t('maxLengthExceeded').toString()
    }
    return true
  }

  openHelpLink() {
    if (this.value.data.helpLinkUrl != null) {
      window.open(this.value.data.helpLinkUrl, '_blank')
    }
  }
}
</script>

<style>
.discountErrorState {
  margin: 3px;
  color: red;
}

.discountValidState {
  margin: 3px;
  color: green;
}

.v-badge__badge {
  color: red !important;
}

.row {
  flex-wrap: wrap !important;
}

.bold {
  font-weight: bold;
}
.cursive {
  font-style: italic;
}

.headline-s {
  font-size: 1.2rem !important;
}
</style>

<i18n>
{
  "en": {
    "close": "close",
    "fileTooBig": "File size is too big",
    "helpTextHeader": "Field help text",
    "maxLengthExceeded": "max length exceeded",
    "notAcceptableValue": "value not acceptable",
    "notNumber": "The value is not a number",
    "required": "required"
  },
  "fi": {
    "close": "sulje",
    "fileTooBig": "Tiedostokoko on liian suuri",
    "helpTextHeader": "Kentän aputeksti",
    "maxLengthExceeded": "liian suuri kentän arvo",
    "notAcceptableValue": "arvo ei ole kelvollinen",
    "notNumber": "Arvo ei ole luku",
    "required": "pakollinen"
  },
  "sv": {
    "close": "stänga",
    "fileTooBig": "Filstorleken är för stor",
    "helpTextHeader": "Fälthjälptext",
    "maxLengthExceeded": "max längd överskridit",
    "notAcceptableValue": "värde inte acceptabelt",
    "notNumber": "Värdet är inte ett tal",
    "required": "nödvändig"
  }
}
</i18n>
