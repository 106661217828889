<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden">
      <div class="pt-4">
        <label
          class="subtitle-1 mr-2 label"
          >{{ label }}</label
        >
        <v-layout class="ma-1" row>
          <v-checkbox
            class="ma-0 mr-4"
            dense
            :label="r.label"
            v-for="(r, i) in data.checkBoxes"
            :key="i"
            v-model="r.value"
            @change="groupCheckBoxChanged(r.id)"
          />
          <v-icon
            class="help-text"
            v-if="data.helpText != null ? 'info' : ''"
            @click="showHelpText()"
            >info</v-icon
          >
        </v-layout>
      </div>
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator'

// MODELS
import { CheckBoxGroup } from '@/models/fields/checkBoxGroup'

@Component({})
export default class CheckkBoxGroupComponent extends Vue {
  @Prop({ default: new CheckBoxGroup() }) data!: CheckBoxGroup
  @Prop() label!: string

  showHelp = false

  groupCheckBoxChanged(id: string) {
    this.$emit('groupCheckBoxChanged', id)
  }

  showHelpText() {
    this.$emit('showHelpText', true)
  }
}
</script>
<style scoped>
.help-text {
  top: -7px;
  left: 0px;
  right: auto;
  position: relative;
}
.label {
  top: -4px;
  left: 0px;
  right: auto;
  position: relative;
}
</style>
