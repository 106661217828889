import { Observable, from, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import axios from "axios";

export abstract class BaseService {
  readonly api = process.env.VUE_APP_API;
  readonly axiosNoInterceptor = axios.create();

  protected handleError(serviceError: any) {
    console.dir(serviceError);
    if (serviceError.isAxiosError) {
      // err.title =  serviceError.message;
      // err.stack = serviceError.stack;
      if (serviceError.response != null) {
        // err.message = "from response";
        // err.message = serviceError.response as string; //todo get message from response...
      }
    }

    // todo logging
    return throwError("");
  }

  protected getExt<T>(url: string, queryParams?: string): Observable<T> {
    return from(this.axiosNoInterceptor.get(url)).pipe(
      map((res: any) => res.data as T),
      catchError(error => this.handleError(error))
    );
  }

  protected getInPage<T>(url: string, queryParams?: string): Observable<T> {
    if (!url.startsWith("/")) {
      // fix the url
      url = "/" + url;
    }

    let getUrl = this.api + url;
    if (queryParams != null) {
      getUrl = getUrl + this.validateQueryParams(queryParams);
    }

    return from(this.axiosNoInterceptor.get(getUrl)).pipe(
      map((res: any) => res.data as T),
      catchError(error => this.handleError(error))
    );
  }

  protected get<T>(url: string, queryParams?: string, config?: any): Observable<T> {
    if (!url.startsWith("/")) {
      // fix the url
      url = "/" + url;
    }

    let getUrl = this.api + url;
    if (queryParams != null && queryParams != "") {
      getUrl = getUrl + this.validateQueryParams(queryParams);
    }

    return from(axios.get(getUrl, config)).pipe(
      map((res: any) => res.data as T),
      catchError(error => this.handleError(error))
    );
  }

  protected postFile(url: string, data: FormData): Observable<string> {
    return from(
      this.axiosNoInterceptor.post(this.api + url, data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
    ).pipe(
      map((res: any) => res.data as string),
      catchError(error => this.handleError(error))
    );
  }

  protected postInPage<T, D>(url: string, data: D): Observable<T> {
    if (!url.startsWith("/")) {
      // fix the url
      url = "/" + url;
    }

    return from(this.axiosNoInterceptor.post(this.api + url, data)).pipe(
      map((res: any) => res.data as T),
      catchError(error => this.handleError(error))
    );
  }

  protected post<T, D>(url: string, data: D, config?: any): Observable<T> {
    if (!url.startsWith("/")) {
      // fix the url
      url = "/" + url;
    }

    return from(axios.post(this.api + url, data, config)).pipe(
      map((res: any) => res.data as T),
      catchError(error => this.handleError(error))
    );
  }

  validateQueryParams(queryParams?: string): string {
    if (queryParams == null || queryParams === "") {
      return "";
    }

    if (!queryParams.startsWith("?")) {
      queryParams = "?" + queryParams;
    }

    return queryParams;
  }
}
