<template>
  <v-card flat tile>
    <v-card-text class="pt-0">
      <v-container class="pt-0">
        <v-row>
          <v-col
            cols="12"
            class="pa-0"
            v-for="(field, i) in value.fields"
            :key="i"
          >
            <form-element
              :value="field"
              @skipLogic="evaluateSkipLogic"
              :eventId="eventId"
              :areInternalStandsAllowed="areInternalStandsAllowed"
              :registrationId="registrationId"
              :eventPasses="eventPasses"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FormElement from './FormElement.vue';

// MODELS
import { Pass } from '@/models/pass';
import { RegistrationForm } from '@/models/registrationForm';

@Component({
  components: {
    FormElement,
  },
})
export default class RegistrationFormPage extends Vue {
  @Prop() value!: RegistrationForm;
  @Prop() eventId!: string;
  @Prop() registrationId!: string;
  @Prop() areInternalStandsAllowed!: boolean;
  @Prop({
    default: function () {
      return [];
    },
  })
  eventPasses!: Pass[];

  dialog = false;

  evaluateSkipLogic(sourceId: string) {
    this.$emit('skipLogic', sourceId);
  }
}
</script>

<style></style>
