import { InputField } from "./interfaces/inputField";
import { Field } from "./interfaces/field";
import { Mandatory } from "./interfaces/mandatory";
import { StandItem } from "@/models/standItem";

export class Stand implements InputField, Field, Mandatory {
  selectedStands: StandItem[] = [];

  mandatory!: boolean;

  //input interface
  value?: string;
  helpText?: string;
  helpLinkText?: string;
  helpLinkUrl?: string;

  //field
  id!: string;
  isHidden!: boolean;
}
