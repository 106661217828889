<template>
    <v-dialog v-model="isOpen" persistent max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('ticketNotAvailable') }}
        </v-card-title>
        <v-card-text>
          <p class="mt-4">{{ $t('ticketNotAvailableText') }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="emitIsUpdated"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TicketsNotAvailableComponent extends Vue {
  @Prop({ default: false }) isOpen!: boolean;

  emitIsUpdated() {
    this.$emit('ticketsNotAvailableDialogUpdated');
  }
}
</script>


<i18n>
{
  "en": {
    "close": "close",
    "ticketNotAvailable": "Selected ticket sold out",
    "ticketNotAvailableText": "Unfortunately, the ticket you have selected, has been sold out. Please, review your selection."
  },
  "fi": {
    "close": "sulje",
    "ticketNotAvailable": "Valittu lippu loppuunmyyty",
    "ticketNotAvailableText": "Valitettavasti valitsemanne lippu on juuri myyty loppuun. Ole hyvä ja valitse toinen lippu."
  },
  "sv": {
    "close": "stänga",
    "ticketNotAvailable": "Vald biljett slutsåld",
    "ticketNotAvailableText": "Tyvärr har biljetten du valt sålt slut. Välj en annan biljett."
  }
}
</i18n>
