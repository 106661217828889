<template>
  <div>
    <v-list dense>
      <v-list-item-group v-model="listItem" color="primary">
        <v-list-item
          @click="componentClick(i)"
          v-for="(item, i) in value"
          :key="'field-' + i"
          draggable
          @dragstart="dragStart($event, i)"
          @dragover="allowDrop"
          @drop="dropTo($event, i)"
        >
          <v-list-item-icon>
            <v-icon>{{ getIcon(item.fieldType) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content :id="'field-' + i">
            <v-list-item-title v-text="item.name" />
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              v-if="getFieldLangStatus(item) != null"
              :color="getFieldLangStatus(item)"
              >speaker_notes</v-icon
            >
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-btn class="ma-4" tile small color="success" @click="addField"
      ><v-icon left>add</v-icon>add
    </v-btn>

    <v-dialog scrollable persistent v-model="componentEditDialog" width="650">
      <v-card height="570">
        <v-card-title class="headline grey lighten-2"
          >{{ selectedEditorField.name }} <v-spacer /><v-icon class="mr-3">{{
            getIcon(selectedEditorField.fieldType)
          }}</v-icon
          ><small>{{
            getTypename(selectedEditorField.fieldType)
          }}</small></v-card-title
        >
        <v-card-text class="grow pa-1">
          <entity-editor
            :validationOptions="validationOptions"
            :value="selectedEditorField"
            @keyFieldChanged="keyFieldChanged"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-icon
            v-if="getFieldLangStatus(selectedEditorField) != null"
            :color="getFieldLangStatus(selectedEditorField)"
            >speaker_notes</v-icon
          >
          <v-spacer />
          <v-btn
            color="error"
            outlined
            tile
            small
            @click="confirmDeleteDialog = true"
          >
            <v-icon left small>delete</v-icon>delete
          </v-btn>
          <v-btn color="success" outlined tile small @click="saveField()">
            <v-icon left small>done</v-icon>OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable persistent v-model="confirmDeleteDialog" width="350">
      <v-card height="200">
        <v-card-title class="headline yellow lighten-2"
          >Delete Field?</v-card-title
        >
        <v-card-text class="pa-5 text-center">
          You are about to delete field. Are you sure?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            outlined
            tile
            small
            @click="confirmDeleteDialog = false"
          >
            <v-icon left small>delete</v-icon>cancel
          </v-btn>
          <v-btn color="warning" outlined tile small @click="deleteField()">
            <v-icon left small>warning</v-icon>delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';
import EntityEditor from '@/components/FormEditor/ComponentEntityEditor.vue';

// MODELS
import { EditorField } from '@/models/editor/EditorField';
import { ListProvider } from '@/models/editor/listProvider';
import { Option } from '@/models/editor/option';

// ENUMS
import { FieldType, Lang } from '@/enums/enums';

@Component({
  components: {
    EntityEditor,
  },
})
export default class FormComponentEditor extends Vue {
  listItem: any = '';
  componentEditDialog = false;
  confirmDeleteDialog = false;
  @Prop() value!: EditorField[];
  @Prop({ default: '' }) sectionIdx!: string;
  @Prop({
    default: function () {
      return [];
    },
  })
  validationOptions!: { name: string; value: string }[];

  selectedEditorField: EditorField = new EditorField();
  selectedFieldIdx: number = 0;
  @Prop({
    default: function () {
      return [];
    },
  })
  langs!: Lang[];

  getFieldLangStatus(field: EditorField) {
    switch (field.fieldType) {
      case FieldType.textParagraph:
        return this.checkFieldLangs(['paragraph'], field);
      case FieldType.header:
        return this.checkFieldLangs(['question'], field);
      case FieldType.textInput:
        return this.checkFieldLangs(['question'], field);
      case FieldType.radioButton:
        let result = this.checkFieldOptionLangs(field.options);
        if (result == 'red') return result;

        return this.checkFieldLangs(['question'], field);
      case FieldType.checkBox:
        return this.checkFieldLangs(['question'], field);
      case FieldType.textBox:
        return this.checkFieldLangs(['question'], field);
      case FieldType.image:
        return this.checkFieldLangs(['fileId'], field);
      case FieldType.file:
        return this.checkFieldLangs(['question'], field);
      case FieldType.html:
        return this.checkFieldLangs(['htmlText'], field);
      case FieldType.checkBoxGroup:
        let resultCb = this.checkFieldOptionLangs(field.options);
        if (resultCb == 'red') return resultCb;

        return this.checkFieldLangs(['question'], field);
      case FieldType.equipment:
        return this.checkFieldLangs(['equipmentname'], field);
      case FieldType.ticket:
        return this.checkFieldLangs(['question'], field);
    }
    return null; //null if no localisation is neeeded
  }

  checkFieldOptionLangs(options: Option[]): string {
    for (let option of options) {
      if (
        this.langs.includes(Lang.fi) &&
        (option.nameFi == null || option.nameFi == '')
      ) {
        return 'red';
      }
      if (
        this.langs.includes(Lang.en) &&
        (option.nameEn == null || option.nameEn == '')
      ) {
        return 'red';
      }
      if (
        this.langs.includes(Lang.sv) &&
        (option.nameSv == null || option.nameSv == '')
      ) {
        return 'red';
      }
    }
    return 'green';
  }

  checkFieldLangs(params: string[], field: EditorField): string {
    for (let l of this.langs) {
      let loc = field.localisations.filter((x) => x.lang == l);
      let sumStrOnParams = '';
      if (loc != undefined) {
        for (let p of params) {
          sumStrOnParams +=
            (loc[0] as any)[p.toString()] == null
              ? ''
              : (loc[0] as any)[p.toString()];
        }
        if (sumStrOnParams == '') {
          return 'red';
        }
      }
    }
    return 'green';
  }

  keyFieldChanged(fieldType: number, fieldId: string) {
    let validationIndex = undefined
    if (Array.isArray(this.validationOptions)) {
      validationIndex = this.validationOptions.findIndex(
        x => x.name === "Email validation"
      );
    }

    if (fieldType === 1 && validationIndex) {
      this.selectedEditorField.validationRegExpId = this.validationOptions[validationIndex].value
    }

    if (fieldType !== 1 && validationIndex) {
      if (this.selectedEditorField.validationRegExpId === this.validationOptions[validationIndex].value) {
          this.selectedEditorField.validationRegExpId = ""
        }
    }

    this.$emit("keyFieldChanged", fieldType, fieldId);
  }

  getTypename(componentType: FieldType) {
    return ListProvider.getTypename(componentType);
  }

  getIcon(componentType: FieldType) {
    return ListProvider.getIcon(componentType);
  }

  saveField() {
    this.componentEditDialog = false;
  }

  addField() {
    this.$emit('addField', this.sectionIdx);
  }

  deleteField() {
    this.$emit('deleteField', this.sectionIdx, this.selectedFieldIdx);
    this.confirmDeleteDialog = false;
    this.componentEditDialog = false;
  }

  componentClick(index: number) {
    this.selectedEditorField = this.value[index];
    this.selectedFieldIdx = index;
    this.componentEditDialog = true;
  }

  dragStart(ev: any, index: number) {
    //console.log("drag start: " + index );
    ev.dataTransfer.setData('index', index);
    ev.dataTransfer.setData('type', 'field');
    ev.dataTransfer.setData('section', this.sectionIdx);
    var elem = document.getElementById('field-' + index);
    ev.dataTransfer.setDragImage(elem, 0, 0);
  }

  allowDrop(ev: any) {
    //console.log("allowdDrop");
    ev.preventDefault();
  }

  dropTo(ev: any, index: number) {
    ev.preventDefault();
    const type = ev.dataTransfer.getData('type');
    if (type != 'field') {
      return;
    }

    const oldIdx = ev.dataTransfer.getData('index');
    const oldSection = ev.dataTransfer.getData('section');
    this.$emit('fieldMoved', oldSection, oldIdx, this.sectionIdx, index);

    /*
    console.log("dropped to:" + index);
    let data = ev.dataTransfer.getData("index");
    console.log(data);*/
  }
}
</script>
