<template>
  <v-expand-transition>
    <v-card flat v-show="!data.isHidden">
      <v-img
        :src="data.src"
        class="my-3"
        contain
        :width="data.width"
        :height="data.height"
        @click="openImage"
      />
    </v-card>
  </v-expand-transition>
</template>

<script lang="ts">

// VUE
import { Component, Prop, Vue } from 'vue-property-decorator';

// MODELS
import { Image } from '@/models/fields/image';

@Component({})
export default class ImageComponent extends Vue {
  @Prop({ default: new Image() }) data!: Image

  openImage() {
    window.open(this.data.src, '_blank');
  }
}
</script>
