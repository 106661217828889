<template>
  <v-container v-if="!isLoading">
    <Header
      :value="value"
      :marketingPage="marketingPage"
    />
    <v-row class="mb-8">
     <template v-if="!returnFromRegistration">
        <v-btn
          v-if="value.id != '' && !marketingPage.hasRegisterButton"
          class="ma-2 ml-6"
          tile
          outlined
          color="success"
          @click="dialog = true"
          :disabled="!value.openForRegistrations || value.forms.length == 0"
        >
          <v-icon left>edit</v-icon> {{ registrationBtnText }}
        </v-btn>

        <v-btn
          v-if="value.registrationId != null && value.registrationId != '' && hasCancelButton === false"
          class="ma-2"
          tile
          outlined
          color="error"
          @click="cancelDialog = true"
        >
          <v-icon left>cancel</v-icon> {{ $t('cancel') }}
        </v-btn>
      </template>
    </v-row>

    <!-- registration -->
    <RegistrationForm
      :isOpen="dialog"
      @closeClicked="dialog = false"
      @saveClicked="saveRegistration"
      v-model="value.forms"
      :isRegistered="
        value.registrationId != null && value.registrationId != ''
      "
      :registerPeriodClosed="!value.registrationModificationsAllowed"
      :skipLogicList="value.skipLogic"
      :eventId="value.id"
      :emailField="value.emailFieldId"
      :registrationId="value.registrationId"
      :eventPaymentType="value.eventPayment"
      :eventPasses="value.passes"
      :hasKeyMappingFields="checkKeyFieldMappings()"
    />

    <!-- discount dialog -->
    <DiscountDialog
      :isOpen="discountDialog"
      @discountDialogIsUpdated="discountDialogIsUpdated"
    />

    <!-- discount dialog results -->
    <DiscountResults
      :isOpen="discountDialogResults"
      :discountResults="discountResults"
      @discountResultsDialogIsUpdated="discountResultsDialogIsUpdated"
    />

    <!-- registration results -->
    <Results
      :afterRegistrationLink="getAfterRegistrationLink(true)"
      :confirmationMessage="value.confirmationMessage"
      :confirmationMessageLinkVisible="value.confirmationMessageLinkVisible"
      :isOpen="resultsDialog"
      @resultsDialogIsUpdated="resultsDialogIsUpdated"
    />

    <!-- cancel registration -->
    <Cancel
      :isOpen="cancelDialog"
      @cancelDialogIsUpdated="cancelDialogIsUpdated"
    />

    <!-- Equipments not available dialog -->
    <EquipmentsNotAvailable
      :isOpen="equipmentsNotAvailableDialog"
      :notAvailableEquipments="listOfNotAvailableEquipments"
      @equipmentsNotAvailableDialogIsUpdated="
        equipmentsNotAvailableDialog = false
      "
    />

    <!-- Payment summary dialog -->
    <PaymentSummary
      :isOpen="paymentSummarydialog"
      :header="value.header"
      :items="eventOrder.items"
      :totalPrice="eventOrder.totalPrice"
      @paymentSummaryDialogIsUpdated="paymentSummaryDialogIsUpdated"
    />

    <!-- Tickets no longer available -->
    <TicketsNotAvailable
      :isOpen="ticketsNotAvailableDialog"
      @ticketsNotAvailableDialogUpdated="ticketsNotAvailableDialog = false"
    />

    <!-- terms dialog -->
    <Terms :isOpen="termsDialogIsOpen" @termsIsUpdated="termsIsUpdated" />

    <!-- Payment methods dialog-->
    <PaymentMethods
      :isOpen="paymentMethodsDialog"
      :isMethodsLoading="isMethodsLoading"
      ref="paymentMethodsComponent"
    />
  </v-container>
</template>

<script lang="ts">
// VUE
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Cancel from './EventLayout/Dialogs/Cancel.vue';
import DiscountDialog from './EventLayout/Dialogs/Discount.vue';
import DiscountResults from './EventLayout/Dialogs/DiscountResults.vue';
import EquipmentsNotAvailable from './EventLayout/Dialogs/EquipmentsNotAvailable.vue';
import Header from './EventLayout/Header.vue'
import PaymentMethods from './EventLayout/Dialogs/PaymentMethods.vue';
import PaymentSummary from './EventLayout/Dialogs/PaymentSummary.vue';
import RegistrationForm from './RegistrationForm/RegistrationForm.vue';
import Results from './EventLayout/Dialogs/Results.vue';
import Terms from './EventLayout/Dialogs/Terms.vue';
import TicketsNotAvailable from './EventLayout/Dialogs/TicketsNotAvailable.vue';

// RXJS
import { Observable } from 'rxjs';

// SERVICES
import { eventRegistrationService } from '@/services/eventRegistrationService';

// MODELS
import { Discount } from '@/models/discount';
import { EmbeddedMarketingPage } from '@/models/embeddedMarketingPage';
import { Equipment } from '@/models/fields/equipment';
import { EquipmentAvailability } from '@/models/equipmentAvailability';
import { Event } from '@/models/event';
import { Order } from '@/models/order';
import { PaytrailPaymentResponse } from '@/models/paytrailPaymentResponse';
import { TicketAvailability } from '@/models/ticketAvailability';

// ENUMS
import { FieldType, PaymentType } from '@/enums/enums';

// HELPERS
import {
  addUsedDiscountIdsToEvent,
  createPaytrailProviders,
  getOrderedAmount,
  updatePassesAvailabilities,
  resolveIsSelectedTicketsAvailable,
  getEventOrder,
} from '@/helpers/eventLayout';

const anchors = {
  EDIT: '#edit',
  CANCEL: '#cancel',
  REGISTER: '#register'
}

@Component({
  components: {
    Cancel,
    DiscountDialog,
    DiscountResults,
    EquipmentsNotAvailable,
    Header,
    PaymentMethods,
    PaymentSummary,
    RegistrationForm,
    Results,
    Terms,
    TicketsNotAvailable,
  },
})
export default class EventComponent extends Vue {
  @Prop() value!: Event;
  @Prop() isLoading!: boolean;
  @Prop() readableId!: string;

  dialog = false;
  resultsDialog = false;
  cancelDialog = false;
  paymentSummarydialog = false;
  termsDialogIsOpen = false;
  paymentMethodsDialog = false;
  equipmentsNotAvailableDialog = false;
  ticketsNotAvailableDialog = false;
  discountDialog = false;
  discountDialogResults = false;
  listOfNotAvailableEquipments: string[] = [];
  discountResults = '';
  marketingPage = new EmbeddedMarketingPage();
  returnFromRegistration: boolean = false;
  isMethodsLoading: boolean = false;
  hasCancelButton: boolean = false;
  hasEditButton: boolean = false;

  @Watch('value.marketingPageId', { immediate: true })
  marketingPageChanged() {
    this.loadMarketingPage();
  }

  discountDialogIsUpdated(discountCode: string) {

    if (discountCode == '') {
      this.discountDialog = false;
      this.dialog = true;
      return;
    }

    eventRegistrationService
      .getDiscounts(this.value.id, discountCode)
      .subscribe((results: Discount[]) => {
        if (results == null || results.length == 0) {
          this.discountResults = '';
          this.discountDialog = false;
          this.discountDialogResults = true;
        } else {
          this.discountResults = '<ul>';
          for (let d of results) {
            let pass = this.value.passes.find((x) => x.id == d.passId);
            if (pass != undefined) {
              pass.discount = d;

              if (d.euros != undefined)
                this.discountResults +=
                  '<li>' +
                  pass.translatedName +
                  ' (' +
                  pass.price +
                  '€) -' +
                  d.euros +
                  '€ </li>';
              else
                this.discountResults +=
                  '<li>' +
                  pass.translatedName +
                  ' (' +
                  pass.price +
                  '€) -' +
                  d.percentage +
                  '% </li>';
            }
          }
          this.discountResults += '</ul>';

          this.discountDialog = false;
          this.discountDialogResults = true;
        }
      });
  }

  discountResultsDialogIsUpdated() {
    this.discountDialogResults = false;
    this.dialog = true;
  }

  resultsDialogIsUpdated() {
    this.resultsDialog = false;
    window.location.href = this.getAfterRegistrationLink(false);
  }

  cancelDialogIsUpdated(canceled: boolean) {
    this.cancelDialog = false;
    if (canceled === true) {
      eventRegistrationService
        .cancelRegistration(this.value.registrationId)
        .subscribe((result: void) => {
          this.$emit('eventCancelled');
        });
    }
  }

  paymentSummaryDialogIsUpdated(toPayment: boolean) {
    this.paymentSummarydialog = false;
    if (toPayment === true) {
      eventRegistrationService
        .getTicketAvailability(this.value.id, this.value.registrationId)
        .subscribe((result: TicketAvailability[]) => {
          const isAvailable = this.isSelectedTicketsAvailable(result);
          if (isAvailable) {
            if (this.eventOrder.totalPrice != 0) {
              this.termsDialogIsOpen = true;
              this.paymentSummarydialog = false;
            } else {
              this.paymentSummarydialog = false;
              this.completePreRegisterAndPay();
            }
          } else {
            this.ticketsNotAvailableDialog = true;
          }
        });
    }
  }

  termsIsUpdated(isAccepted: boolean) {
    this.termsDialogIsOpen = false;
    if (isAccepted === true) {
      this.completePreRegisterAndPay();
    }
  }

  loadMarketingPage() {
    if (this.value.marketingPageId != '') {
      eventRegistrationService
        .getMarketingPage(this.value.marketingPageId)
        .subscribe((result: EmbeddedMarketingPage) => {
          this.marketingPage = result;
          document.head.innerHTML =
            result.headContent + document.head.innerHTML;

          this.$nextTick(() => {
            this.setMarketingPageButtons()
          });
        });
    }
  }

get showRegisterButton() {
  return (
    this.value.id != '' &&
    this.marketingPage.hasRegisterButton &&
    this.value.registrationId == null
  )
}

get showCancelButton() {
  return (
    this.value.id != '' &&
    this.hasCancelButton &&
    this.value.registrationId != null
  )
}

get showEditButton() {
  return (
    this.value.id != '' &&
    this.hasEditButton &&
    this.value.registrationId != null
  )
}

  /**
   * Removes Edit button
   * @date 14.5.2024 - 09:00
   */
  querySelector(anchor: string) {
    return document.querySelector('[href="' + anchor + '"]')
  }

  /**
   * Removes Edit button
   * @date 14.5.2024 - 08:35
   */
  removeButton(anchor: string) {
    const button = this.querySelector(anchor)
    if (button !== null) {
      button.remove()
    }
  }

  /**
   * Evaluates marketing page buttons
   * @date 14.5.2024 - 08:35
   */
  setMarketingPageButtons() {
    const cancelButton = this.querySelector(anchors.CANCEL)
    const editButton = this.querySelector(anchors.EDIT)

    if (
      this.value.registrationId != null &&
      this.value.eventPayment !== PaymentType.paytrail
    ) {
      this.removeButton(anchors.REGISTER)
      if (cancelButton !== null) {
        this.hasCancelButton = true
        this.setCancelButton()
      }
      if (editButton !== null) {
        this.hasEditButton = true
        this.setEditButton()
      }

      return
    } else {
      this.removeButton(anchors.EDIT)
      this.removeButton(anchors.CANCEL)
      this.setRegisterButton()
    }
  }

  /**
   * Sets Register button
   * @date 10/2/2023 - 3:56:10 PM
   */
  setRegisterButton() {
    const registerButton = this.querySelector(anchors.REGISTER) as HTMLAnchorElement

    if (registerButton !== null) {
      registerButton.href = '#'
      registerButton.onclick = () => {
        this.registerButtonClicked();
      }
      if (
        !this.value.openForRegistrations ||
        this.value.forms.length === 0 ||
        this.value.registrationId != null) {
          console.log(this.value.openForRegistrations)
          console.log(this.value.forms.length === 0)
          console.log(this.value.registrationId != null)
          registerButton.setAttribute('disabled', '')
        }
    }
  }

  /**
   * Sets Cancel button
   * @date 10/2/2023 - 3:56:10 PM
   */
  setCancelButton () {
    const cancelButton = this.querySelector(anchors.CANCEL) as HTMLAnchorElement

    if (cancelButton !== null) {
      cancelButton.href = '#'
      cancelButton.onclick = () => {
        this.cancelDialog = true
      }
    }
  }

  /**
   * Sets Edit button
   * @date 10/2/2023 - 3:56:10 PM
   */
  setEditButton() {
    const editButton = this.querySelector(anchors.EDIT) as HTMLAnchorElement

    if (editButton !== null) {
      editButton.href = '#'
      editButton.onclick = () => {
         this.registerButtonClicked();
      }
    }
  }

  registerButtonClicked() {
    this.dialog = true;
  }

  get registerButtonDisabled(): boolean {
    return !this.value.openForRegistrations || this.value.forms.length == 0;
  }

  completePreRegisterAndPay() {
    this.value = addUsedDiscountIdsToEvent(this.value);
    this.paymentMethodsDialog = true;
    this.dialog = false;
    this.isMethodsLoading = true;

    eventRegistrationService
      .createPayment(this.value)
      .subscribe((result: PaytrailPaymentResponse) => {
        if (result.transactionId == null || result.transactionId == '') {
          //if there is no transactionid, no need for payment and terms field contains registration id!
          this.isMethodsLoading = false;
          this.paymentMethodsDialog = false;
          this.value.registrationId = result.terms;
          this.resultsDialog = true;
        } else {
          this.isMethodsLoading = false;
          //document.body.innerHTML = "" // clear screen...
          const paytrailPaymentForms = createPaytrailProviders(
            result.providers
          );
          let parent = this.$refs.paymentMethodsComponent as PaymentMethods;
          let el = parent.$refs.paymentMethods;

          for (let f of paytrailPaymentForms) {
            (el as HTMLElement).appendChild(f);
          }
        }
      });
  }

  checkKeyFieldMappings(): boolean {
    if (this.value.emailFieldId == null || this.value.emailFieldId == '')
      return false;

    if (
      this.value.firstnameFieldId == null ||
      this.value.firstnameFieldId == ''
    )
      return false;

    if (this.value.lastnameFieldId == null || this.value.lastnameFieldId == '')
      return false;

    return true;
  }

  saveRegistration() {
    if (this.value.eventPayment == PaymentType.paytrail) {
      eventRegistrationService
        .getTicketAvailability(this.value.id, this.value.registrationId)
        .subscribe((result: TicketAvailability[]) => {
          const isAvailable = this.isSelectedTicketsAvailable(result);
          if (isAvailable) {
            this.paymentSummarydialog = true;
          } else {
            this.ticketsNotAvailableDialog = true;
          }
        });
    } else {
      this.ensureEquipmentAvailability().subscribe(
        (resultAvailability: string[]) => {
          if (resultAvailability.length == 0) {
            this.value = addUsedDiscountIdsToEvent(this.value);
            eventRegistrationService
              .createOrUpdateRegistration(this.value)
              .subscribe((result: string) => {
                this.returnFromRegistration = true;
                this.value.registrationId = result;
                this.resultsDialog = true;
                this.dialog = false;
              });
          } else {
            this.listOfNotAvailableEquipments = resultAvailability;
            this.equipmentsNotAvailableDialog = true; //availabilities are now updated, just show error dialog to fix errornous equipments
          }
        }
      );
    }
  }

  get eventOrder(): Order {
    return getEventOrder(this.value);
  }

  isSelectedTicketsAvailable(
    ticketAvailabilities: TicketAvailability[]
  ): boolean {
    this.value = updatePassesAvailabilities(this.value, ticketAvailabilities);
    return resolveIsSelectedTicketsAvailable(this.value, ticketAvailabilities);
  }

  ensureEquipmentAvailability(): Observable<string[]> {
    return new Observable<string[]>((subscriber: any) => {
      eventRegistrationService
        .getEquipmentAvailability(this.value.id, this.value.registrationId)
        .subscribe((result: EquipmentAvailability[]) => {
          const notAvailableItems: string[] = [];
          for (const form of this.value.forms) {
            for (const field of form.fields) {
              if (field.type == FieldType.equipment) {
                const currentEquipment = field.data as Equipment;
                const availability = result.find(
                  (x) => x.equipmentId == currentEquipment.equipmentId
                );
                if (
                  availability == null ||
                  availability.amountAvailable == null
                ) {
                  continue;
                }

                const ordered = getOrderedAmount(currentEquipment);

                if (ordered > availability.amountAvailable) {
                  notAvailableItems.push(currentEquipment.label);
                  if (
                    currentEquipment.ordered != null &&
                    currentEquipment.ordered
                  ) {
                    currentEquipment.ordered = false;
                  }
                }
                currentEquipment.maxOrderAmount = availability.amountAvailable; //update availability for all equipments
              }
            }
          }
          subscriber.next(notAvailableItems);
          subscriber.complete();
        });
    });
  }

  getAfterRegistrationLink(toRegistrationEdit: boolean = false): string {
    let regLink = window.location.protocol + '//' + window.location.hostname;

    if (
      window.location.port != null &&
      window.location.port != '0' &&
      window.location.port != ''
    ) {
      regLink += ':' + window.location.port;
    }

    regLink += '/' + this.readableId;

    if (toRegistrationEdit)
      regLink += '/' + encodeURI(this.value.registrationId);

    regLink += '?lang=' + this.$root.$i18n.locale;

    return regLink;
  }

  get registrationBtnText() {
    if (this.value.registrationId != null && this.value.registrationId != '') {
      return this.$t('editRegistration');
    } else {
      return this.$t('register');
    }
  }
}
</script>

<style>
.paymentMethodButton {
  width: 40%;
  position: relative;
  left: 30%;
}

a[disabled],
a[disabled]:hover {
  pointer-events: none;
  color: #807b7b !important;
  background-color: rgb(94, 90, 90) !important;
}
</style>

<i18n>
{
  "en": {
    "register": "register",
    "cancel": "cancel registration",
    "editRegistration": "edit your registration"
  },
  "fi": {
    "register": "ilmoittaudu",
    "cancel": "peru ilmoittautuminen",
    "editRegistration": "muokkaa rekisteröitymistä"
  },
  "sv": {
    "register": "registrera",
    "cancel": "avbryt registrering",
    "editRegistration": "redigera din registrering"
  }
}
</i18n>
